import { useState } from "react";
import useNotification from "./useNotification";

export default function useDatabase(baseUrl) {
	const [loading, setLoading] = useState(true);
	const [dbError, setDbError] = useState(null);

	const notification = useNotification();

	/**
	 * Creates an error object based on the provided HTTP response and API response.
	 * @param {object} http - The HTTP response object.
	 * @param {object} api - The API response object.
	 * @returns {Promise<Error>} - A promise that resolves to the created error object.
	 */
	async function createError(http, api) {
		const newError = new Error();
		newError.response = http;

		// SET THE BEST ERROR MESSAGE
		if (api.message && api.message !== "") {
			newError.message = api.message;
		} else if (http.statusText && http.statusText !== "") {
			newError.message = http.statusText;
		} else {
			newError.message = "Unknown Error";
		}

		// RETURN THE ERROR INSTANCE
		return newError;
	}

	/**
	 * Handles an error by rejecting a promise, displaying a notification, and setting a database error message.
	 * @param {Error} error - The error object.
	 * @param {function} rejectPromise - The function to reject a promise.
	 * @returns None
	 */
	function handleError(error, rejectPromise) {
		rejectPromise(error.message);
		notification.add("danger", error.message);
		setDbError(error.message);
	}

	/**
	 * Handles the response from an HTTP request.
	 * @param {Response} httpResponse - The response object from the HTTP request.
	 * @param {Function} rejectPromise - The function to reject the promise if there is an error.
	 * @param {Function} resolvePromise - The function to resolve the promise with the response data.
	 * @returns None
	 */
	async function handleResponse(httpResponse, rejectPromise, resolvePromise) {
		const apiResponse = await httpResponse.json(); // Get the API response from the HTTP Response Object
		if (httpResponse.ok && apiResponse.success) {
			resolvePromise(apiResponse.response);
		} else {
			const error = await createError(httpResponse, apiResponse);
			handleError(error, rejectPromise);
		}
	}

	/**
	 * Performs a GET request to the specified URL with the provided access token.
	 * @param {string} url - The URL to send the GET request to.
	 * @param {string} accessToken - The access token to include in the request headers.
	 * @returns {Promise} A promise that resolves with the response data if successful, or rejects with an error.
	 * @throws {Error} If an error occurs during the request.
	 */
	async function get(url, accessToken) {
		setDbError(null);
		setLoading(true);

		return new Promise((resolve, reject) => {
			fetch(baseUrl + url, {
				method: "get",
				headers: {
					"Content-Type": "application/json",
					authorization: `Bearer ${accessToken}`,
				},
			})
				.then((response) => handleResponse(response, reject, resolve))
				.catch((e) => {
					throw e;
				});
		})
			.catch((e) => {
				//DO NOTHING
			})
			.finally(() => setLoading(false));
	}

	/**
	 * Sends a PATCH request to the specified URL with the given body and access token.
	 * @param {string} url - The URL to send the PATCH request to.
	 * @param {object} body - The body of the PATCH request.
	 * @param {string} accessToken - The access token to include in the request headers.
	 * @returns {Promise} A promise that resolves with the response from the server.
	 * @throws {Error} If there is an error during the request.
	 */
	async function patch(url, body, accessToken) {
		setDbError(null);
		setLoading(true);

		return new Promise((resolve, reject) => {
			fetch(baseUrl + url, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(body),
			})
				.then((response) => handleResponse(response, reject, resolve))
				.catch((e) => {
					throw e;
				});
		})
			.catch((e) => {
				//DO NOTHING
			})
			.finally(() => setLoading(false));
	}

	/**
	 * Sends a POST request to the specified URL with the given body and access token.
	 * @param {string} url - The URL to send the request to.
	 * @param {object} body - The body of the request.
	 * @param {string} accessToken - The access token to include in the request headers.
	 * @returns {Promise} A promise that resolves with the response from the server.
	 * @throws {Error} If there is an error during the request.
	 */
	async function post(url, body, accessToken) {
		setDbError(null);
		setLoading(true);

		return new Promise((resolve, reject) => {
			fetch(baseUrl + url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(body),
			})
				.then((response) => handleResponse(response, reject, resolve))
				.catch((e) => {
					throw e;
				});
		})
			.catch((e) => {
				//DO NOTHING
			})
			.finally(() => setLoading(false));
	}

	/**
	 * Removes a resource from the server using the DELETE method.
	 * @param {string} url - The URL of the resource to be removed.
	 * @param {string} accessToken - The access token for authentication.
	 * @returns {Promise} A promise that resolves when the resource is successfully removed, or rejects with an error.
	 * @throws {Error} If there is an error during the removal process.
	 */
	async function remove(url, accessToken) {
		setDbError(null);
		setLoading(true);

		return new Promise((resolve, reject) => {
			fetch(baseUrl + url, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					authorization: `Bearer ${accessToken}`,
				},
			})
				.then((response) => handleResponse(response, reject, resolve))
				.catch((e) => {
					throw e;
				});
		})
			.catch((e) => {
				//DO NOTHING
			})
			.finally(() => setLoading(false));
	}

	return { get, patch, post, remove, loading, setLoading, dbError, setDbError }; // Destructure { useDatabase, loading, setLoading } to be able to access loading status
}
