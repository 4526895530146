/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useEffect, useContext, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";
import { WebsitesContext } from "../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import VerifyEmailPopup from "./PopupModals/VerifyEmail_Popup";
import LogRocket from "logrocket";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function AuthenticationRequired(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const websites = useContext(WebsitesContext);
	const notification = useNotification();
	const { pageTitle, setPageTitle } = props;
	const [verifyPopup, setVerifyPopup] = useState(false);

	/* Dependencies
  -------------------------------------------------- */

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		firebase.auth.onAuthStateChanged(function (user) {
			// When the user auth state has changed firebase.auth.onAuthStateChanged(function (user) {
			firebase.setAuthLoading(true); // Set Auth Loading State
			try {
				if (!user) {
					// If there is no user, notify user that they are not signed in
					notification.add("warning", "You are not signed in.");
				} else {
					handleAuthChange(user);
					handleGoogleAnalytics(user);
					handleGetData(user);
				}
			} catch (error) {
				notification.add("danger", error.message); // Error Handling
			} finally {
				firebase.setAuthLoading(false); // Allow components to load
			}
		});
		// eslint-disable-next-line
	}, [firebase.db.dbError]);

	// Manage Page Title
	useEffect(() => {
		setPageTitle("Checking Authentication");
	}, [setPageTitle]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Handles Google Analytics events based on the user's metadata.
	 * If the user was just created, it logs a "sign_up" event with the user's ID and sign up method.
	 * If the user is logging in, it logs a "login" event with the user's ID and email.
	 * @param {User} user - The user object containing metadata and authentication information.
	 * @returns None
	 */
	function handleGoogleAnalytics(user) {
		if (user.metadata.creationTime === user.metadata.lastSignInTime) {
			// Check if the user is new
			// If this is the users first signin
			firebase.logEvent(firebase.analytics, "sign_up", {
				user_id: user.uid,
				sign_up_method: user.providerData[0].providerId,
			}); // Add GA Event for New User
		} else {
			firebase.logEvent(firebase.analytics, "login", {
				user_id: user.uid,
				user_email: user.email,
			}); // Add GA Event for Sign In
		}
	}

	/**
	 * Handles the change in authentication state for the user.
	 * @param {object} user - The user object representing the authenticated user.
	 * @returns None
	 */
	async function handleAuthChange(user) {
		// If there is a user
		firebase.setUser(user); // Set user properties in context
		firebase.setAccessToken(user.accessToken); // Set user access token for API Access
		LogRocket.identify(user.uid, {
			...user,
			accessToken: "redacted",
		});

		// Adds Custom Claims to the User Object, so things like admin:true can be verified to show additional UI
		const { claims } = await user.getIdTokenResult();
		firebase.setUser({ ...user, claims });

		// Double check if the user is set and if email address is not verified, prompt the user to verify email
		if (user && !user.emailVerified) {
			setVerifyPopup(true); // Show popup modal
		}
	}

	function handleGetData(user) {
		// Get stripe customer data, if any exists
		if (!firebase.db.dbError) {
			firebase.db
				.get(
					`billing/customers/?uid=${user.uid}&email=${user.email}&name=${user.displayName}`,
					user.accessToken
				)
				.then((result) => firebase.setBillingUser(result)); // Set billingUser with result, returns null if no customer found

			// Get all websites owned by the user
			if (websites.websites === null) {
				firebase.db
					.get(`websites/all/?userId=${user.uid}`, user.accessToken)
					.then((data) => {
						websites.setWebsites(data);
					});
			}
		}
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<>
			{firebase.authLoading ? (
				<p>{pageTitle}...</p>
			) : firebase.user ? (
				<>
					<Outlet />

					{/* Render the Popup Modal only if the user is not verified, it will still not show until verifyPopup=true */}
					{!firebase.user.emailVerified && (
						<VerifyEmailPopup
							state={verifyPopup}
							stateSetter={setVerifyPopup}
							firebase={firebase}
						/>
					)}
				</>
			) : (
				<>
					<Navigate
						to="/"
						replace
					></Navigate>
					<p>Unauthorized... go to login</p>
				</>
			)}
		</>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
