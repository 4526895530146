/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { createContext, useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import {
	getAuth,
	onAuthStateChanged,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInWithPopup,
	GoogleAuthProvider,
	OAuthProvider,
	updateProfile,
	updateEmail,
	unlink,
	updatePassword,
	sendPasswordResetEmail,
	sendEmailVerification,
	signInWithCustomToken,
} from "firebase/auth";

import {
	getStorage,
	ref,
	listAll,
	getMetadata,
	getDownloadURL,
	uploadBytes,
	deleteObject,
} from "firebase/storage";

import useDatabase from "../hooks/useDatabase";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

/* Context Imports
-------------------------------------------------- */
const FirebaseContext = createContext();

/* Component Imports
-------------------------------------------------- */

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

function FirebaseProvider(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const [user, setUser] = useState(null); // Sets User Info
	const [authLoading, setAuthLoading] = useState(true);
	const [accessToken, setAccessToken] = useState(null); // Sets API Access Token
	const [billingUser, setBillingUser] = useState(null); // Sets Stripe Account info on Authentication

	/* Dependencies
  -------------------------------------------------- */
	const firebaseConfig = {
		apiKey: "AIzaSyDlR9Ydcf50CivC0qrQhDLCbFPnqqZpexk",
		authDomain: "web-design-for-actors.firebaseapp.com",
		projectId: "web-design-for-actors",
		storageBucket: "web-design-for-actors.appspot.com",
		messagingSenderId: "247975106454",
		appId: "1:247975106454:web:01326fd1da36de96d1efb7",
		measurementId: "G-V9M4GKELX3",
	};
	const firebase = initializeApp(firebaseConfig);
	const auth = getAuth(firebase);
	const storage = getStorage();
	const analytics = getAnalytics(firebase);

	// Database
	const db = useDatabase("https://api.webdesignforactors.com/", accessToken); // Must include trailing slash!

	// Google Authentication
	const googleAuth = new GoogleAuthProvider();
	googleAuth.addScope("https://www.googleapis.com/auth/userinfo.email");
	googleAuth.addScope("https://www.googleapis.com/auth/userinfo.profile");

	const appleAuth = new OAuthProvider("apple.com");
	appleAuth.addScope("email");
	appleAuth.addScope("name");

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Retrieves a reference to a folder in Firebase Storage.
	 * @param {string} folder - The name or path of the folder.
	 * @returns {Promise<Reference>} A promise that resolves to the reference of the folder.
	 */
	async function getDocRef(folder) {
		const folderRef = ref(storage, folder);
		return folderRef;
	}

	/**
	 * Deletes a document with the given reference.
	 * @param {ref} ref - The reference of the document to delete.
	 * @throws {Error} If there is an error deleting the document.
	 * @returns None
	 */
	async function deleteDoc(ref) {
		try {
			await deleteObject(ref);
		} catch (error) {
			throw new Error(error);
		}
	}

	/**
	 * Retrieves the storage items within a specified folder of a website.
	 * @param {FolderReference} folderRef - The reference to the folder in the website's storage.
	 * @returns {Promise<Array<FileInfo>>} - A promise that resolves to an array of file information objects.
	 * Each file information object contains the name, full path, and URL of the storage item.
	 */
	async function getWebsiteStorage(folderRef) {
		const items = await listAll(folderRef).then((res) => {
			return res.items;
		});

		const fileInfos = await Promise.all(
			items.map(async (item) => {
				const url = await getDownloadURL(item);

				return {
					name: item.name,
					fullPath: item.fullPath,
					url,
				};
			})
		);
		return fileInfos;
	}

	/**
	 * Uploads a file to a specified folder in a storage system.
	 * @param {any} folderRef - The reference to the folder where the file will be uploaded.
	 * @param {any} file - The file to be uploaded.
	 * @param {any} metadata - The metadata associated with the file.
	 * @param {string} fileName - The name of the file.
	 * @returns {Promise<Object>} - An object containing the name, fullPath, customMetadata, and downloadURL of the uploaded file.
	 * @throws {Error} - If the folderRef is not found or if there is an error getting the file to upload.
	 */
	async function uploadWebsiteFile(folderRef, file, metadata, fileName) {
		// Create a file reference (e.g. websitefolder/imagename.jpg)
		if (!folderRef) {
			throw new Error(
				"Unable to find a storage folder for your account: " + folderRef
			);
		} else if (!file) {
			throw new Error("Error getting the file to upload");
		}

		const getFileName = () => {
			if (file.name) {
				return file.name;
			} else if (fileName) {
				// If the File Object does not contain/support a file name, this allows us to pass one in manually
				return fileName;
			} else {
				return "unnamed file";
			}
		};

		const fileRef = ref(folderRef, getFileName()); // Create a file reference (e.g. websitefolder/imagename.jpg)
		const snapshot = await uploadBytes(fileRef, file, metadata); // Upload the file to the file reference (e.g. upload file to websitefolder/imagename.jpg)
		const downloadURL = await getDownloadURL(snapshot.ref); // Get the full image URL
		const { name, fullPath, customMetadata } = await getMetadata(snapshot.ref);
		return { name, fullPath, customMetadata, downloadURL }; //Return the full image URL
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	const value = {
		//Firebase Initialisation
		firebase,
		auth,

		// Firebase User
		accessToken, // The Firebase Access Token extracted from the current user, used for DB/API Auth etc
		setAccessToken, // Allows to set the Firebase Access Token when auth state changes
		onAuthStateChanged, // Hook called when auth state changed
		createUserWithEmailAndPassword, // Function to create the user with email/password
		signInWithEmailAndPassword, // Function to Sign in with email/password
		signInWithPopup, // Function to create authentication popups for 3rd party provisioners
		googleAuth, // Google Auth Authentication
		user, // The user data from Firebase
		setUser, // Allows the Firebase User to be set when auth state changes
		setAuthLoading,
		authLoading, // Loading state until Firebase has propagated authentication state
		updateProfile, // Update profile metadata such as display name and profile photo
		updateEmail, // Update email address
		unlink, // Unlink a 3rd party auth provider
		updatePassword, // Update user password
		billingUser, // Stripe User
		setBillingUser, // Allows stripe user to be set if found when user authenticates
		OAuthProvider, // Class for Apple Authentication
		appleAuth, // Apple Authentication
		sendPasswordResetEmail, // Send a password reset email to the email address
		sendEmailVerification, // Send Email verification to the email address
		signInWithCustomToken, // Admin Function to sign in with a token generated for a different user

		// Firebase Firestore DB
		db, // useFetch hook functions

		// Firebase Storage
		getDocRef,
		getWebsiteStorage,
		uploadWebsiteFile,
		deleteDoc,

		//Analytics,
		analytics,
		logEvent,
	};

	//Context Provider
	return (
		<FirebaseContext.Provider value={value}>
			{props.children}
		</FirebaseContext.Provider>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

export { FirebaseContext, FirebaseProvider };

/*============  End of Exports  =============*/
