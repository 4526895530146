/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Level, Heading, Button } from "react-bulma-components";
import PageHeadingSkeleton from "./Skeletons/Heading_Skeleton";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function PageHeading({ title, subtitle, loading, buttons }) {
	/* State, Context and Hooks
  -------------------------------------------------- */

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	function PageTitle() {
		return (
			<Level>
				<Level.Side align="left">
					<Heading
						size={1}
						style={{
							fontWeight: "500",
							textTransform: "capitalize",
						}}
					>
						{title}
					</Heading>
					<Heading
						subtitle
						style={{
							fontWeight: "600",
							textTransform: "capitalize",
						}}
					>
						{subtitle}
					</Heading>
				</Level.Side>
				<Level.Side align="right">
					<Button.Group>
						{buttons.map((button, index) => {
							return (
								<Button
									color={button.color}
									onClick={button.action}
									inverted={button.inverted}
									key={index}
								>
									{button.text}
								</Button>
							);
						})}
					</Button.Group>
				</Level.Side>
			</Level>
		);
	}

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (loading) {
		return <PageHeadingSkeleton />;
	} else {
		return <PageTitle />;
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
