/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../hooks/useNotification";
import useCrisp from "../hooks/useCrisp";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";
import { WebsitesContext } from "../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Dropdown, Button } from "react-bulma-components";
import GenericConfirmActionPopup from "../components/PopupModals/GenericConfirmAction_Popup";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

export default function WebsiteActions(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const websites = useContext(WebsitesContext);
	const notification = useNotification();
	const supportChat = useCrisp();
	const navigate = useNavigate();
	const [deletePopup, setDeletePopup] = useState(false);

	/* Dependencies
  -------------------------------------------------- */
	const valueFunctionMap = {
		deactivate: handleWebsiteStatus,
		support: () => {
			supportChat.then((crisp) => crisp.chat.open());
		},
		delete: () => {
			setDeletePopup(true);
		},
		view: (websiteData) => {
			window.open(`https://${websiteData.data.domain}`);
		},
	};

	const websiteData = props.website ?? null;

	const isDisabled = () => {
		const status = websiteData.data.status?.toLowerCase();

		if (status === "online" || status === "user deactivated") {
			return false;
		}

		return true;
	};

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Handles the deletion of a website.
	 * @returns None
	 */
	async function handleDeleteWebsite() {
		const promise = firebase.db
			.remove(
				`websites/${websiteData.id}?domain=${websiteData.data.domain}`,
				firebase.accessToken
			)
			.then((res) => {
				websites.deleteWebsiteData(websiteData.id);
				navigate("/dashboard", { replace: true });
				setDeletePopup(false);
			});

		notification.withPromise(promise, {
			pending: "Deleting your website",
			success: "Your website has been deleted",
		});
	}

	/**
	 * Handles the status of a website by updating the status in the database and sending a notification.
	 * @returns None
	 */
	function handleWebsiteStatus() {
		const altStatus =
			websiteData.data.status === "User Deactivated" ? "on" : "off";

		const promise = firebase.db
			.patch(
				`plesk/alias/?domain=${websiteData.data.domain}&status=${altStatus}`,
				null, // Body
				firebase?.accessToken
			)
			.then((res) => {
				return firebase.db
					.patch(
						`websites/${websiteData.id}`,
						{
							status: altStatus === "off" ? "User Deactivated" : "Online",
						},
						firebase?.accessToken
					)
					.then(() => {
						websiteData.data.status =
							altStatus === "off" ? "User Deactivated" : "Online";
					});
			});

		notification.withPromise(promise, {
			pending: `Turning website ${altStatus}line`,
			success: `Successfully turned website ${altStatus}line`,
		});
	}

	/**
	 * Determines the appropriate action based on the status of a website.
	 * @returns {string} The action to be taken.
	 */
	function getAction() {
		const status = websiteData.data.status?.toLowerCase();

		if (status === "online") {
			return "Deactivate Website";
		} else if (status === "user deactivated") {
			return "Reactivate Website";
		}
		return `Website is ${websiteData.data.status}`;
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Dropdown
			closeOnSelect
			hoverable
			className="discreet-dropdown"
			onChange={(selected) => {
				const selectedFunction = valueFunctionMap[selected](websiteData);

				if (selectedFunction) {
					selectedFunction();
				}
			}}
			label={<FontAwesomeIcon icon={faEllipsis} />}
		>
			<Dropdown.Item
				renderAs={Button}
				value="view"
			>
				View Website
			</Dropdown.Item>
			<Dropdown.Item
				renderAs={Button}
				value="support"
			>
				Contact Support
			</Dropdown.Item>

			<Dropdown.Item
				renderAs={Button}
				value="deactivate"
				disabled={isDisabled()}
				onClick={() => handleWebsiteStatus()}
			>
				{getAction()}
			</Dropdown.Item>

			<Dropdown.Divider />
			<Dropdown.Item
				renderAs={Button}
				value="delete"
			>
				Delete Website
			</Dropdown.Item>
			<GenericConfirmActionPopup
				state={deletePopup}
				stateSetter={setDeletePopup}
				action={handleDeleteWebsite}
				title={`Permanently Delete ${websiteData.data.domain}?`}
				body="This action is irreversable and all data will be permanently deleted. Your subscription will be cancelled immediately and your website immediately removed. Are you sure?"
			/>
		</Dropdown>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
