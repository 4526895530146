/*=============================================
=                   IMPORTS                   =
=============================================*/

/*============  End of IMPORTS  =============*/

/*=============================================
=                   Functions                   =
=============================================*/

/* generateInitialValues()
-------------------------------------------------- */
/**
 * Generates initial values for a form based on the provided container configurations, website data, test values, and generateEmpty flag.
 * @param {Object} options - The options object.
 * @param {Array} options.containerConfigs - An array of container configurations.
 * @param {Object} options.websiteData - The website data object.
 * @param {boolean} options.testValues - A flag indicating whether to generate test values.
 * @param {boolean} options.generateEmpty - A flag indicating whether to generate empty values.
 * @returns {Object} The generated initial values for the form.
 */
export default function generateInitialValues({
	containerConfigs,
	websiteData,
	testValues,
	generateEmpty,
}) {
	/**
     *
     * RETURNS INITIAL FORM VALUES IN THE FOLLOWING STRUCTURE:
        {
            template: { colorScheme: [ { value: undefined } ] },
            intro: {
              stageName: [ { value: undefined } ],
              biog: [ { value: undefined } ],
              file: [ { cv: undefined, headshot: undefined } ],
              location: [ { city: undefined, country: undefined } ],
              role: [ { value: undefined } ],
              playingAge: [ { min: undefined, max: undefined } ],
              availability: [ { value: undefined } ]
            },
            socials: {
              facebook: [ { value: undefined } ],
              twitter: [ { value: undefined } ],
              instagram: [ { value: undefined } ]
            },
            skills: { skills: [ { title: undefined, value: undefined } ] },
            training: { training: [ { title: undefined, value: undefined } ] }, 
        }
     *
     */
	const initialValues = {}; // Initialize the object to store initial values

	// return an object of test values if `debug` is true
	if (testValues) {
		return {
			template: { colorScheme: [{ value: undefined }] },
			intro: {
				stageName: [{ value: undefined }],
				biog: [{ value: undefined }],
				file: [{ cv: undefined, headshot: undefined }],
				location: [{ city: undefined, country: undefined }],
				role: [{ value: undefined }],
				playingAge: [{ min: undefined, max: undefined }],
				availability: [{ value: undefined }],
			},
			socials: {
				facebook: [{ value: undefined }],
				twitter: [{ value: undefined }],
				instagram: [{ value: undefined }],
			},
			skills: { skills: [{ title: undefined, value: undefined }] },
			training: { training: [{ title: undefined, value: undefined }] },
			gallery: {
				file: [{ name: undefined, category: undefined, upload: undefined }],
			},
		};
	} else {
		// Iterate through each container in the form fields
		containerConfigs.forEach((container) => {
			if (!initialValues[container.group]) {
				// Create a nested object if it doesn't exist already
				initialValues[container.group] = {};
			}

			let sortedFields = container.fields.sort((a, b) => {
				const aWeight = a.weight ?? 999;
				const bWeight = b.weight ?? 999;
				if (aWeight < bWeight) {
					return -1;
				}
				if (aWeight > bWeight) {
					return 1;
				}
				return 0;
			});

			// Provide an index-based nested object id if the field type is an array (container.dynamic)
			initialValues[container.group][container.id] = [];

			if (
				websiteData &&
				websiteData[container.group] &&
				websiteData[container.group][container.id] &&
				generateEmpty !== true
			) {
				const fieldValuesArray = websiteData[container.group][container.id];

				fieldValuesArray.forEach((fieldValues) => {
					const newFieldValues = {};
					sortedFields.forEach((fieldConfig) => {
						const key = fieldConfig.id ?? "value";
						const value = fieldValues[key] ?? undefined;
						newFieldValues[key] = value;
					});
					initialValues[container.group][container.id].push(newFieldValues);
				});
			} else {
				const keyValuesObject = {};
				sortedFields.forEach((field) => {
					const key = field.id ?? "value";
					// value must be a truthy string.
					keyValuesObject[key] = new String(""); // eslint-disable-line
				});
				initialValues[container.group][container.id].push(keyValuesObject);
			}
		});

		return initialValues; // Return the generated initial values object
	}
}

/*============  End of Functions  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
