/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useNavigate } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import PopupModal from "../PopupModal";
import { Button } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A functional component that renders a popup for verifying email.
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.state - The state object.
 * @param {Function} props.stateSetter - The function to set the state.
 * @param {Object} props.firebase - The firebase object.
 * @returns The rendered VerifyEmailPopup component.
 */
export default function VerifyEmailPopup(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const { state, stateSetter, firebase } = props;
	const notification = useNotification();
	const redirect = useNavigate();

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Renders a text component that displays a message to the user.
	 * The message includes the user's email address and a button to change the email address.
	 * @returns {JSX.Element} - The rendered text component.
	 */
	function RenderText() {
		return (
			<span>
				To prevent login issues, please verify your email address{" "}
				{firebase.user.email}.{" "}
				<Button
					type="button"
					className="buttonAsLink"
					text
					onClick={() => {
						redirect("/dashboard/settings");
						stateSetter(false);
					}}
				>
					Click here to change your email address.
				</Button>
			</span>
		);
	}
	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<PopupModal
			state={state}
			stateSetter={stateSetter}
			title="Verify your account"
			body={RenderText()}
			footer={
				<Button.Group>
					<Button
						color="primary"
						onClick={() => {
							const promise = firebase.sendEmailVerification(
								firebase?.auth?.currentUser,
								{
									url: window.location.href,
								}
							);

							stateSetter(false);

							notification.withPromise(promise, {
								pending: "Sending Email",
								success:
									"Successfully sent email, check your inbox for the link.",
							});
						}}
					>
						Send Verification Email
					</Button>
				</Button.Group>
			}
		/>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
