/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useNavigate } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useCrisp from "../hooks/useCrisp";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Columns, Heading, Button, Section } from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignsPost } from "@fortawesome/free-solid-svg-icons";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function E404() {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const supportChat = useCrisp();
	const navigate = useNavigate();

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Columns
			vCentered
			centered
			style={{ minHeight: "100vh" }}
		>
			<Columns.Column height="100vh">
				<Section
					m={5}
					textAlign={"center"}
				>
					<FontAwesomeIcon
						icon={faSignsPost}
						size="6x"
					/>
					<Heading mt={5}>This page could not be found.</Heading>
					<Heading
						subtitle
						size={4}
						textWeight="light"
					>
						Please try again later.
					</Heading>
					<Button.Group align="center">
						<Button
							color="dark"
							onClick={() => {
								supportChat.then((crisp) => crisp.chat.open());
							}}
						>
							Contact Support
						</Button>
						<Button
							color="primary"
							onClick={() => {
								navigate(-1);
							}}
						>
							Go Back
						</Button>
					</Button.Group>
				</Section>
			</Columns.Column>
		</Columns>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
