/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useEffect, useContext } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Columns, Heading, Button, Section } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

export default function ErrorPage(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const [time, setTime] = useState(5);
	const [retryCount, setRetryCount] = useState(1);

	/* Dependencies
  -------------------------------------------------- */

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime((prevTime) => {
				if (prevTime < 1) {
					clearInterval(intervalId);
					return;
				} else if (prevTime > 1) {
					return prevTime - 1;
				}
			});
		}, 1000);
		// Clear the interval when the component unmounts or when the time reaches 0
		return () => {
			clearInterval(intervalId);
		};
	}, [time]);

	/*=============================================
  =                   Functions                   =
  =============================================*/
	function tryAgain() {
		firebase.db.get(`status`);
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Columns.Column>
			<Columns className="content-parent-container">
				<Columns.Column>
					<Section
						m={5}
						className="content"
						textAlign={"center"}
					>
						<FontAwesomeIcon
							icon={faCircleExclamation}
							size="6x"
						/>
						<Heading>There was an unexpected problem</Heading>
						<Heading
							subtitle
							size={4}
							textWeight="light"
						>
							This page could not be loaded, please try again later.
						</Heading>
						<Button.Group align="center">
							{retryCount > 1 && (
								<Button
									color="dark"
									disabled={retryCount < 2 || (retryCount === 2 && time)}
								>
									Contact Support
								</Button>
							)}
							<Button
								color="primary"
								onClick={() => {
									firebase.db.setDbError(false);
									setRetryCount((prevCount) => prevCount + 1);
									setTime(retryCount * 20);
									tryAgain();
								}}
								disabled={time}
							>
								{time ? `Wait ${time}` : "Reload"}
							</Button>
						</Button.Group>
					</Section>
				</Columns.Column>
			</Columns>
		</Columns.Column>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
