/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useEffect } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import { useStripe, useElements } from "@stripe/react-stripe-js";
import useNotification from "../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import PopupModal from "../PopupModal";
import { Section, Form, Button, Notification } from "react-bulma-components";
import { CardElement } from "@stripe/react-stripe-js";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A functional component that renders a popup for adding a new card.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.clientSecret - The client secret for the Stripe API.
 * @param {string} props.state - The current state of the component.
 * @param {function} props.stateSetter - A function to set the state of the component.
 * @returns The rendered component.
 */
export default function AddNewCardPopup(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const { clientSecret, state, stateSetter } = props;
	const stripe = useStripe();
	const elements = useElements();
	const notification = useNotification();
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	/* Dependencies
  -------------------------------------------------- */
	const options = {
		classes: {
			base: "StripeElement",
			focus: "StripeElement--focus",
			invalid: "StripeElement--invalid",
		},
		style: {
			base: {
				color: "#32325d",
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#aab7c4",
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	/* End of useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (stripe && elements && !stripe.isLoading) {
			setIsLoading(false);
		}
	}, [stripe, elements]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Handles the submission of a new card form.
	 * @param {Event} e - The event object.
	 * @returns None
	 */
	async function handleNewCard(e) {
		e.preventDefault();

		setIsLoading(true);

		// With the user input card details, create a new Payment Method, destructure the response into error or paymentMethod
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardElement),
		});

		// If the destructured response contains error, show the error and return - Error Handling
		if (error) {
			setError(error);
			setIsLoading(false);
			notification.add("warning", error.message);
			return;
		}

		// If there was no error, use the clientSecret generated when the popup was opened to attach the payment method to the stripe customer - Same error/response destructuring as above
		const { error: updateError } = await stripe.confirmCardSetup(clientSecret, {
			payment_method: paymentMethod.id,
		});

		if (updateError) {
			setError(updateError);
			return;
		} else {
			notification.add(
				"success",
				`Successfully added new card ending ***${paymentMethod.card.last4}.`
			);
			stateSetter(false); // Close the popup modal
		}
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/
	return (
		<PopupModal
			state={state}
			stateSetter={stateSetter}
			title="Add a new card"
			body={
				<Section>
					<form className="stripe-form">
						<Form.Field>
							<Form.Field.Label>
								<Form.Label>New Card Details</Form.Label>
							</Form.Field.Label>
							<Form.Field.Body>
								<Form.Field>
									<Form.Control fullwidth>
										<CardElement options={options} />
									</Form.Control>
								</Form.Field>
							</Form.Field.Body>
						</Form.Field>

						<p>
							By providing your card information, you allow Web Design for
							Actors to charge your card for future payments in accordance with
							their terms.
						</p>

						{/* Show any error or success messages */}
						{error && (
							<Notification
								id="payment-message"
								color="warning"
								m={5}
							>
								<Button
									remove
									onClick={() => setError(null)}
								/>
								{error.message}
								<Button
									text
									className="buttonAsLink"
									onClick={() => setError(null)}
								>
									&nbsp;Click here to try again
								</Button>
								.
							</Notification>
						)}
						{!error && (
							<Button.Group
								align="center"
								mt={5}
							>
								<Button
									submit
									size="medium"
									color="primary"
									align="center"
									onClick={(e) => handleNewCard(e)}
									loading={isLoading}
								>
									Add New Card
								</Button>
							</Button.Group>
						)}
					</form>
				</Section>
			}
		/>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
