/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useContext, useEffect, useRef } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { editorSavePrompt } from "../FormComponents/EditorSavePrompt";
import generateInitialValues from "../../helpers/generateFormValues";
import { DynamicFormFields } from "../FormComponents/DynamicFormFields";
import { FormSpy } from "react-final-form";
import { requiresUpgrade } from "../FormComponents/utilities";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { WebsitesContext } from "../../context/websites_contextProvider";
import { getFields, handleFormSubmit } from "../../helpers/formFunctions";
import BoxedList from "./BoxedList";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A functional component that represents an editor form.
 * @returns The rendered editor form component.
 */
export default function EditorForm() {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const websites = useContext(WebsitesContext);
	const notification = useNotification();
	const queryParams = useParams();
	const {
		//Context from EditWebsite.js (te parent of this)
		firebase,
		websiteData,
	} = useOutletContext();
	const [formFields, setFormFields] = useState([]);
	const isFirstRender = useRef(true);

	/* Dependencies
  -------------------------------------------------- */

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (isFirstRender.current) {
			getFields(firebase, setFormFields);
			isFirstRender.current = false;
		}
	}, [firebase]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Retrieves the field filters from the query parameters and returns them as an array.
	 * @returns {Array<string>} - An array of field filters.
	 */
	const fieldFilters = () => {
		const paramsArray = [];
		const { g } = queryParams;

		if (g) {
			paramsArray.push(...g.split(",")); // Push the split values into the array
		} else {
			paramsArray.push("intro"); // Push "intro" if "g" parameter is not present
			paramsArray.push("template"); // Push "template" if "g" parameter is not present
		}

		return paramsArray;
	};

	/*============  End of Functions  =============*/

	/*=============================================
  =                   Components                   =
  =============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (firebase.authLoading) {
		// Return Loading Page if Firebase is still loading
		return <p>Loading...</p>;
	} else if (requiresUpgrade(fieldFilters(), websiteData.data.addons)) {
		return <p>You must upgrade to access this addon.</p>;
	} else {
		return (
			<Form
				initialValues={generateInitialValues({
					containerConfigs: formFields,
					websiteData: websiteData.data,
					// testValues: true,
				})}
				mutators={{
					...arrayMutators,
				}}
				subscription={{
					submitting: true,
					values: true,
				}}
				// debug={(formState) => console.log(formState)}
				onSubmit={async (values) =>
					await handleFormSubmit(
						firebase,
						websiteData.id,
						values,
						websites,
						notification
					)
				}
			>
				{(props) => {
					return (
						<form
							onSubmit={props.handleSubmit}
							noValidate={true} // Disable default browser validation #WeDoItBetter
						>
							{fieldFilters().includes("gallery") === true ||
							fieldFilters().includes("blog") === true ? (
								<BoxedList
									formFields={formFields}
									groupFilters={fieldFilters()}
									firebase={firebase}
									notification={notification}
									form={props.form}
								/>
							) : (
								<DynamicFormFields
									formFields={formFields}
									{...props.form.mutators}
									groupFilters={fieldFilters()}
									firebase={firebase}
									notification={notification}
									containerSettings={{
										displayLabel: true,
										displayHelp: true,
										isHorizontal: true,
									}}
								/>
							)}
							<FormSpy
								subscription={{
									pristine: true,
									validating: true,
									invalid: true,
									errors: true,
								}}
							>
								{(props) => {
									return !props.pristine && editorSavePrompt(props);
								}}
							</FormSpy>
						</form>
					);
				}}
			</Form>
		);
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
