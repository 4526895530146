/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useCrisp from "../../hooks/useCrisp";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import PopupModal from "../PopupModal";
import { Button, Block, Heading, Notification } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A functional component that renders a cancel subscription popup.
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.state - The state object.
 * @param {Function} props.stateSetter - The function to set the state.
 * @param {Object} props.subscription - The subscription object.
 * @param {Function} props.setSubscriptions - The function to set the subscriptions.
 * @returns The cancel subscription popup component.
 */
export default function CancelSubscriptionPopup(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const { state, stateSetter, subscription, setSubscriptions } = props;
	const firebase = useContext(FirebaseContext);
	const supportChat = useCrisp();
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Converts a UNIX timestamp to a formatted date string.
	 * @param {number} timestamp - The UNIX timestamp to convert.
	 * @returns {string} The formatted date string.
	 */
	const renewalDate = new Date(
		subscription.current_period_end * 1000
	).toLocaleDateString();

	/**
	 * Handles the click event for cancelling a subscription.
	 * @param {string} subscriptionId - The ID of the subscription to cancel.
	 * @returns None
	 */
	function handleCancelSubscriptionClick(subscriptionId) {
		firebase.db
			.remove(
				`billing/subscriptions?subscriptionId=${subscriptionId}`,
				firebase?.accessToken
			)
			.then(() => {
				setSubscriptions((prevData) =>
					prevData.map((subscription) => {
						if (subscription.id === subscriptionId) {
							// Create a new subscription object with the `cancel_at_period_end` attribute set to true
							return {
								...subscription,
								cancel_at_period_end: true,
							};
						} else {
							return subscription;
						}
					})
				);
				setSuccess(
					"Your subscription has been cancelled. If you have any outstanding invoices, please contact support to cancel them."
				);
			})
			.catch(() => setError("Unable to cancel your subscription."));
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<PopupModal
			state={state}
			stateSetter={stateSetter}
			title={`Cancellation for ${subscription?.metadata?.domain ?? ""}`}
			body={
				<>
					<Block>
						<Heading size={5}>
							Please read carefully and click Confirm to continue.
						</Heading>
						<p>
							<b>
								Your subscription will not renew on its next renewal date:&nbsp;
								{renewalDate}.
							</b>
							<br />
							Your domain name will also not be renewed and access to your email
							services will expire. This means that anyone may purchase and take
							over your domain name after it expires.
						</p>
					</Block>
					{success && <Notification color="success">{success}</Notification>}
					{error && (
						<Notification color="warning">
							{error}&nbsp;
							<Button
								text
								className="buttonAsLink"
								onClick={() => {
									setError(null);
									stateSetter(false);
									supportChat.then((crisp) => {
										crisp.chat.open();
										crisp.message.send(
											"text",
											`Hello, I am trying to cancel my subscription ${
												subscription.id ?? "(Could not get subscription ID)"
											} but there was a problem. Can you help?`
										);
									});
								}}
							>
								Please&nbsp;<b>contact support</b>.
							</Button>
						</Notification>
					)}
				</>
			}
			footer={
				!error && (
					<Button.Group>
						<Button
							color="dark"
							onClick={() => {
								stateSetter(false);
							}}
						>
							Go Back
						</Button>
						<Button
							color="primary"
							onClick={() => handleCancelSubscriptionClick(subscription.id)}
							disabled={success || error}
						>
							Confirm{success && "ed"}
						</Button>
					</Button.Group>
				)
			}
		/>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
