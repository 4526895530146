/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { createContext, useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
const WebsitesContext = createContext();

/* Component Imports
-------------------------------------------------- */

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

function WebsitesProvider(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const notification = useNotification();
	const [websites, setWebsites] = useState(null);
	const [templateVariations, setTemplateVariations] = useState(null);
	const [websitePayment, setWebsitePayment] = useState(false);

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Retrieves the template variations for a given template name from the Firebase database.
	 * @param {Firebase} firebase - The Firebase instance.
	 * @param {string} templateName - The name of the template to retrieve variations for.
	 * @returns {Promise<TemplateVariations>} - A promise that resolves to the template variations.
	 * @throws {Error} - If unable to connect to the database or retrieve the template variations.
	 */
	function getTemplateVariations(firebase, templateName) {
		if (firebase && firebase.db && !templateVariations) {
			// Do not get template variations if already set in state, check if Firebase.db is available to use
			firebase.db
				.get("templates/get", firebase?.accessToken) // Request the templates List
				.then((data) => {
					// Filter the template based on the provided template name, get the template variations
					const templateVariations = data.find(
						(thisTemplate) => thisTemplate.name === templateName
					);
					setTemplateVariations(templateVariations); // Return the template variations to state
				})
				.catch((error) => {
					//Catch errors from the fetch request
					notification.add(
						"danger",
						"Unable to get templates from the database"
					);
				});
		} else if (templateVariations) {
			return templateVariations;
		} else {
			throw new Error(
				"Could not connect to the database to retrieve the template variations."
			);
		}
	}

	/**
	 * Updates the data of a website with the given websiteId.
	 * @param {string} websiteId - The ID of the website to update.
	 * @param {object} updatedData - The updated data to merge with the existing data.
	 * @returns None
	 */
	async function updateWebsiteData(websiteId, updatedData) {
		// Update website context based on website ID (This method exists so that state can be updated across the website and show the latest user updated info without having to make another request to the database)
		setWebsites(
			websites.map((website) => {
				if (websiteId === website.id) {
					// If ID Matches
					return { ...website, data: { ...website.data, ...updatedData } }; // Update the Data Object
				}
				return website; // If website ID does not match, return the website unchanged
			})
		);
	}

	/**
	 * Deletes the website data for the specified website ID.
	 * @param {string} websiteId - The ID of the website to delete.
	 * @returns None
	 */
	async function deleteWebsiteData(websiteId) {
		setWebsites(websites.filter((website) => website?.id !== websiteId));
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	// Context Returns
	const value = {
		websites,
		setWebsites,
		updateWebsiteData,
		templateVariations,
		getTemplateVariations,
		websitePayment,
		setWebsitePayment,
		deleteWebsiteData,
	};

	//Context Provider
	return (
		<WebsitesContext.Provider value={value}>
			{props.children}
		</WebsitesContext.Provider>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

export { WebsitesContext, WebsitesProvider };

/*============  End of Exports  =============*/
