/*=============================================
=                   IMPORTS                   =
=============================================*/
import useNotification from "../hooks/useNotification";

/*============  End of IMPORTS  =============*/

/*=============================================
=                   Functions                   =
=============================================*/

/* getFields()
-------------------------------------------------- */
/**
 * Retrieves the form fields configuration from the Firebase database and updates the state with the retrieved data.
 * @param {Firebase} firebase - The Firebase instance.
 * @param {Function} stateSetter - The function to update the state with the retrieved form fields configuration.
 * @returns None
 */
export async function getFields(firebase, stateSetter) {
	firebase.db
		.get(`websites/types/?id=performer`)
		.then((formData) => {
			if (formData.inputs) {
				stateSetter(formData.inputs);
			} else {
				throw new Error(
					"Unable to get the form configuration from the database"
				);
			}
		})
		.catch((error) => useNotification().add("danger", error.message));
}

/* handleFormSubmit()
-------------------------------------------------- */
/**
 * Handles the form submission by updating the website data in the Firebase database.
 * @param {Firebase} firebase - The Firebase instance.
 * @param {string} websiteId - The ID of the website to update.
 * @param {object} data - The updated data for the website.
 * @param {Websites} websites - The Websites instance.
 * @param {Notification} notification - The Notification instance.
 * @returns None
 */
export async function handleFormSubmit(
	firebase,
	websiteId,
	data,
	websites,
	notification
) {
	firebase.db.setLoading(true);

	const promise = firebase.db
		.patch(
			`websites/${websiteId}`,
			{
				...data,
			},
			firebase.user?.accessToken ?? null
		)
		.then(() => websites.updateWebsiteData(websiteId, data));

	notification.withPromise(promise, {
		pending: "Updating website",
		success: "Website Updated",
	});
	await promise;
}

/*============  End of Functions  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
