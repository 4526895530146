/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { Link } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Heading, Container, Image } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function UserActions({ loading, firebase }) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const notification = useNotification();

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (loading) {
		// Return Loading Page if Firebase is still loading
		return <p>Loading...</p>;
	} else {
		try {
			if (!firebase.user) {
				// API Request Returned an Error: Throw New Error
				throw new Error("Unable to get user from DB.");
			} else {
				// Otherwise, return Websites Cards
				return (
					<>
						<hr />
						<Container
							display="flex"
							p={3}
							renderAs={Link}
							to="/dashboard/settings"
							alt="Go to settings page"
						>
							<Image
								src={
									firebase.auth?.currentUser?.photoURL?.toString() ??
									process.env.PUBLIC_URL + "/blank-profile-picture.png"
								}
								referrerPolicy="no-referrer"
								fallback={process.env.PUBLIC_URL + "/blank-profile-picture.png"}
								rounded
								style={{ borderColor: "rgb(209, 170, 95)" }}
								size={48}
							/>
							<div>
								<Heading
									size={6}
									ml={3}
									mb={4}
									m={2}
								>
									{firebase.user.displayName ?? "Manage Account"}
									{/* DISABLED DUE TO BUG
                   <Dropdown
                    up
                    closeOnSelect
                    hoverable
                    className="discreet-dropdown"
                    label={<FontAwesomeIcon icon={faEllipsis} />}
                  >
                    <Dropdown.Item
                      renderAs="button"
                      value="support"
                    >
                      Update Profile
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item
                      renderAs={Button}
                      value="delete"
                      onClick={(e) => onSignOutClick(e)}
                    >
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown> */}
								</Heading>
								<Heading
									size={7}
									pt={1}
									ml={3}
									subtitle
								>
									{firebase.user.email ?? "Error: No Email found"}
								</Heading>
							</div>
						</Container>
					</>
				);
			}
		} catch (error) {
			// If Request Returned an Error, Add error notification
			notification.add("danger", error.message);

			// Return Page Content -- TODO: Return Empty Card
			return <p>Unable to get user from DB</p>;
		}
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
