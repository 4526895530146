/*=============================================
=                   IMPORTS                   =
=============================================*/
import { Form } from "react-bulma-components";

/*============  End of IMPORTS  =============*/

/*=============================================
=                   Functions                   =
=============================================*/

/* handleFieldChangeEvent()
-------------------------------------------------- */
/**
 * Handles a field change event by updating the state with the new value.
 * @param {Event} e - The event object.
 * @param {string} group - The group identifier for the field.
 * @param {string} id - The identifier for the field.
 * @param {function} stateSetter - The state setter function.
 * @param {number} maxLength - The maximum length of the field value.
 * @param {object} validation - The validation object.
 * @returns None
 */
export function handleFieldChangeEvent(
	e,
	group,
	id,
	stateSetter,
	maxLength,
	validation
) {
	const value = e.currentTarget.value;
	validation?.setValidationMessage(""); // Reset validation message onInput

	if (maxLength) {
		value.slice(0, maxLength);
	}

	stateSetter((currentValue) => ({
		...currentValue,
		[group]: {
			...currentValue[group],
			[id]: value,
		},
	}));
}

/* handleFieldChangeValue()
-------------------------------------------------- */
/**
 * Updates the value of a field in the state object using the provided state setter function.
 * @param {any} value - The new value for the field.
 * @param {string} group - The group to which the field belongs.
 * @param {string} id - The ID of the field.
 * @param {function} stateSetter - The state setter function to update the state object.
 * @returns None
 */
export function handleFieldChangeValue(value, group, id, stateSetter) {
	stateSetter((currentValue) => ({
		...currentValue,
		[group]: {
			...currentValue[group],
			[id]: value,
		},
	}));
}

/* ShowValidationMessage()
-------------------------------------------------- */
/**
 * Displays a validation message based on the provided validation object.
 * @param {Object} validation - The validation object containing the color and validation message.
 * @returns {JSX.Element | null} - The JSX element displaying the validation message, or null if no validation message is present.
 */
export function ShowValidationMessage(validation) {
	const { color, validationMessage } = validation;

	if (validationMessage) {
		return <Form.Help color={color}>{validationMessage}</Form.Help>;
	}
}

/* ShowMaxChars()
-------------------------------------------------- */
/**
 * Displays the number of characters used out of the maximum length and applies a color
 * based on the remaining characters.
 * @param {string} value - The value to check the length of.
 * @param {number} length - The maximum length allowed.
 * @returns A React component that displays the number of characters used and applies a color
 * based on the remaining characters.
 */
export function ShowMaxChars(value, length) {
	const color = value.length > length - 30 ? "warning" : "info"; // Changes to a warning colour if the value is getting close to the maximum length.

	return (
		<Form.Help color={color}>
			{value.length ?? 0} of {length ?? 0} characters used.
		</Form.Help>
	);
}

/**
 * Checks if accessing certain items requires an upgrade based on the subscription status.
 * @param {Array} accessing - The items being accessed.
 * @param {Object} subscribedTo - The subscription status of the items.
 * @returns {boolean} True if an upgrade is required, false otherwise.
 */
export function requiresUpgrade(accessing, subscribedTo) {
	if (subscribedTo === undefined) {
		return true;
	}

	for (let i = 0; i < accessing.length; i++) {
		const item = accessing[i];
		// Check if the item exists in the object and has a truthy value
		if (!(item in subscribedTo) || !subscribedTo[item]) {
			return true; // Return true if any item does not exist or has a falsy value
		}
	}

	// If all items exist with truthy values, return false
	return false;
}

/*============  End of Functions  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
