/* React Imports */

/* Hooks & Helpers Imports */
import { toast } from "react-toastify";

/* Components Imports */

/* Icons Imports */

/* Components Styling */

export default function useNotification() {
	/* Hook Values */

	/* States */

	/* Function Dependancies */
	async function getStyle(name) {
		// Converts the value name from Bulma Names to Toastify Names
		if (name === "danger") {
			return "error";
		} else {
			// If the name is info, success, warning the name does not need to be adjusted
			return name;
		}
	}

	const toastSettings = {
		position: toast.POSITION.TOP_RIGHT, // Sets loaction to Top Right Corner
		autoClose: 8000, // Close the notification after 8 seconds
		pauseOnFocusLoss: true, // Pause the timer if the user clicks out of the browser window
		toastId: Date.now(), // Prevent duplication by generating a Current ID
		draggablePercent: 60, // Allows the toast to be removed if dragged 60%
		type: "default",
	};

	/* Functions */

	async function add(
		style = "warning",
		message = "There was an unexpected issue.",
		customSettings
	) {
		if (style === "danger") {
			message = convertErrorCodes(message);
		}

		const settings = { ...toastSettings, ...customSettings }; // Merge customSettings inside toastSettings. customSettings overrides toastSettings.

		settings.type = await getStyle(style); // Adds the correct type variable to the settings object
		toast(message + ".", settings);
	}

	function withPromise(promise, responses) {
		// Ensure that 'promise' is actually a Promise
		if (!(promise instanceof Promise)) {
			console.error("The 'promise' parameter is not a Promise.");
			return;
		}

		toast.promise(promise, {
			pending: {
				render() {
					return responses.pending ?? "Loading....";
				},
				icon: "⏳",
			},
			success: {
				render({ data }) {
					if (typeof data !== "string") {
						return "Task Completed.";
					}

					if (data) {
						return data;
					}

					return "Task Completed.";
				},
				// Other options
				icon: "🟢",
			},
			error: {
				render({ data }) {
					console.log(data);
					// Access the error message from data
					return convertErrorCodes(data);
				},
				icon: "🛑",
			},
		});
	}

	function convertErrorCodes(errorCode) {
		// console.log(errorCode);
		if (!navigator.onLine) {
			return "Unable to connect to the database as you are not currently online. Check your internet connection and reload the page";
		} else if (errorCode === "Failed to fetch") {
			// Change errorMessage to errorCode
			return "Unable to connect to the server. Please try again later";
		}

		const errorCodes = {
			"Firebase: Error (auth/invalid-email).":
				"Invalid email address. Please try again or contact support",
			"Firebase: Error (auth/wrong-password).":
				"Wrong password. Please try again or contact support",
			"Firebase: Error (auth/email-already-in-use).":
				"This account already exists. Please try again or contact support",
			"Firebase: Error (auth/weak-password).":
				"Please enter a stronger password, try to include special characters such as capital letters and symbols",
			"Firebase: Error (auth/operation-not-allowed).":
				"Sorry, there was a problem. Contact support: auth/op-not-allowed",
			"Firebase: Error (auth/popup-closed-by-user).":
				"Unable to sign in. Please check that your popup blocker is not preventing the sign in window",
			"Firebase: Error (auth/requires-recent-login).":
				"Unable to update your profile. Please sign out and back in again",
			"Firebase: Error (auth/user-disabled).":
				"Your account has been suspended, please contact support",
			"Too Many Requests":
				"You have been temporarily blocked from our network. Please try again in 1 Hour",
			Unauthorized:
				"You are not signed in or your session has expired, please sign in",
		};

		if (errorCodes[errorCode]) {
			return errorCodes[errorCode];
		}

		return `Oops! ${errorCode}. Please try again or contact support`;
	}

	/* Return */
	return { add, withPromise, toastSettings };
}
