/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import React, { useContext, useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import { useStripe, useElements } from "@stripe/react-stripe-js";
import useNotification from "../hooks/useNotification";
import useCrisp from "../hooks/useCrisp";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { PaymentElement } from "@stripe/react-stripe-js";
import {
	Form,
	Button,
	Notification,
	Section,
	Heading,
	Columns,
	Tag,
	Block,
} from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A component that renders a Stripe payment form.
 * @param {object} props - The component props.
 * @returns The rendered StripeForm component.
 */
export default function StripeForm(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const notification = useNotification();
	const stripe = useStripe();
	const elements = useElements();
	const invoiceData = props.subscriptionData.invoice_status;
	const productData = invoiceData.lines.data[0];
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const supportChat = useCrisp();

	/* Dependencies
  -------------------------------------------------- */
	// Set the Domain Search Form button to not loading incase user closes this tab
	props.setDomainSearchLoading(false);

	const paymentElementOptions = {
		layout: {
			type: "tabs",
			defaultCollapsed: false,
		},
		business: { name: "Web Design for Actors" },
		wallets: { applePay: "auto", googlePay: "auto" },
		defaultValues: {
			billingDetails: {
				name: firebase.user.displayName,
				email: firebase.user.email,
			},
		},
	};
	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Handles the form submission event.
	 * @param {Event} e - The form submission event.
	 * @returns None
	 */
	async function handleSubmit(e) {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: window.location.href,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (
			error.type === "card_error" ||
			error.type === "validation_error" ||
			error.message
		) {
			setMessage(error.message);
			notification.add("warning", error.message);
		} else {
			setMessage("An unexpected error occurred.");
			notification.add("danger", "An unexpected error occured.");
		}
		setIsLoading(false);
	}

	/**
	 * Returns the price of a product based on the invoice and product data.
	 * If the total price is different from the subtotal price, it returns a JSX element
	 * with the original price crossed out and the discounted price displayed along with
	 * the recurring interval. Otherwise, it returns a JSX element with the total price
	 * and the recurring interval.
	 * @returns {JSX.Element} - The JSX element representing the price.
	 */
	function returnPrice() {
		if (invoiceData.total !== invoiceData.subtotal) {
			return (
				<>
					<p>
						<span style={{ textDecoration: "line-through" }}>
							£{invoiceData.subtotal / 100}
						</span>{" "}
						£{invoiceData.total / 100} this{" "}
						{productData.price.recurring.interval}
					</p>
				</>
			);
		} else {
			return (
				<p>
					£{invoiceData.total / 100} per {productData.price.recurring.interval}
				</p>
			);
		}
	}
	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<>
			<form
				id="payment-form"
				className="builder"
				onSubmit={handleSubmit}
			>
				<>
					<Section>
						<Columns vCentered>
							<Columns.Column size="one-third">
								<img
									src={process.env.PUBLIC_URL + `/default/demo_images/blue.png`}
									width="100%"
									alt="Website Theme Preview"
								/>
							</Columns.Column>
							<Columns.Column>
								<Block>
									<Tag.Group>
										<Tag color="info">{productData.plan.nickname} Package</Tag>{" "}
										<Tag color="info">www.{productData.metadata.domain}</Tag>
									</Tag.Group>
								</Block>
								<Block>
									<Heading>Your website subscription</Heading>
									<Heading subtitle>{returnPrice()}</Heading>
								</Block>
								<Block>
									<Heading
										subtitle
										size={6}
									>
										Remove usage limits, setup your free domain and get access
										to emails sent to your website. Use our simple website
										editor at any time.
									</Heading>
								</Block>
								<Button.Group>
									<Button
										mr={5}
										text
										className="buttonAsLink"
										textWeight="bold"
										type="button"
										onClick={() => {
											supportChat.then((crisp) => crisp.chat.open());
										}}
									>
										Contact Support
									</Button>
								</Button.Group>
							</Columns.Column>
						</Columns>
					</Section>
				</>
				<Section>
					<Form.Field>
						<Form.Control>
							<PaymentElement
								id="payment-element"
								options={paymentElementOptions}
							/>
						</Form.Control>
					</Form.Field>
				</Section>

				{!message && (
					<Button.Group align="center">
						<Button
							submit
							size="medium"
							color="primary"
							loading={isLoading}
							disabled={isLoading || !stripe || !elements}
							id="submit"
							align="center"
						>
							<span id="button-text">Pay £{invoiceData.total / 100} Now</span>
						</Button>
					</Button.Group>
				)}
				{/* Show any error or success messages */}
				{message && (
					<Notification
						id="payment-message"
						color="warning"
						m={5}
					>
						<Button
							remove
							onClick={() => setMessage(null)}
						/>
						{message}{" "}
						<Button
							text
							className="buttonAsLink"
							onClick={() => setMessage(null)}
						>
							Click here to try again
						</Button>
						.
					</Notification>
				)}
			</form>
		</>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
