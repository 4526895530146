/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useContext, useEffect } from "react";
import { Outlet, useParams, NavLink, useSearchParams } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useCrisp from "../hooks/useCrisp";
import useNotification from "../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";
import { WebsitesContext } from "../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import {
	Columns,
	Section,
	Tabs,
	Heading,
	Container,
	Button,
	Tag,
} from "react-bulma-components";
import SelectImages from "../helpers/SelectImages";
import PopupModal from "../components/PopupModal";
import FormFieldSearchDomain from "../components/FormComponents/DomainSearch";
import WebsiteActions from "../components/WebsiteActions";
import GenericConfirmActionPopup from "../components/PopupModals/GenericConfirmAction_Popup";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function EditWebsite(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const notification = useNotification();
	const websites = useContext(WebsitesContext);
	const { websiteId } = useParams(); // Get Site ID to Edit from URL - Can't change name of this const without updating it in App.js!
	const [websiteData, setWebsiteData] = useState(null);
	const [searchParams] = useSearchParams();
	const supportChat = useCrisp();
	const { pageTitle, setPageTitle } = props;
	const [newSite, setNewSite] = useState(() => searchParams.get("new")); // State for Popup Modal
	const [paymentIntentModal, setPaymentIntentModal] = useState(() =>
		searchParams.get("payment_intent")
	); // State for Popup Modal
	const [updateDBPopup, setUpdateDBPopup] = useState(false);
	const [paymentStatus] = useState(() => searchParams.get("redirect_status")); // State for Popup Modal
	const [edited, setEdited] = useState(false);
	const [searchDomain, setSearchDomain] = useState("");
	const outletContext = {
		// Context to send to edit pages
		firebase,
		SelectImages,
		edited,
		setEdited,
		notification,
		websiteData,
	};

	/* Dependencies
  -------------------------------------------------- */

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		// Get the current website based on websiteId
		if (!firebase.authLoading && websites.websites && websiteId) {
			// Check websites exist before setting the current website
			const thisWebsite = websites.websites.find(
				// Find website based on matching Ids in object & url
				(website) => website.id === websiteId
			);
			setWebsiteData(thisWebsite); // Set the website

			if (thisWebsite.data.data_version <= 1) {
				setUpdateDBPopup(true);
			}
		}
	}, [
		websiteId,
		firebase.onAuthStateChange,
		websites.websites,
		setWebsiteData,
		firebase.authLoading,
	]); //Re-run on re-auth, websites updated or websiteId change

	/* Set Page Title */
	useEffect(() => {
		setPageTitle("Edit your website");
	}, [setPageTitle]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (firebase.authLoading) {
		return <p>Loading...</p>; //TODO: Loading Component
	} else if (!firebase.authLoading && websiteData) {
		return (
			<>
				<PopupModal
					width="50rem"
					body={
						<p>
							Until you choose a name for your website, you can preview your
							website via this temporary access link:{" "}
							<a
								href={`https://${websiteData.data.domain}`}
								alt="Temporary Access Link"
								target="_blank"
								rel="noreferrer"
							>
								{websiteData.data.domain}
							</a>
							<br />
							<br />
							When you publish your website there are no restrictions on page
							views, but this temporary link is capped at 100 views.
							<br />
						</p>
					}
					title="Hooray! Your website has been created."
					footer={
						<>
							<Button
								color="primary"
								onClick={() => {
									const link = `https://${websiteData.data.domain}`;
									window.open(link);
								}}
							>
								Preview Website
							</Button>
						</>
					}
					state={newSite}
					stateSetter={setNewSite}
				/>

				<PopupModal
					width="50rem"
					body={
						<p>
							Your payment has {paymentStatus}.
							<br />
						</p>
					}
					title="Payment Update"
					footer={
						<>
							<Button
								color="dark"
								onClick={() => supportChat.then((crisp) => crisp.chat.open())}
							>
								Contact Support
							</Button>
							<Button
								color="primary"
								onClick={() => setPaymentIntentModal(false)}
							>
								Close
							</Button>
						</>
					}
					state={paymentIntentModal}
					stateSetter={setPaymentIntentModal}
				/>

				<GenericConfirmActionPopup
					state={updateDBPopup}
					stateSetter={setUpdateDBPopup}
					title="Data Upgrade Required"
					body="We just need to do update your website to the latest version. Cancelling this may result in your website data being lost. Press Continue and we'll get this done for you now - it should only take a few seconds."
					action={async () => {
						await firebase.db
							.patch(
								`websites/data-upgrade?domain=${websiteData.data.domain}&toVersion=1to2`,
								{},
								firebase.accessToken
							)
							.then(
								async (res) => await websites.updateWebsiteData(websiteId, res)
							)
							.then(() => setUpdateDBPopup(false));
					}}
				/>
				<Columns.Column>
					<Columns className="content-parent-container">
						<Columns.Column size="full">
							<Section>
								<Heading size={1}>{pageTitle}</Heading>
								<Heading
									subtitle
									style={{
										fontWeight: "600",
										textTransform: "lowercase",
									}}
								>
									{websiteData?.data?.domain ?? "Website not found."}
									<WebsiteActions
										website={websiteData}
										setWebsiteData={setWebsiteData}
									/>
								</Heading>
								<Tag.Group alignContent="center">
									{websiteData.data.status && (
										<Tag color="primary">
											Website is {websiteData.data.status}
										</Tag>
									)}
									{websiteData.data.package && (
										<Tag color="primary">
											{websiteData.data.package} Package
										</Tag>
									)}
									{websiteData.data.data_version <= 1 && (
										<Tag color="danger">Data Update Required</Tag>
									)}
								</Tag.Group>
							</Section>
							<Container
								className="float-top-on-mobile"
								p={3}
								backgroundColor="#fff"
							>
								<Tabs
									fullwidth
									type="toggle"
								>
									<Tabs.Tab
										renderAs={NavLink}
										to="template,intro"
									>
										Introduction to You
									</Tabs.Tab>
									<Tabs.Tab
										renderAs={NavLink}
										to="socials,testimonials"
									>
										Social Media
									</Tabs.Tab>
									<Tabs.Tab
										renderAs={NavLink}
										to="skills,training"
									>
										Skills & Experience
									</Tabs.Tab>
									<Tabs.Tab
										renderAs={NavLink}
										to="contacts"
									>
										Contact Information
									</Tabs.Tab>
									<Tabs.Tab
										renderAs={NavLink}
										to="gallery"
									>
										Gallery
									</Tabs.Tab>
									<Tabs.Tab
										renderAs={NavLink}
										to="blog"
									>
										Blog
									</Tabs.Tab>
									<Tabs.Tab
										renderAs={NavLink}
										to="emails"
									>
										Contact Forms
									</Tabs.Tab>
								</Tabs>
							</Container>
							{websiteData.data?.package?.toLowerCase() === "demo" && (
								<Section className="content">
									<FormFieldSearchDomain
										domain={searchDomain}
										websiteId={websiteId}
										setDomain={setSearchDomain}
										initialLabelText="Remove usage limits by publising your website"
										initialHelpText="Choose a domain name to get started"
									/>
								</Section>
							)}
							<Section className="content">
								<Outlet context={outletContext} />
							</Section>
						</Columns.Column>
					</Columns>
				</Columns.Column>
			</>
		);
	} else {
		return <p>Could not return website</p>;
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
