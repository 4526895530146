/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Columns } from "react-bulma-components";
import SelectImages from "../../helpers/SelectImages";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A component that displays a color scheme and allows the user to select a new color scheme.
 * @param {object} props - The component props.
 * @returns The rendered component.
 */

export function ColourScheme(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Columns.Column
			className="accordian"
			p={0}
			m={0}
		>
			{firebase.user?.accessToken && (
				<SelectImages
					colorScheme={props.value}
					setColorScheme={props.onChange}
					firebase={firebase}
					template="default"
				/>
			)}
		</Columns.Column>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
