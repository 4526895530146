/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../hooks/useNotification";
import { formatDistanceToNow } from "date-fns";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import {
	Columns,
	Section,
	Heading,
	Container,
	Tag,
	Block,
	Button,
	Card,
	Media,
	Image,
} from "react-bulma-components";
import UpdateUserProfilePopup from "../components/PopupModals/UpdateUserProfile_Popup";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCrown,
	faUserCheck,
	faLock,
	faUnlock,
} from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

export default function ManageUsers(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const notification = useNotification();
	const redirect = useNavigate();
	const { pageTitle, setPageTitle } = props;
	const [users, setUsers] = useState([]);
	const [updatePopup, setUpdatePopup] = useState({ state: false, user: null });

	/* Dependencies
  -------------------------------------------------- */
	const hasLoaded = useRef(false);

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (!hasLoaded.current) {
			firebase.db
				.get(`userauth/users/`, firebase.accessToken)
				.then((res) => setUsers([...res.users]));
		}

		hasLoaded.current = true;
	}, [firebase.accessToken, firebase.db, notification]);

	useEffect(() => {
		setPageTitle("Manage Users");
	}, [setPageTitle]);

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Returns the value of a datapoint if it exists, otherwise returns a fallback value.
	 * @param {any} datapoint - The datapoint to check.
	 * @param {string} fallback - The fallback value to use if the datapoint is undefined or null.
	 * @returns The value of the datapoint if it exists, otherwise the fallback value.
	 */
	function safeText(datapoint, fallback) {
		if (!datapoint) {
			return `Unknown ${fallback}`;
		} else {
			return datapoint;
		}
	}

	/**
	 * Formats a date value to a human-readable format representing the time elapsed since the given date.
	 * @param {Date} value - The date value to format.
	 * @returns {string} A human-readable string representing the time elapsed since the given date.
	 */
	function formatDate(value) {
		if (!value) {
			return "";
		}
		return formatDistanceToNow(new Date(value), { addSuffix: true });
	}

	/**
	 * Updates the user data for a specific user in the users array.
	 * @param {string} userId - The unique identifier of the user.
	 * @param {object} updatedData - The updated data for the user.
	 * @returns None
	 */
	function handleUpdatedUser(userId, updatedData) {
		const userIndex = users.findIndex((user) => user.uid === userId);
		const updatedUsers = [
			...users.slice(0, userIndex),
			{ ...users[userIndex], ...updatedData },
			...users.slice(userIndex + 1),
		];

		setUsers(updatedUsers);
	}

	/**
	 * Handles updating a user's information in the Firebase database.
	 * @param {string} userId - The ID of the user to update.
	 * @param {object} body - The updated user data.
	 * @returns None
	 */
	function handleUserUpdate(userId, body) {
		const promise = firebase.db
			.patch(`userauth/users/?userId=${userId}`, body, firebase.accessToken)
			.then((res) => {
				handleUpdatedUser(userId, res);
			});

		notification.withPromise(promise, {
			pending: `Updating User ${userId}`,
			success: "Successfully updated user.",
		});
	}

	/**
	 * Handles the admin privileges for a user.
	 * @param {string} userId - The ID of the user.
	 * @param {string} method - The method to perform on the user's admin privileges. Can be "promote" or "demote".
	 * @returns None
	 */
	function handleAdminPrivelages(userId, method) {
		const promise = firebase.db
			.patch(
				`userauth/privelages?userId=${userId}&admin=${
					method === "promote" ? "true" : "false"
				}`,
				null, //body
				firebase.accessToken
			)
			.then((res) => handleUpdatedUser(userId, res));

		notification.withPromise(promise, {
			pending: `${method}ing User ${userId}`,
			success: `Successfully ${method}ed User`,
		});
	}

	/**
	 * Authenticates the user with the given userId.
	 * @param {string} userId - The ID of the user to authenticate as.
	 * @returns None
	 */
	async function authenticateAsUser(userId) {
		// Get a new access token for the current user
		firebase.db
			.get(`userauth/auth/?userId=${firebase.user.uid}`, firebase.accessToken)
			.then((res) => localStorage.setItem("admin_id_token", res));

		// Get an access token for the new user
		const promise = firebase.db
			.get(`userauth/auth/?userId=${userId}`, firebase.accessToken)
			.then(
				(res) =>
					firebase
						.signInWithCustomToken(firebase.auth, res) // Sign in with this token
						.then(redirect("/dashboard")) // Reedirect to dashboard
			);

		notification.withPromise(promise, {
			pending: "Creating authentification token",
			success: "Logged in as user",
		});
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Columns.Column>
			<UpdateUserProfilePopup
				state={updatePopup}
				stateSetter={setUpdatePopup}
				user={updatePopup.user}
				firebase={firebase}
			/>
			<Columns className="content-parent-container">
				<Columns.Column>
					<Section>
						<Heading
							size={1}
							style={{
								fontWeight: "500",
								textTransform: "capitalize",
							}}
						>
							{pageTitle}
						</Heading>
						<Heading
							subtitle
							style={{
								fontWeight: "600",
							}}
						>
							Find a user to manage.
						</Heading>
					</Section>
					<Container>
						<Section className="">
							<Columns>
								{users.length > 0 &&
									users.map((user) => {
										return (
											<Columns.Column
												size="one-third"
												key={user.uid}
											>
												<Card
													className="selectable"
													style={{ height: "100%" }}
												>
													<Card.Content>
														<Media>
															<Media.Item
																renderAs="figure"
																align="left"
															>
																<Image
																	src={
																		user.photoURL
																			? user.photoURL
																			: process.env.PUBLIC_URL +
																			  "/blank-profile-picture.png"
																	}
																	size={64}
																	alt={user.displayName ?? user.email}
																	rounded
																	fallback={
																		process.env.PUBLIC_URL +
																		"/blank-profile-picture.png"
																	}
																/>
															</Media.Item>
															<Media.Item>
																<Heading size={4}>
																	{safeText(user.displayName, "Name")}
																</Heading>
																<Heading
																	subtitle
																	size={6}
																>
																	{safeText(user.email, "Email")}
																</Heading>
															</Media.Item>
														</Media>
														<Tag.Group>
															<Tag color="info">
																Signed Up{" "}
																{formatDate(user?.metadata?.creationTime)}
															</Tag>
															<Tag color="info">
																Signed In{" "}
																{formatDate(user?.metadata?.lastSignInTime)}
															</Tag>

															{user.disabled ? (
																<Tag.Group
																	hasAddons
																	mr={3}
																>
																	<Tag color="danger">Account Disabled</Tag>
																	<Tag
																		renderAs={Button}
																		color="light"
																		onClick={() =>
																			handleUserUpdate(user.uid, {
																				disabled: false,
																			})
																		}
																	>
																		<FontAwesomeIcon icon={faUnlock} />
																	</Tag>
																</Tag.Group>
															) : (
																<Tag.Group
																	hasAddons
																	mr={3}
																>
																	<Tag color="success">Account Enabled</Tag>
																	<Tag
																		renderAs={Button}
																		color="light"
																		onClick={() =>
																			handleUserUpdate(user.uid, {
																				disabled: true,
																			})
																		}
																	>
																		<FontAwesomeIcon icon={faLock} />
																	</Tag>
																</Tag.Group>
															)}
															{user.emailVerified ? (
																<Tag.Group mr={2}>
																	<Tag color="success">Email Verified</Tag>
																</Tag.Group>
															) : (
																<Tag.Group
																	hasAddons
																	mr={2}
																>
																	<Tag color="warning">Email Not Verified</Tag>
																	<Tag
																		renderAs={Button}
																		color="light"
																		onClick={() =>
																			handleUserUpdate(user.uid, {
																				emailVerified: true,
																			})
																		}
																	>
																		<FontAwesomeIcon icon={faUserCheck} />{" "}
																	</Tag>
																</Tag.Group>
															)}
															{user.customClaims?.admin ? (
																<Tag.Group
																	hasAddons
																	mr={2}
																>
																	<Tag color="primary">
																		<FontAwesomeIcon icon={faCrown} /> &nbsp;
																		Admin
																	</Tag>
																</Tag.Group>
															) : (
																<Tag.Group
																	hasAddons
																	mr={2}
																>
																	<Tag color="info">Customer</Tag>
																</Tag.Group>
															)}
														</Tag.Group>
														<Block mt={3}>
															<Button.Group style={{ marginBottom: "0" }}>
																<Button
																	fullwidth={
																		firebase?.user?.uid === user?.uid ||
																		user?.customClaims?.admin
																	}
																	disabled={user.disabled}
																	onClick={() =>
																		setUpdatePopup({ state: true, user })
																	}
																>
																	Update User Profile
																</Button>

																{!user?.customClaims?.admin &&
																	firebase?.user?.uid !== user?.uid && (
																		<Button
																			onClick={() =>
																				authenticateAsUser(user.uid)
																			}
																			disabled={user.disabled}
																		>
																			Login as User
																		</Button>
																	)}

																{user?.customClaims?.admin ? (
																	<Button
																		fullwidth
																		color="dark"
																		onClick={() =>
																			handleAdminPrivelages(user.uid, "demote")
																		}
																		disabled={firebase?.user?.uid === user?.uid}
																	>
																		<FontAwesomeIcon icon={faCrown} />
																		&nbsp; Demote Administrator
																	</Button>
																) : (
																	<Button
																		fullwidth
																		color="dark"
																		onClick={() =>
																			handleAdminPrivelages(user.uid, "promote")
																		}
																		disabled={
																			firebase?.user?.uid === user?.uid ||
																			user.disabled
																		}
																	>
																		Make Administrator
																	</Button>
																)}
															</Button.Group>
														</Block>
													</Card.Content>
												</Card>
											</Columns.Column>
										);
									})}
							</Columns>
						</Section>
					</Container>
				</Columns.Column>
			</Columns>
		</Columns.Column>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
