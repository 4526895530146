/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */
import arrayMutators from "final-form-arrays";
import generateInitialValues from "../../helpers/generateFormValues";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Button } from "react-bulma-components";
import PopupModal from "../PopupModal";
import { Form } from "react-final-form";
import { DynamicFormFields } from "../FormComponents/DynamicFormFields";
import { FormSpy } from "react-final-form";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A generic form popup component that displays a modal with a form.
 * @param {object} props - The props passed to the component.
 * @param {object} props.state - The state object for the popup.
 * @param {function} props.stateSetter - The function to set the state of the popup.
 * @param {string} props.title - The title of the popup.
 * @param {string} props.confirmText - The text for the confirm button.
 * @param {string} props.cancelText - The text for the cancel button.
 * @param {string} props.notification - The notification message to display.
 * @returns The rendered component.
 */
export default function GenericFormPopup(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const {
		state,
		stateSetter,
		title, // String - Popup Prompt Title
		confirmText, // String - Overrides Confirm Button Text
		cancelText, // String - Overrides Cancel Button Text
		notification,
		firebase,
		formConfig, // Object - Contains websiteData Obj, formFields Obj, formFilters Arr, onlyShowIndex Str
		action,
	} = props;

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Retrieves the title of a page.
	 * @returns {string} - The title of the page. If no title is found, returns "More Information".
	 */
	function getTitle() {
		if (title) {
			return title;
		} else {
			return "More Information";
		}
	}

	/**
	 * Renders a form component with dynamic form fields based on the provided configuration.
	 * @returns The rendered form component.
	 */
	function Body() {
		const { formFilters, formFields, websiteData, onlyShowIndex } = formConfig;

		return (
			<Form
				initialValues={generateInitialValues({
					containerConfigs: formFields,
					websiteData: websiteData,
				})}
				mutators={{
					...arrayMutators,
				}}
				subscription={{
					submitting: true,
				}}
				onSubmit={async (values) => {
					if (typeof action === "function") {
						await action(values);
						stateSetter(false);
					} else {
						throw new Error(
							`Unable to submit. No action function was provided to the popup. Typeof ${typeof action} provided.`
						);
					}
				}}
			>
				{(props) => {
					return (
						<form
							onSubmit={props.handleSubmit}
							noValidate={true} // Disable default browser validation #WeDoItBetter
						>
							<DynamicFormFields
								formFields={formFields}
								{...props.form.mutators}
								firebase={firebase}
								notification={notification}
								groupFilters={formFilters}
								containerSettings={{
									displayLabel: true,
									displayHelp: true,
									isHorizontal: false,
								}}
								onlyShowIndex={onlyShowIndex}
							/>

							<FormSpy
								subscription={{
									pristine: true,
									invalid: true,
									errors: true,
								}}
							>
								{(props) => {
									return (
										<Button.Group>
											<Button
												color="dark"
												onClick={() => stateSetter(false)}
												disabled={props.loading}
											>
												{cancelText ? cancelText : "Cancel"}
											</Button>
											{!props.pristine && !props.invalid && (
												<Button
													type="Submit"
													color="primary"
													disabled={
														props.loading ||
														props.pristine ||
														props.invalid ||
														!props.errors
													}
													loading={props.loading}
												>
													{confirmText ? confirmText : "Continue"}
												</Button>
											)}
										</Button.Group>
									);
								}}
							</FormSpy>
						</form>
					);
				}}
			</Form>
		);
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<PopupModal
			state={state}
			stateSetter={stateSetter}
			title={getTitle()}
			body={Body()}
		/>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
