/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useContext, useEffect } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import { loadStripe } from "@stripe/stripe-js";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Elements } from "@stripe/react-stripe-js";
import { Columns, Section, Heading } from "react-bulma-components";
import CardsTable from "../components/UserProfile/CardsTable";
import SubscriptionTable from "../components/UserProfile/SubscriptionTable";
import BasicProfile from "../components/UserProfile/BasicProfile";
import AddNewCardPopup from "../components/PopupModals/AddNewCard_Popup";
import CancelSubscriptionPopup from "../components/PopupModals/CancelSubscription_Popup";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function UserSettings(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const stripePubKey = "pk_test_8whTdK2uGT2dwyYsDrHKndVf00Epcej71D";
	const [stripePromise] = useState(() => loadStripe(stripePubKey));
	const { pageTitle, setPageTitle } = props;
	const [clientSecret, setClientSecret] = useState(null);
	const [addCardPopup, setAddCardPopup] = useState(false);
	const [cancelSubscriptionPopup, setCancelSubscriptionPopup] = useState(false);
	const [subscription, setSubscription] = useState({});
	const [subscriptions, setSubscriptions] = useState([]);
	const [authMethods, setAuthMethods] = useState([]);

	/* Dependencies
  -------------------------------------------------- */
	const paymentElementOptions = {
		layout: {
			type: "tabs",
			defaultCollapsed: false,
		},
		business: { name: "Web Design for Actors" },
		wallets: { applePay: "auto", googlePay: "auto" },
		defaultValues: {
			billingDetails: {
				name: firebase.user.displayName,
				email: firebase.user.email,
			},
		},
	};

	const options = {
		// passing the client secret obtained from the server
		clientSecret: clientSecret,
	};

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		setPageTitle("Account Settings");
	}, [setPageTitle]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (firebase.authLoading) {
		return <p>Loading...</p>; //TODO: Loading Component
	} else if (!firebase.authLoading && firebase.user) {
		return (
			<>
				{clientSecret && (
					<Elements
						stripe={stripePromise}
						options={options}
					>
						{addCardPopup && (
							<AddNewCardPopup
								state={addCardPopup}
								stateSetter={setAddCardPopup}
								paymentElementOptions={paymentElementOptions}
								clientSecret={clientSecret}
							/>
						)}
					</Elements>
				)}
				<CancelSubscriptionPopup
					state={cancelSubscriptionPopup}
					stateSetter={setCancelSubscriptionPopup}
					subscription={subscription}
					setSubscriptions={setSubscriptions}
				/>{" "}
				<Columns.Column>
					<Columns className="content-parent-container">
						<Columns.Column>
							<Section>
								<Heading
									size={1}
									style={{
										fontWeight: "500",
										textTransform: "capitalize",
									}}
								>
									{pageTitle}
								</Heading>
								<Heading
									subtitle
									style={{
										fontWeight: "600",
										textTransform: "lowercase",
									}}
								></Heading>
							</Section>
							<Columns className="content-parent-container">
								<Columns.Column size="one-third">
									<Section>
										<Heading size={4}>Your Profile</Heading>
										<Heading
											subtitle
											size={6}
										>
											Update your name, email address or password.
										</Heading>
									</Section>
								</Columns.Column>
								<Columns.Column size="two-thirds">
									<Section className="content">
										<BasicProfile
											authMethods={authMethods}
											setAuthMethods={setAuthMethods}
										/>
									</Section>
								</Columns.Column>
								<Columns.Column size="one-third">
									<Section>
										<Heading size={4}>Billing Details</Heading>
										<Heading
											subtitle
											size={6}
										>
											Keep your payment details up to date.
										</Heading>
									</Section>
								</Columns.Column>
								<Columns.Column size="two-thirds">
									<Section className="content">
										<CardsTable
											addCardPopup={addCardPopup}
											setAddCardPopup={setAddCardPopup}
											clientSecret={clientSecret}
											setClientSecret={setClientSecret}
										/>
									</Section>
								</Columns.Column>
								<Columns.Column size="one-third">
									<Section>
										<Heading size={4}>Subscriptions</Heading>
										<Heading
											subtitle
											size={6}
										>
											Stay on top of your subscriptions.
										</Heading>
									</Section>
								</Columns.Column>
								<Columns.Column size="two-thirds">
									<Section className="content">
										<SubscriptionTable
											setCancelSubscriptionPopup={setCancelSubscriptionPopup}
											setSubscription={setSubscription}
											subscriptions={subscriptions}
											setSubscriptions={setSubscriptions}
										/>
									</Section>
								</Columns.Column>
							</Columns>
						</Columns.Column>
					</Columns>
				</Columns.Column>
			</>
		);
	} else {
		return <p>Could not return user settings</p>;
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
