/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import React from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import PropTypes from "prop-types";
/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Form } from "react-final-form";
import { Button, Section } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A wizard component that allows users to navigate through multiple pages and submit the final form.
 * @class Wizard
 * @extends React.Component
 * @property {function} onSubmit - The function to be called when the form is submitted.
 */
export default class Wizard extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
	};
	static Page = ({ children }) => children;

	/* State, Context and Hooks
  -------------------------------------------------- */
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			values: props.initialValues || {},
		};
	}

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/
	next = (values) =>
		this.setState((state) => ({
			page: Math.min(state.page + 1, this.props.children.length - 1),
			values,
		}));

	previous = () =>
		this.setState((state) => ({
			page: Math.max(state.page - 1, 0),
		}));

	/**
	 * NOTE: Both validate and handleSubmit switching are implemented
	 * here because 🏁 Redux Final Form does not accept changes to those
	 * functions once the form has been defined.
	 */

	handleSubmit = (values) => {
		const { children, onSubmit } = this.props;
		const { page } = this.state;
		const isLastPage = page === React.Children.count(children) - 1;
		if (isLastPage) {
			return onSubmit(values);
		} else {
			this.next(values);
		}
	};
	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	render() {
		const { children, mutators } = this.props;
		const { page, values } = this.state;
		const activePage = React.Children.toArray(children)[page];
		const isLastPage = page === React.Children.count(children) - 1;
		const isFirstPage = page === 0;

		if (values?.length < 1) {
			return <p>Loading...</p>;
		} else {
			return (
				<Form
					{...this.props}
					initialValues={values}
					validate={this.validate}
					onSubmit={this.handleSubmit}
					mutators={{
						...mutators,
					}}
				>
					{({ handleSubmit, submitting, values, form }) => (
						<Section size="medium">
							<form
								onSubmit={handleSubmit}
								noValidate={true}
								className="builder create"
								id="builderform"
							>
								{React.cloneElement(activePage)}
								<Button.Group
									align="center"
									size="medium"
								>
									{page > 0 && (
										<Button
											type="button"
											onClick={this.previous}
										>
											« Previous
										</Button>
									)}
									{isFirstPage && <Button type="button">Learn More</Button>}
									{!isLastPage && (
										<Button
											type="submit"
											color={isFirstPage ? "primary" : ""}
										>
											{page > 0 ? "Next Step" : "Get Started"}
										</Button>
									)}
									{isLastPage && (
										<Button
											type="submit"
											disabled={submitting}
											color="primary"
										>
											Submit
										</Button>
									)}
								</Button.Group>
								{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}{" "}
							</form>
						</Section>
					)}
				</Form>
			);
		}
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
