/* React Imports */

/* Hooks & Helpers Imports */

/* Components Imports */

/* Icons Imports */

/* Components Styling */

export default function useCard(firebase, notification) {
	/* Hook Values */

	/* States */

	/* Functions */
	function removeCard(e, customerId, cardId, stateSetter) {
		// Remove a card from stripe
		const promise = firebase.db
			.remove(
				`billing/cards/?customerId=${customerId}&cardId=${cardId}`,
				firebase?.accessToken
			)
			.then((res) => {
				stateSetter((prevState) =>
					prevState.filter((card) => card.id !== cardId)
				);
			})
			.catch((error) => {
				throw error;
			});

		notification.withPromise(promise, {
			pending: `Removing card ending ***${cardId}`,
		});
	}

	async function getSecret(e, customerId) {
		try {
			const res = await firebase.db.post(
				`billing/setupintents?customerId=${customerId}`,
				null, // Body
				firebase?.accessToken
			);

			if (!res.client_secret) {
				throw new Error(res.message);
			}

			return res.client_secret;
		} catch (error) {
			throw error;
		}
	}

	/* Return */
	return { getSecret, remove: removeCard };
}
