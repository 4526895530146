/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import arrayMutators from "final-form-arrays";
import generateInitialValues from "../helpers/generateFormValues";
import useNotification from "../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import {
	Columns,
	Level,
	Heading,
	Box,
	Container,
	Button,
	Block,
	Icon,
} from "react-bulma-components";
import PopupModal from "../components/PopupModal";
import { Form, FormSpy } from "react-final-form";
import { DynamicFormFields } from "../components/FormComponents/DynamicFormFields";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faApple, faGoogle } from "@fortawesome/free-brands-svg-icons";

/*============  End of IMPORTS  =============*/

export default function Login(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const notification = useNotification();
	const navigate = useNavigate();
	const { firebase, setPageTitle } = props;
	const [type, setType] = useState("provider");
	const [forgotPass, setForgotPass] = useState(false);

	/* Dependencies
  -------------------------------------------------- */
	const loginFormConfig = [
		{
			group: "login",
			id: "email",
			label: "Continue with Email",
			helpText: "Use an Email Address and Password",
			fields: [
				{
					component: "text",
					weight: 0,
					attributes: {
						label: "Email Address",
						placeholder: "Enter an Email Address",
						width: "100%",
					},
					validators: {
						required: true,
						type: "email",
					},
				},
			],
		},
		{
			group: "login",
			id: "pass",
			fields: [
				{
					component: "text",
					weight: 1,
					attributes: {
						label: "Password",
						placeholder: "Enter a Password",
						width: "100%",
					},
					validators: {
						required: true,
						type: "password",
						minValue: 8,
						maxValue: 50,
					},
				},
			],
		},
	];

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		setPageTitle("Create or Edit Your Website");
	}, [setPageTitle]);

	/*=============================================
  =                   Functions                   =
  =============================================*/
	async function handleSuccessfulAuth(authCredential, method) {
		// If Authentication was successful
		navigate("/dashboard"); // Redirect user to /dashboard

		if (authCredential) {
			const isNewUser =
				authCredential.user.metadata.creationTime ===
				authCredential.user.metadata.lastSignInTime;
			const firstName =
				authCredential?.user?.displayName?.split(" ")[0] ?? undefined;
			const email = authCredential?.user?.email ?? undefined;

			if (isNewUser) {
				if (firstName !== "undefined") {
					return `Hello ${firstName}, you've created a new account under ${email} via ${method}.`;
				} else {
					return `Signed Up via ${method} as ${email}`;
				}
			} else {
				return `Welcome back, ${firstName ?? email}`;
			}
		}
	}

	async function handleSignUp(email, pass) {
		await firebase
			.createUserWithEmailAndPassword(firebase.auth, email, pass)
			.then((userCredential) => {
				// Signed in
				handleSuccessfulAuth(userCredential, "Email");
			})
			.catch((error) => {
				throw error; // Return the error back to the notification
			});
	}

	async function handleSignInButtonClick(values) {
		const email = values.login.email[0]?.value;
		const pass = values.login.pass[0]?.value;

		const promise = firebase
			.signInWithEmailAndPassword(firebase.auth, email, pass)

			.then((authCredential) => {
				return handleSuccessfulAuth(authCredential, "Email");
			})
			.catch((error) => {
				if (error.code === "auth/user-not-found") {
					console.log("Email user not found, creating new account");
					handleSignUp(email, pass);
					return `Created a New Account ${email}`;
				}
				throw error; // Return the error back to the notification
			});

		notification.withPromise(promise, { pending: "Checking your Account" });
	}

	function handleGoogleClick(event) {
		event.preventDefault();
		const promise = firebase
			.signInWithPopup(firebase.auth, firebase.googleAuth)
			.then((authCredential) => {
				return handleSuccessfulAuth(authCredential, "Google");
			})
			.catch((error) => {
				throw error;
			});

		notification.withPromise(promise, {
			pending: "Signing in with Google",
		});
	}

	function handleAppleClick(event) {
		event.preventDefault();

		// Result from Redirect auth flow.
		const promise = firebase
			.signInWithPopup(firebase.auth, firebase.appleAuth)
			.then((authCredential) => {
				// The signed-in user info.
				return handleSuccessfulAuth(authCredential, "Apple ID");
			})
			.catch((error) => {
				throw error; // Return the error back to the notification
			});

		notification.withPromise(promise, {
			pending: "Signing in with Apple ID",
		});
	}

	async function handlePasswordResetClick(values) {
		const auth = firebase.auth;
		const email = values.login.email[0].value;

		const promise = firebase
			.sendPasswordResetEmail(auth, email, { url: window.location.href })
			.then(() => {
				setForgotPass(false);
				return "Successfully Sent Password Reset Email.";
			})
			.catch((error) => {
				setForgotPass(false);
				throw error;
			});

		notification.withPromise(promise, {
			pending: "Sending Password Reset Email",
		});

		await promise;
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<>
			<PopupModal
				state={forgotPass}
				stateSetter={setForgotPass}
				title="Reset your password"
				body={
					<>
						<Block>
							<Form
								initialValues={generateInitialValues({
									containerConfigs: loginFormConfig,
								})}
								mutators={{
									...arrayMutators,
								}}
								subscription={{
									values: true,
								}}
								onSubmit={async (values) =>
									await handlePasswordResetClick(values)
								}
							>
								{(props) => {
									return (
										<form
											onSubmit={props.handleSubmit}
											noValidate={true} // Disable default browser validation #WeDoItBetter
										>
											<DynamicFormFields
												formFields={loginFormConfig}
												{...props.form.mutators}
												firebase={firebase}
												fieldFilters={{ login: ["email"] }}
												notification={notification}
												containerSettings={{
													displayLabel: false,
													displayHelp: false,
													isHorizontal: false,
												}}
											/>{" "}
											<FormSpy
												subscription={{
													pristine: true,
													invalid: true,
													errors: true,
													submitting: true,
												}}
											>
												{(props) => {
													return (
														<Button.Group align="right">
															<Button
																type="button"
																color="dark"
																onClick={() => setForgotPass(false)} // Close the modal
															>
																Cancel
															</Button>
															{!props.pristine && !props.invalid && (
																<Button
																	type="submit"
																	color="primary"
																	loading={props.submitting}
																	disabled={props.submitting}
																>
																	Submit
																</Button>
															)}
														</Button.Group>
													);
												}}
											</FormSpy>
										</form>
									);
								}}
							</Form>
						</Block>
					</>
				}
			/>
			<Columns
				centered
				vCentered
				style={{ minHeight: "100vh", maxHeight: "100vh", margin: "auto" }}
				backgroundColor="light"
			>
				<Columns.Column
					desktop={{ size: 5 }}
					tablet={{ size: 10 }}
					mobile={{ size: 12 }}
				>
					<Container style={{ marginTop: "10vh" }}>
						<Level>
							<Level.Item>
								<img
									alt="Web Design for Actors Logo"
									src={process.env.PUBLIC_URL + "/WDFA_Logo.png"}
								/>
							</Level.Item>
						</Level>
						<Level>
							<Level.Item>
								<Heading
									subtitle
									style={{ fontWeight: "lighter" }}
									textAlign="center"
									size={6}
								>
									Create or update your website at any time
									<br /> using our easy website builder.
								</Heading>
							</Level.Item>
						</Level>

						<Level>
							<Level.Item style={{ minWidth: "100%" }}>
								<Box
									style={{
										minWidth: "100%",
									}}
									p={6}
									desktop={{ style: { midWidth: "50vw" } }}
									touch={{ style: { minWidth: "100vw", padding: "0px" } }}
									textAlign="center"
								>
									{/* <Heading
										pb={5}
										size={5}
										align="left"
									>
										{pageTitle}...
									</Heading> */}
									{type === "provider" && (
										<>
											<Button.Group
												hasAddons
												justifyContent="center"
											>
												<Button
													style={{ minWidth: "20vw" }}
													onClick={(e) => handleAppleClick(e)}
													fullwidth
												>
													<Icon>
														<FontAwesomeIcon icon={faApple} />
													</Icon>
													<b>Continue with Apple</b>
												</Button>
											</Button.Group>
											<Button.Group
												hasAddons
												justifyContent="center"
											>
												<Button
													color=""
													style={{ minWidth: "20vw" }}
													onClick={(e) => handleGoogleClick(e)}
													fullwidth
												>
													<Icon>
														<FontAwesomeIcon icon={faGoogle} />
													</Icon>
													<b>Continue with Google</b>
												</Button>
											</Button.Group>

											<Block
												display="flex"
												alignItems="center"
												m={1}
											>
												<hr style={{ border: "initial", width: "60%" }} />
												<p
													style={{
														width: "100%",
														fontSize: "14px",
														padding: "0",
														textAlign: "center",
													}}
												>
													don't have any of those?
												</p>
												<hr style={{ border: "initial", width: "60%" }} />
											</Block>
											<Button.Group
												hasAddons
												justifyContent="center"
											>
												<Button
													fullwidth
													onClick={() => setType("Email")}
												>
													Continue with Email Address
												</Button>
											</Button.Group>
										</>
									)}

									{type === "Email" && (
										<>
											<Form
												initialValues={generateInitialValues({
													containerConfigs: loginFormConfig,
												})}
												mutators={{
													...arrayMutators,
												}}
												subscription={{
													submitting: true,
												}}
												onSubmit={async (values) =>
													await handleSignInButtonClick(values)
												}
											>
												{(props) => {
													return (
														<form
															onSubmit={props.handleSubmit}
															noValidate={true} // Disable default browser validation #WeDoItBetter
														>
															<DynamicFormFields
																formFields={loginFormConfig}
																{...props.form.mutators}
																firebase={firebase}
																notification={notification}
																containerSettings={{
																	displayLabel: false,
																	displayHelp: false,
																	isHorizontal: false,
																}}
															/>

															<Button.Group
																align="right"
																pull="right"
																style={{ marginTop: "-25px" }}
															>
																<Button
																	text
																	className="buttonAsLink"
																	onClick={() => setForgotPass(true)} // Open Modal
																	type="button"
																	textSize={7}
																>
																	Forgot your Password?
																</Button>
															</Button.Group>

															<FormSpy
																subscription={{
																	pristine: true,
																	invalid: true,
																	errors: true,
																}}
															>
																{(props) => {
																	return (
																		!props.pristine &&
																		!props.invalid && (
																			<Button
																				type="Submit"
																				color="primary"
																				fullwidth
																				disabled={
																					props.loading ||
																					props.pristine ||
																					props.invalid ||
																					!props.errors
																				}
																			>
																				Continue
																			</Button>
																		)
																	);
																}}
															</FormSpy>
														</form>
													);
												}}
											</Form>

											<Block
												display="flex"
												alignItems="center"
												m={1}
											>
												<hr style={{ border: "initial", width: "60%" }} />
												<p
													style={{
														width: "100%",
														fontSize: "14px",
														padding: "0",
														textAlign: "center",
													}}
												>
													or continue with...
												</p>
												<hr style={{ border: "initial", width: "60%" }} />
											</Block>
											<Level>
												<Level.Item m={1}>
													<Button
														fullwidth
														onClick={handleGoogleClick}
													>
														<FontAwesomeIcon icon={faGoogle} />
													</Button>
												</Level.Item>
												<Level.Item m={1}>
													<Button
														fullwidth
														onClick={handleAppleClick}
													>
														<FontAwesomeIcon icon={faApple} />
													</Button>
												</Level.Item>
											</Level>
										</>
									)}
								</Box>
							</Level.Item>
						</Level>
						<Level>
							{type === "provider" && (
								<Level.Item>
									<a
										href="https://webdesignforactors.com"
										target="_blank"
										rel="noreferrer"
										className="buttonAsLink"
										mt={3}
									>
										<FontAwesomeIcon icon={faCaretLeft} /> &nbsp; Back to Web
										Design for Actors
									</a>
								</Level.Item>
							)}

							{type === "Email" && (
								<Level.Item>
									<Button
										className="buttonAsLink"
										text
										onClick={() => setType("provider")}
									>
										<FontAwesomeIcon icon={faCaretLeft} /> &nbsp; Go Back
									</Button>
								</Level.Item>
							)}
						</Level>
					</Container>
				</Columns.Column>
			</Columns>
		</>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
