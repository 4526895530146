/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useCrisp from "../hooks/useCrisp.js";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";
import { WebsitesContext } from "../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import WebsiteCards from "../components/WebsiteCards";
import { Section, Columns } from "react-bulma-components";
import PageHeading from "../components/PageHeading.js";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function Dashboard(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const websites = useContext(WebsitesContext);
	const { pageTitle, setPageTitle, loading } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const supportChat = useCrisp();

	/* Dependencies
  -------------------------------------------------- */
	const buttonsConfig = () => {
		const buttonArray = [];

		if (!searchParams.get("create")) {
			buttonArray.push({
				color: "primary",
				action: () => setSearchParams("create=true"),
				text: "Create New Website",
			});
		}

		buttonArray.push({
			color: "light",
			inverted: true,
			action: () => supportChat.then((crisp) => crisp.chat.open()),
			text: "Contact Support",
		});

		return buttonArray;
	};

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		setPageTitle("Manage a Website");
	}, [setPageTitle]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (firebase.authLoading) {
		// Return Loading Page if Firebase is still loading
		return <p>Loading...</p>;
	} else {
		try {
			if (websites.websites && websites.websites.error) {
				// API Request Returned an Error: Throw New Error
				throw new Error(websites.error);
			} else {
				// Otherwise, return Websites Cards
				return (
					<Columns.Column>
						<Columns className="content-parent-container">
							<Columns.Column>
								<Section>
									<PageHeading
										title={pageTitle}
										subtitle="Choose what to do next"
										loading={loading}
										buttons={buttonsConfig()}
									/>
									<WebsiteCards websites={websites.websites} />
								</Section>
							</Columns.Column>
						</Columns>
					</Columns.Column>
				);
			}
		} catch (error) {
			// If API Request Returned an Error, Add error notification
			// notification.add({ color: "danger", message: error.message });

			// Return Page Content -- TODO: Return Empty Card
			return <p>There was an error!</p>;
		}
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
