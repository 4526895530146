/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Button } from "react-bulma-components";
import PopupModal from "../PopupModal";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A generic confirmation action popup component.
 * @param {Object} props - The props object containing the necessary data for the component.
 * @param {boolean} props.state - The state of the popup.
 * @param {function} props.stateSetter - The function to set the state of the popup.
 * @param {function} props.action - The action to be performed when the confirmation is made.
 * @param {string} props.title - The title of the popup.
 * @param {string} props.body - The body text of the popup.
 * @param {string} props.confirmText - The text to display on the confirmation button.
 * @returns The rendered GenericConfirmActionPopup component.
 */
export default function GenericConfirmActionPopup(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const {
		state,
		stateSetter,
		action, // Function - Runs on "Confirm" Button pressed
		title, // String - Popup Prompt Title
		body, // String - Popup Prompt Text
		confirmText, // String - Overrides Confirm Button Text
		cancelText, // String - Overrides Cancel Button Text
	} = props;
	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Retrieves the title of the action confirmation dialog.
	 * If a title is provided, it is returned.
	 * Otherwise, the default title "Confirm Action" is returned.
	 * @returns {string} The title of the action confirmation dialog.
	 */
	function getTitle() {
		if (title) {
			return title;
		} else {
			return "Confirm Action";
		}
	}

	/**
	 * Retrieves the body of a document or returns a default message if the body is not available.
	 * @returns {string} - The body of the document or a default message.
	 */
	function getBody() {
		if (body) {
			return body;
		} else {
			return "This action may be irreversable. Are you sure?";
		}
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<PopupModal
			state={state}
			stateSetter={stateSetter}
			title={getTitle()}
			body={getBody()}
			footer={
				<Button.Group>
					<Button
						color="dark"
						onClick={() => stateSetter(false)}
					>
						{cancelText ? cancelText : "Go Back"}
					</Button>
					<Button
						color="primary"
						onClick={() => {
							stateSetter(false);
							action();
						}}
					>
						{confirmText ? confirmText : "Continue"}
					</Button>
				</Button.Group>
			}
		/>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
