/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */
import { Modal, Button } from "react-bulma-components";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * Renders a popup modal component with the given state, title, body, and footer.
 * @param {Object} props - The props object containing the state, stateSetter, title, body, and footer.
 * @returns The rendered popup modal component.
 */
export default function PopupModal({
	state,
	stateSetter,
	title,
	body,
	footer,
}) {
	/* State, Context and Hooks
  -------------------------------------------------- */

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<>
			<Modal
				show={state}
				onClose={() => {
					stateSetter(false);
				}}
			>
				<Modal.Card>
					<Modal.Card.Header showClose>
						<Modal.Card.Title>{title}</Modal.Card.Title>
					</Modal.Card.Header>
					<Modal.Card.Body>{body}</Modal.Card.Body>
					<Modal.Card.Footer
						align="right"
						renderAs={Button.Group}
						className="dialogue-buttons"
					>
						{footer}
					</Modal.Card.Footer>
				</Modal.Card>
			</Modal>
		</>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
