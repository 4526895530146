/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import ContentLoader from "react-content-loader";
import { Columns, Section, Card } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * Renders a skeleton loading animation for website cards.
 * @returns The JSX code for the skeleton loading animation.
 */
export default function WebsiteCardsSkeleton() {
	/* State, Context and Hooks
  -------------------------------------------------- */

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Columns>
			<Columns.Column size="half">
				<Section>
					<Card className="selectable">
						<Card.Content>
							<ContentLoader
								speed={2}
								width="100%"
								height="195px"
								// viewBox="0 0 100% 100%"
								backgroundColor="#f3f3f3"
								foregroundColor="#fff"
							>
								<rect
									width="100%"
									height="100%"
								/>
							</ContentLoader>
						</Card.Content>
					</Card>
				</Section>
			</Columns.Column>
			<Columns.Column size="half">
				<Section>
					<Card className="selectable">
						<Card.Content>
							<ContentLoader
								speed={2}
								width="100%"
								height="195px"
								// viewBox="0 0 100% 100%"
								backgroundColor="#f3f3f3"
								foregroundColor="#fff"
							>
								<rect
									width="100%"
									height="100%"
								/>
							</ContentLoader>
						</Card.Content>
					</Card>
				</Section>
			</Columns.Column>
		</Columns>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
