/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";
import { WebsitesContext } from "../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import {
	Columns,
	Section,
	Menu,
	Icon,
	Level,
	Navbar,
	Block,
	Button,
	Notification,
} from "react-bulma-components";
import YourWebsitesSkeleton from "./Skeletons/YourWebsites_Skeleton";
import UserActions from "./UserActions";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSquareCaretUp,
	faHome,
	faChartSimple,
	faCircleQuestion,
	faGear,
	faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * Renders the sidebar component.
 * @param {boolean} loading - Indicates whether the component is in a loading state.
 * @param {boolean} admin - Indicates whether the user is an admin.
 * @returns The rendered sidebar component.
 */
export default function Sidebar(loading, admin) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const notification = useNotification();
	const websites = useContext(WebsitesContext);
	const navigate = useNavigate();

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Handles the click event for signing out.
	 * @param {Event} event - The click event object.
	 * @returns None
	 * @throws {Error} - If an error occurs during the sign out process.
	 */
	function handleSignOutClick(event) {
		event.preventDefault();

		try {
			const promise = firebase.auth
				.signOut()
				.then(function () {
					firebase.setLoading(true);
				})
				.catch(function (error) {
					console.error(error);
				})
				.finally(() => {
					window.location.reload();
				});

			notification.withPromise(promise, { pending: `Signing out` });
		} catch (error) {
			throw error;
		}
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	function Desktop() {
		/* Desktop Sidebar - Hidden on Touch Devices */
		return (
			<Columns.Column
				size="one-fifth"
				backgroundColor="white"
				style={{
					minHeight: "100vh",
					overflow: "scroll",
					minWidth: "350px",
				}}
				touch={{ display: "hidden", size: "full" }}
			>
				<Section size="small">
					<Block>
						<Level>
							<Level.Item>
								<a href={`${window.location.origin}/dashboard`}>
									<img
										alt="Web Design for Actors Logo"
										src={process.env.PUBLIC_URL + "/WDFA_Logo.png"}
										width="200px"
										align="center"
									/>
								</a>
							</Level.Item>
						</Level>
					</Block>
					<Menu>
						<Menu.List>
							<Menu.List.Item
								renderAs={NavLink}
								to="/dashboard"
								end
							>
								<Icon mr={2}>
									<FontAwesomeIcon icon={faHome} />
								</Icon>
								Dashboard
							</Menu.List.Item>
							<Menu.List.Item
								renderAs={NavLink}
								to="/dashboard/status"
							>
								<Icon mr={2}>
									<FontAwesomeIcon icon={faChartSimple} />
								</Icon>
								Service Status
							</Menu.List.Item>
							<Menu.List.Item
								renderAs="a"
								href="https://webdesignforactors.com"
								target="_blank"
								rel="noreferrer"
							>
								<Icon mr={2}>
									<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
								</Icon>
								Web Design for Actors
							</Menu.List.Item>
						</Menu.List>
						{/* Display Admin Menu if the user has admin privelages */}
						{admin && (
							<Menu.List title="Administration">
								<Menu.List.Item
									renderAs={NavLink}
									to={`/dashboard/manageusers`}
								>
									Manage Users
								</Menu.List.Item>
							</Menu.List>
						)}
						<Menu.List title="Your Websites">
							{websites && websites.websites ? (
								websites.websites.map((website) => {
									return (
										<Menu.List.Item
											renderAs={NavLink}
											to={`/dashboard/edit/${website.id}`}
											key={website.id}
										>
											www.
											{website.data.domain
												? website.data.domain
												: "New Website"}
										</Menu.List.Item>
									);
								})
							) : (
								<YourWebsitesSkeleton />
							)}
						</Menu.List>
					</Menu>
				</Section>
				<Block style={{ top: "100%", position: "sticky" }}>
					<Section>
						<Block>
							{/* Display a message to admin if they are masquerading as a user */}
							{localStorage.admin_id_token && (
								<Notification color="warning">
									You currently masquerading as a customer.
									<Button
										text
										className="buttonAsLink"
										textWeight="bold"
										onClick={() => {
											const promise = firebase
												.signInWithCustomToken(
													firebase.auth,
													localStorage.admin_id_token
												)
												.then(() => {
													localStorage.removeItem("admin_id_token"); // Remove the used token to hide the sidebar warning
													navigate("/dashboard/manageusers"); // Redirect admin to users page
												});

											notification.withPromise(promise, {
												pending: "Logging in as admin",
												success: "Successfully returned you to admin",
												error:
													"Please log out and back in. Your Admin Verification Token has likely expired.",
											});
										}}
									>
										Return to Admin
									</Button>
								</Notification>
							)}
							<Menu>
								<Menu.List>
									<Menu.List.Item
										renderAs="a"
										href="https://webdesignforactors.com"
										target="_blank"
										rel="noreferrer"
									>
										<Icon mr={2}>
											<FontAwesomeIcon icon={faCircleQuestion} />
										</Icon>
										Help
									</Menu.List.Item>
									<Menu.List.Item
										renderAs={NavLink}
										to="/dashboard/settings"
									>
										<Icon mr={2}>
											<FontAwesomeIcon icon={faGear} />
										</Icon>
										Settings
									</Menu.List.Item>
								</Menu.List>
							</Menu>
						</Block>
						<UserActions
							onSignOutClick={handleSignOutClick}
							firebase={firebase}
							loading={loading}
						/>
						<Button
							onClick={(e) => handleSignOutClick(e)}
							mt={5}
							fullwidth
						>
							Sign Out
						</Button>
					</Section>
				</Block>
			</Columns.Column>
		);
	}

	function Touch() {
		let debounceTimeout;
		const dropdownButtonRef = useRef(null);

		return (
			/* Header - Touch Devices Only */
			<Columns.Column
				size="11"
				desktop={{ display: "hidden" }}
				backgroundColor="dark"
				style={{
					position: "sticky",
					bottom: 20,
					zIndex: 1,
					flexGrow: "unset",
					borderRadius: "10px",
					boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.3)" /* Box shadow */,
				}}
				domRef={(ref) => ref?.style?.setProperty("margin", "auto", "important")} // Hacky way of setting margin:auto!important
			>
				<Navbar className="floating-mobile-nav">
					<Navbar.Container>
						<Navbar.Item
							domRef={dropdownButtonRef}
							onMouseEnter={() => {
								if (debounceTimeout) {
									clearTimeout(debounceTimeout);
								}

								dropdownButtonRef.current.classList.add("is-active");
							}}
							onMouseLeave={() => {
								debounceTimeout = setTimeout(() => {
									if (dropdownButtonRef.current.classList) {
										dropdownButtonRef.current?.classList?.remove("is-active");
									}
								}, 300); // 300ms debounce time
							}}
						>
							<Navbar.Link arrowless>
								<Icon mr={2}>
									<FontAwesomeIcon icon={faSquareCaretUp} />
								</Icon>
								More
							</Navbar.Link>
							<Navbar.Dropdown up>
								<Navbar.Item
									renderAs={NavLink}
									to="/dashboard/manageusers"
								>
									Manage Users
								</Navbar.Item>
								<Navbar.Item
									renderAs={Button}
									style={{ border: "0px" }}
									onClick={(e) => handleSignOutClick(e)}
								>
									Sign Out
								</Navbar.Item>
							</Navbar.Dropdown>
						</Navbar.Item>
						<Navbar.Item
							renderAs={NavLink}
							to="/dashboard/settings"
						>
							<Icon mr={2}>
								<FontAwesomeIcon icon={faGear} />
							</Icon>
							Settings
						</Navbar.Item>
						<Navbar.Item
							renderAs={NavLink}
							to="/dashboard"
							end
						>
							<Icon mr={2}>
								<FontAwesomeIcon icon={faHome} />
							</Icon>
							Dashboard
						</Navbar.Item>
					</Navbar.Container>
				</Navbar>
			</Columns.Column>
		);
	}

	return { Desktop, Touch };
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
