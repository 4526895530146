/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import handleUserUpdate from "../../helpers/handleUserUpdate";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Block, Table, Icon, Button } from "react-bulma-components";
import GenericConfirmActionPopup from "../PopupModals/GenericConfirmAction_Popup";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faApple } from "@fortawesome/free-brands-svg-icons";
import {
	faQuestion,
	faChalkboardUser,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * Renders a table displaying authentication methods.
 * @param {Object} props - The props object containing authMethods and setAuthMethods.
 * @returns {JSX.Element} - The rendered table component.
 */
export default function AuthMethodTable(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const { authMethods, setAuthMethods } = props;
	const [confirmPopup, setConfirmPopup] = useState(false);

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Block className="accordian is-table">
			<Table>
				<thead>
					<tr>
						<th>Provider</th>
						<th>Name</th>
						<th>Email</th>
						<th align="right">Actions</th>
					</tr>
				</thead>
				<tbody>
					{authMethods.length > 0 &&
						authMethods.map((provider) => {
							return (
								<>
									<GenericConfirmActionPopup
										key={"ConfirmPopup"}
										state={confirmPopup}
										stateSetter={setConfirmPopup}
										action={() => {
											handleUserUpdate(
												"providerData",
												provider.providerId,
												setAuthMethods,
												null,
												props.firebase
											);
										}}
									/>
									<tr key={provider.providerId}>
										<td>
											{provider.providerId === "google.com"
												? "via Google"
												: provider.providerId === "password"
												? "via Email Address and Password"
												: `via: "${provider.providerId}"`}
											<Icon pull="right">
												<FontAwesomeIcon
													icon={
														provider.providerId === "google.com"
															? faGoogle
															: provider.providerId === "password"
															? faChalkboardUser
															: provider.providerId === "apple.com"
															? faApple
															: faQuestion
													}
												/>
											</Icon>
										</td>
										<td>{provider.displayName}</td>
										<td>{provider.email}</td>
										<td align="right">
											<Button
												disabled={authMethods.length <= 1}
												color="warning"
												onClick={(e) => {
													setConfirmPopup(true);
												}}
											>
												<FontAwesomeIcon icon={faTrash} />
											</Button>
										</td>
									</tr>
								</>
							);
						})}
				</tbody>
			</Table>
		</Block>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
