/*=============================================
=                   IMPORTS                   =
=============================================*/

/* Reach Imports
-------------------------------------------------- */
import { useState, useContext } from "react";
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { requiresUpgrade } from "../FormComponents/utilities";

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */
import { WebsitesContext } from "../../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import {
	Button,
	Heading,
	Card,
	Tag,
	Block,
	Columns,
} from "react-bulma-components";
import GenericFormPopup from "../PopupModals/GenericForm_Popup";
import GenericConfirmActionPopup from "../PopupModals/GenericConfirmAction_Popup";

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPencil,
	faTrash,
	faPlus,
	faLink,
	faLinkSlash,
} from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * Renders a boxed list component with the provided form fields, group filters, and form.
 * @param {Object[]} formFields - The form fields to display in the boxed list.
 * @param {Object[]} groupFilters - The group filters to apply to the boxed list.
 * @param {Object} form - The form object.
 * @returns The rendered boxed list component.
 */

export default function BoxedList({ formFields, groupFilters, form }) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const {
		//Context from EditWebsite.js (the parent of this component)
		firebase,
		websiteData,
	} = useOutletContext();

	const formValues = form.getState().values;
	const [index, setIndex] = useState(undefined);
	const [selectedGroup, setSelectedGroup] = useState(undefined);
	const [editPopup, setEditPopup] = useState(false);
	const [removePopup, setRemovePopup] = useState(false);
	const websites = useContext(WebsitesContext);
	const navigate = useNavigate();
	const queryParams = useParams();

	/* End of State, Context & Hooks
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Handles the submission of form values.
	 * If the website has the "gallery" addon enabled, it updates the website data
	 * and patches the changes to the Firebase database.
	 * If the "gallery" addon is not active, it displays a warning notification.
	 * @param {Object} values - The form values to submit.
	 * @returns None
	 */
	async function handleSubmit(values) {
		await websites.updateWebsiteData(websiteData.id, values);
		await firebase.db.patch(
			`websites/${websiteData.id}`,
			{ ...values },
			firebase.accessToken
		);
	}

	/**
	 * Handles the click event for removing an item from a group in a form.
	 * @param {number} itemIndex - The index of the item to remove.
	 * @param {string} group - The group name of the items.
	 * @returns None
	 */
	async function handleRemoveClick(itemIndex, group) {
		const key = () => {
			const objectKeys = Object.keys(formValues[group] ?? {});
			return objectKeys[0];
		};

		form.mutators.remove(`${group}.${key()}.${itemIndex}`);
	}

	/**
	 * Handles the click event when the edit button is clicked for a specific group.
	 * @param {group} group - The group object that was clicked.
	 * @param {index} index - The index of the group in the list.
	 * @returns None
	 */
	function handleEditClick(group, index) {
		setIndex(index);
		setSelectedGroup(group);
		setEditPopup(true);
	}

	/**
	 * Adds an empty object to the specified group and key in the form's mutators array.
	 * @param {string} group - The group to add the empty object to.
	 * @param {string} key - The key within the group to add the empty object to.
	 * @returns None
	 */
	function addEmptyObject(group, key) {
		form.mutators.push(`${group}.${key}]`, {
			name: "",
			category: "",
			upload: "",
			date: new Date(),
		});
	}

	/**
	 * Handles the click event when adding an item to a group.
	 * @param {string} group - The group to add the item to.
	 * @returns None
	 */
	async function handleAddItemClick(group) {
		const key = () => {
			const objectKeys = Object.keys(formValues[group] ?? {});
			return objectKeys[0];
		};

		const currentIndex = formValues[group][key()].length;

		// Check if the last entry is empty before adding another
		const lastSubmission = formValues[group][key()][currentIndex - 1];

		const isAnyValueEmpty = (obj) => {
			return Object.values(obj).some(
				(value) => value === "" || value === null || value === undefined
			);
		};

		if (isAnyValueEmpty(lastSubmission) === true) {
			//Display the last unfinished item to be edited
			setIndex(currentIndex - 1);
		} else {
			//Add a new empty object and display it to be edited
			addEmptyObject(group, key());
			setIndex(currentIndex);
		}

		setSelectedGroup(group);
		setEditPopup(true);
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/**
	 * Renders a gallery item component.
	 * @param {Object} item - The item object to display in the gallery.
	 * @param {number} itemIndex - The index of the item in the gallery.
	 * @param {string} group - The group that the item belongs to.
	 * @returns The rendered gallery item component.
	 */
	function GalleryItem({ item, itemIndex, group }) {
		if (!item || (!item.name && !item.category)) {
			return; // Do not display empty files. Duplicate empties are prevented in addGalleryItem().
		}

		return (
			<Columns.Column
				mobile={{ size: "full" }}
				tablet={{ size: "half" }}
				desktop={{ size: "one-quarter" }}
			>
				<Card
					style={{
						paddingTop: "30px",
						paddingBottom: "30px",
						margin: "10px",
					}}
				>
					<Card.Image
						size="4by3"
						src={item.upload.downloadURL}
						alt={item.upload.name}
					/>
					<Card.Content>
						<Tag.Group ml={2}>
							<Tag color="primary">{item.name}</Tag>
							<Tag color="dark">{item.category}</Tag>
							<Tag
								onClick={() => {
									handleEditClick(group, itemIndex);
								}}
							>
								<FontAwesomeIcon icon={faPencil} />
							</Tag>
							<Tag
								onClick={() => {
									handleRemoveClick(itemIndex, group, item);
								}}
							>
								<FontAwesomeIcon icon={faTrash} />
							</Tag>
						</Tag.Group>
					</Card.Content>
				</Card>
			</Columns.Column>
		);
	}

	/**
	 * Renders a blog item component with the given item, itemIndex, and group.
	 * @param {Object} item - The blog item object.
	 * @param {number} itemIndex - The index of the blog item.
	 * @param {string} group - The group that the blog item belongs to.
	 * @returns The rendered blog item component.
	 */
	function BlogItem({ item, itemIndex, group }) {
		if (!item || (!item.name && !item.category)) {
			return; // Do not display empty files. Duplicate empties are prevented in addGalleryItem().
		}

		return (
			<Columns.Column
				mobile={{ size: "full" }}
				tablet={{ size: "half" }}
				desktop={{ size: "one-quarter" }}
			>
				<Card
					style={{
						paddingTop: "30px",
						paddingBottom: "30px",
						margin: "10px",
					}}
				>
					<Card.Image
						size="4by3"
						src={item.cover.downloadURL}
						alt={item.cover.name}
					/>
					<Card.Content>
						<Tag.Group ml={2}>
							<Tag color="primary">{item.name}</Tag>
							<Tag color="dark">{new Date(item.date).toLocaleDateString()}</Tag>
							<Tag onClick={() => handleEditClick(group, itemIndex)}>
								<FontAwesomeIcon icon={faPencil} />
							</Tag>
							<Tag
								onClick={() => {
									handleRemoveClick(itemIndex, group, item);
								}}
							>
								<FontAwesomeIcon icon={faTrash} />
							</Tag>
						</Tag.Group>
					</Card.Content>
				</Card>
			</Columns.Column>
		);
	}

	/**
	 * Renders a card component with an "Add Item" call-to-action button.
	 * The content of the card and the text of the button are determined by the provided group.
	 * @param {Object} props - The component props.
	 * @param {string} props.group - The group to determine the content and text values.
	 * @returns {JSX.Element} - The rendered card component with the "Add Item" call-to-action button.
	 */
	function AddItemCTA({ group }) {
		const textValues = {
			gallery: {
				headingText: "Look more professional...",
				actionText: "Add Image, Audio or Video",
			},
			blog: {
				headingText: "Tell them what you're up to...",
				actionText: "Add News/Blog Item",
			},
		};

		return (
			<Columns.Column
				mobile={{ size: "full" }}
				tablet={{ size: "half" }}
				desktop={{ size: "one-quarter" }}
			>
				<Card
					style={{
						paddingTop: "30px",
						paddingBottom: "30px",
						margin: "10px",
					}}
				>
					<Card.Content alignContent="center">
						<Heading>{textValues[group].headingText}</Heading>
						<Button.Group>
							<Button
								color="dark"
								fullwidth
							>
								Learn More &nbsp;
								<FontAwesomeIcon icon={faLink} />
							</Button>
							<Button
								color="primary"
								fullwidth
								onClick={() => handleAddItemClick(group)}
								type="button"
							>
								{textValues[group].actionText}&nbsp;
								<FontAwesomeIcon icon={faPlus} />
							</Button>
						</Button.Group>
					</Card.Content>
				</Card>
			</Columns.Column>
		);
	}

	/**
	 * A functional component that renders a card with a message and a button to return to a form.
	 * @param {Object} props - The component props.
	 * @param {string} props.group - The group name.
	 * @returns {JSX.Element | undefined} - The rendered component or undefined if group is falsy.
	 */
	function ReturnToForm({ group }) {
		if (!group) {
			return undefined;
		}
		function uppercaseName() {
			const firstLetter = group[0];
			const rest = group.substr(1, group.length);
			return firstLetter.toUpperCase() + rest;
		}
		return (
			<Card style={{ width: 300, paddingTop: "30px", paddingBottom: "30px" }}>
				<Card.Content
					alignContent="center"
					alignItems="center"
					textAlign={"center"}
				>
					<FontAwesomeIcon
						size="6x"
						icon={faLinkSlash}
					/>
					<Heading>{uppercaseName()} can't be edited here</Heading>
					<Button.Group>
						<Button
							color="dark"
							fullwidth
							onClick={() => navigate(`../${group}`, { relative: "path" })}
						>
							Edit {group}
						</Button>
					</Button.Group>
				</Card.Content>
			</Card>
		);
	}

	/**
	 * Displays a list of items based on the provided group.
	 * @param {Object} props - The component props.
	 * @param {string} props.group - The group to display items for.
	 * @returns The rendered list of items.
	 */
	function DisplayItems({ group }) {
		const values = formValues?.[group];
		const objectKeys = Object.keys(formValues[group] ?? {});
		let data = [];

		objectKeys.forEach((key) => {
			values[key].forEach((value) => {
				data.push(value);
			});
		});

		if (!data || !data.length) {
			return;
		}
		const componentMap = {
			gallery: GalleryItem,
			blog: BlogItem,
		};

		const ItemComponent = componentMap[group];

		if (!componentMap[group]) {
			return <ReturnToForm group={group} />;
		}

		return data.map((item, index) => {
			form.registerField(`${group}.${objectKeys[0]}.${index}`, () => {}, []); // A field MUST be registered in order to track the state such as isDirty. This registers a completely empty field.

			return (
				<ItemComponent
					item={item}
					setEditPopup={setEditPopup}
					editPopup={editPopup}
					itemIndex={index}
					group={group}
					key={`item.${index}`}
				/>
			);
		});
	}

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (requiresUpgrade(queryParams, websiteData.data.addons)) {
		return "You must upgrade to access this addon.";
	}

	return (
		<Block>
			{groupFilters.map((group, index) => {
				return (
					<Columns
						display="flex"
						key={`${group}.${index}`}
					>
						<DisplayItems group={group} />
						<AddItemCTA group={group} />
					</Columns>
				);
			})}
			{/* Disable Add Item CTA as there is no way of passing the correct data to the action */}
			<GenericFormPopup
				state={editPopup}
				stateSetter={setEditPopup}
				firebase={firebase}
				websites={null}
				notification={null}
				handleFormSubmit={null}
				formConfig={{
					formFields,
					websiteData: formValues,
					formFilters: selectedGroup,
					onlyShowIndex: index,
				}}
				action={handleSubmit}
				confirmText="Publish Changes"
			/>
			<GenericConfirmActionPopup
				state={removePopup}
				stateSetter={setRemovePopup}
				title={"Delete this gallery item?"}
				body={"This action is irreversable. Are you sure?"}
				action={() => handleRemoveClick(index)}
			/>
		</Block>
	);
}

/*============  End of Main Return  =============*/
