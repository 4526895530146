/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import ContentLoader from "react-content-loader";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * Renders a skeleton loading animation for a website's content.
 * @returns The JSX code for the skeleton loading animation.
 */
export default function YourWebsitesSkeleton() {
	/* State, Context and Hooks
  -------------------------------------------------- */

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<ContentLoader
			speed={2}
			width="100%"
			height="100%"
			viewBox="0 0 400 250"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
		>
			<rect
				x="0"
				y="0"
				// rx="5"
				// ry="5"
				width="100%"
				height="54"
			/>
			<rect
				x="0"
				y="65"
				// rx="5"
				// ry="5"
				width="100%"
				height="54"
			/>
		</ContentLoader>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
