/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useEffect, useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import PopupModal from "../PopupModal";
import { Button, Form } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * A functional component that renders a popup for updating user profile information.
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.notification - The notification object used for displaying messages.
 * @param {Object} props.state - The state object containing the current user profile information.
 * @param {Function} props.stateSetter - The function used to update the state object.
 * @param {Object} props.firebase - The Firebase object used for interacting with the database.
 * @param {Object} props.user - The user object containing the current user information.
 * @returns The rendered UpdateUserProfilePopup component.
 */
export default function UpdateUserProfilePopup(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const notification = useNotification();
	const { state, stateSetter, firebase, user } = props;
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */
	useEffect(() => {
		setName(state?.user?.displayName);
		setEmail(state?.user?.email);
	}, [state.user]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Handles the form submission event.
	 * @param {Event} e - The form submission event.
	 * @returns None
	 */
	function handleSubmit(e) {
		e.preventDefault();
		const promise = firebase.db
			.patch(
				`userauth/users?userId=${user.uid}`,
				{
					email,
					name,
				},
				firebase.accessToken
			)
			.then(() => {
				stateSetter({ state: false, user: null });
			});

		notification.withPromise(promise, {
			pending: "Updating User Profile",
			success: "Successfully updated profile",
		});
	}

	/**
	 * Renders a form component with input fields for display name and email address.
	 * @returns The rendered form component.
	 */
	function renderForm() {
		return (
			<form onSubmit={handleSubmit}>
				<Form.Field>
					<Form.Field.Label>
						<Form.Label>Display Name</Form.Label>
					</Form.Field.Label>
					<Form.Field.Body>
						<Form.Field
							kind="group"
							multiline={false}
						>
							<Form.Control
								fullwidth
								style={{ minWidth: "50%" }}
							>
								<Form.Input
									type="text"
									placeholder="Joseph Berry"
									value={name}
									onChange={(e) => {
										setName(e.currentTarget.value);
									}}
								/>
							</Form.Control>
						</Form.Field>
					</Form.Field.Body>
				</Form.Field>
				<Form.Field>
					<Form.Field.Label>
						<Form.Label>Email Address</Form.Label>
					</Form.Field.Label>
					<Form.Field.Body>
						<Form.Field
							kind="group"
							multiline={false}
						>
							<Form.Control
								fullwidth
								style={{ minWidth: "50%" }}
							>
								<Form.Input
									type="email"
									placeholder="your@email.com"
									value={email}
									onChange={(e) => {
										setEmail(e.currentTarget.value);
									}}
								/>
							</Form.Control>
						</Form.Field>
					</Form.Field.Body>
				</Form.Field>
			</form>
		);
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<PopupModal
			state={state.state}
			stateSetter={stateSetter}
			title={`Updating ${user?.displayName ?? "User"}'s Profile`}
			body={renderForm()}
			footer={
				<Button.Group>
					<Button
						color="dark"
						type="reset"
						onClick={() => stateSetter({ state: false, user: null })}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						submit
						onClick={(e) => handleSubmit(e)}
					>
						Save Changes
					</Button>
				</Button.Group>
			}
		/>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
