/* React Imports */

/* Hooks & Helpers Imports */
import { Crisp } from "crisp-sdk-web";

/* Context Imports */

/* Components Imports */

/* Icons Imports */

/* Components Styling */

export default async function useCrisp() {
	/* Context and Hook Values */

	/* States */

	/* Functions */

	/* Return */
	Crisp.configure("129e35fc-ae03-49ce-b1e2-405e99b46896", {
		autoload: false,
	});

	return Crisp;
}
