/*=============================================
=                   IMPORTS                   =
=============================================*/
import useNotification from "../hooks/useNotification";

/*============  End of IMPORTS  =============*/

/*=============================================
=                   Functions                   =
=============================================*/

/* handleUserUpdate()
-------------------------------------------------- */
/**
 * Handles the update of a user's data and displays a notification during the update process.
 * @param {string} key - The key of the data to be updated.
 * @param {any} value - The new value to be set for the specified key.
 * @param {Function} setter - The setter function to update the state with the new value.
 * @param {Function} setter2 - Another setter function to update the state with the new value.
 * @param {Firebase} firebase - The Firebase instance used for data updates.
 * @returns {null} - Always returns null.
 */
export default function HandleUserUpdate(
	key,
	value,
	setter,
	setter2,
	firebase
) {
	/* Context and Hook Values */
	const notification = useNotification();

	/* States */

	/* Functions */

	if (key === "email") {
		const promise = firebase
			.updateEmail(firebase.auth.currentUser, value)
			.then(() => {
				window.location.reload();
				return `Your profile ${[key]} has been updated succesfully.`;
			})
			.catch((error) => {
				setter(firebase.user.email);
				throw error;
			});

		notification.withPromise(promise, {
			pending: `Updating your email address}`,
		});
	} else if (key === "providerData") {
		const promise = firebase
			.unlink(firebase.auth.currentUser, value) // Expected value for this is providerId
			.then(() => {
				setter(firebase.auth.currentUser.providerData);
				return `Your profile has been updated succesfully. ${value} is no longer linked to you account. Relink by signing into Web Design for Actors using ${value} with ${firebase.user.email}.`;
			})
			.catch((error) => {
				setter(firebase.user.providerData);
				throw error;
			});

		notification.withPromise(promise, {
			pending: `Updating your authentication provider links`,
		});
	} else if (key === "password") {
		const promise = firebase
			.updatePassword(firebase.auth.currentUser, setter2)
			.then(() => {
				return "Password updated successfully.";
			})
			.catch((error) => {
				setter("");
				setter2("");
				throw error;
			});

		notification.withPromise(promise, { pending: `Updating your password` });
	} else {
		const promise = firebase
			.updateProfile(firebase.auth.currentUser, {
				[key]: value,
			})
			.then(() => {
				return `Your profile ${[key]} has been updated succesfully.`;
			})
			.catch((error) => {
				throw error;
			});

		notification.withPromise(promise, { pending: `Updating ${key}` });
	}

	/* Return */

	return null;
}

/*============  End of Functions  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
