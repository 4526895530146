/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import arrayMutators from "final-form-arrays";
import generateInitialValues from "../../../helpers/generateFormValues";
import { getFields } from "../../../helpers/formFunctions";
import { DynamicFormFields } from "../DynamicFormFields";
import Wizard from "./Wizard";
import useNotification from "../../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../../context/firebase_contextProvider";
import { WebsitesContext } from "../../../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Columns, Card, Heading, Section } from "react-bulma-components";
import CTA from "./CTA";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * Renders the BuilderSteps component.
 * @returns The JSX code for the BuilderSteps component.
 */
export default function BuilderSteps() {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const navigate = useNavigate();
	const firebase = useContext(FirebaseContext);
	const websites = useContext(WebsitesContext);
	const notification = useNotification();
	const [formFields, setFormFields] = useState([]);
	let isFirstRender = useRef(true);

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (isFirstRender.current) {
			getFields(firebase, setFormFields);
			isFirstRender.current = false;
		}
	}, [firebase]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Handles the creation of a new website by making API calls to Firebase and Plesk.
	 * @param {Object} values - The values needed to create the website.
	 * @returns {Promise} - A promise that resolves with a success message when the website is successfully created.
	 * @throws {Error} - If there is an error during the creation process.
	 */
	function handleWebsiteCreation(values) {
		const promise = firebase.db
			.post(
				`websites/new`,
				{
					...values,
					ownerId: firebase.user.uid,
					data_version: 2,
					user: {
						emailAddress: [
							{
								value: firebase.user.email,
							},
						],
					},
					addons: {
						contacts: true,
						experience: true,
						intro: true,
						skills: true,
						socials: true,
						template: true,
						training: true,
						testimonials: true,
						gallery: true,
						blog: true,
					},
				},
				firebase?.accessToken
			)
			.then((res) => {
				websites.setWebsites([
					...websites.websites,
					{
						id: res.id,
						data: {
							...values,
							domain: `${res.id}.wdfa.site`,
							data_version: 2,
							user: {
								emailAddress: [
									{
										value: firebase.user.email,
									},
								],
							},
							addons: {
								contacts: true,
								experience: true,
								intro: true,
								skills: true,
								socials: true,
								template: true,
								training: true,
								testimonials: true,
								gallery: true,
								blog: true,
							},
						},
					},
				]);
				return res;
			})
			.then((res) => {
				firebase.db.post(
					`plesk/alias/?websiteId=${res.id}`,
					null, // Body
					firebase?.accessToken
				);
				return res;
			})
			.then((res) => {
				return new Promise((resolve, reject) => {
					// Use setTimeout to delay the resolve by 5 seconds to give Plesk time to create the domain and prevent local DNS issues from not enough propagation time
					setTimeout(() => {
						navigate(`/dashboard/edit/${res.id}?new=true`);
						resolve("Successfully created your new website.");
					}, 5000); // 5 seconds delay
				});
			})
			.catch((e) => {
				throw e;
			});

		notification.withPromise(promise, { pending: "Creating website..." });
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (!formFields.length) {
		return <p>Loading</p>;
	}

	return (
		<Columns.Column textAlign="center">
			<Card className="selectable">
				<Wizard
					initialValues={generateInitialValues({
						containerConfigs: formFields,
					})}
					mutators={{
						...arrayMutators,
					}}
					subscription={{ submitting: true, values: true }}
					onSubmit={(values) => {
						handleWebsiteCreation(values);
					}}
					// debug={(formState) => console.log(formState)}
					formFields={formFields}
					firebase={firebase}
					notification={notification}
				>
					<Wizard.Page>
						<CTA />
					</Wizard.Page>
					<Wizard.Page>
						<Heading>First things first</Heading>
						<Heading subtitle>Tell the world who you are</Heading>
						<DynamicFormFields
							formFields={formFields}
							fieldFilters={{
								intro: ["stageName", "file"],
							}}
							containerSettings={{ displayLabel: true, displayHelp: true }}
							firebase={firebase}
							notification={notification}
						/>
					</Wizard.Page>
					<Wizard.Page>
						<Heading>Show off your personality</Heading>
						<Heading subtitle>
							Pick a colour scheme to match your professionalism.
						</Heading>
						<DynamicFormFields
							formFields={formFields}
							fieldFilters={{
								template: ["colorScheme"],
							}}
							containerSettings={{ displayLabel: true, displayHelp: true }}
							firebase={firebase}
							notification={notification}
						/>
					</Wizard.Page>
					<Wizard.Page>
						<Section
							size="medium"
							pb={4}
						>
							<Heading>3 more steps to go!</Heading>
							<Heading subtitle>
								All of the following goes on your website
							</Heading>
							<DynamicFormFields
								formFields={formFields}
								fieldFilters={{
									intro: [
										"biog",
										"location",
										"role",
										"playingAge",
										"availability",
									],
								}}
								containerSettings={{ displayLabel: true, displayHelp: true }}
								firebase={firebase}
								// notification={notification}
							/>
						</Section>
					</Wizard.Page>
					<Wizard.Page>
						<Section
							size="medium"
							pb={4}
						>
							<Heading>Sooo close! Let's hook up</Heading>
							<Heading subtitle>(...your social media)</Heading>
							<DynamicFormFields
								formFields={formFields}
								fieldFilters={{
									socials: ["facebook", "instagram", "x-twitter"],
									testimonials: ["testimonials"],
								}}
								containerSettings={{ displayLabel: true, displayHelp: true }}
								firebase={firebase}
								// notification={notification}
							/>
						</Section>
					</Wizard.Page>
					<Wizard.Page>
						<Section
							size="medium"
							pb={4}
						>
							<Heading>Tell the world what you're good at</Heading>
							<Heading subtitle>Add up to 10 skills &amp; experience</Heading>
							<DynamicFormFields
								formFields={formFields}
								fieldFilters={{
									skills: ["skills"],
									training: ["training"],
								}}
								containerSettings={{ displayLabel: true, displayHelp: true }}
								firebase={firebase}
								// notification={notification}
							/>
						</Section>
					</Wizard.Page>
					<Wizard.Page>
						<Heading>One last question</Heading>
						<Heading subtitle>Lets get you some work...</Heading>
						<DynamicFormFields
							formFields={formFields}
							fieldFilters={{
								contacts: ["hasAgent", "agentDetails"],
							}}
							containerSettings={{ displayLabel: true, displayHelp: true }}
							firebase={firebase}
							// notification={notification}
						/>
					</Wizard.Page>
					<Wizard.Page>
						<Heading>If you're happy and you know it, press submit!</Heading>
						<Heading subtitle>
							Your website will be created instantly, so check you're happy with
							everything.
						</Heading>
					</Wizard.Page>
				</Wizard>
				<Heading
					size={7}
					subtitle
					p={6}
				>
					You can preview your website online before making it public. Our
					yearly subscription starts from £23.99 per year, which includes a free
					domain name and email address. <br />
					Subject to availability & terms and conditions. To find out more,{" "}
					<a
						href="http://google.com"
						target="_blank"
						rel="noreferrer"
					>
						click here
					</a>
					.
				</Heading>
			</Card>
		</Columns.Column>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
