/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useNotification from "../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../context/firebase_contextProvider";
import { WebsitesContext } from "../../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Form, Button, Icon, Tag, Heading } from "react-bulma-components";
import PopupModal from "../PopupModal";
import StripeForm from "../StripeForm";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheck,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * A form field component for searching domains.
 * @param {Object} props - The component props.
 * @param {FirebaseContext} props.firebase - The Firebase context.
 * @param {WebsitesContext} props.websites - The Websites context.
 * @param {useNotification} props.notification - The notification hook.
 * @param {useNavigate} props.redirect - The navigation hook.
 * @param {string} props.domain - The current domain value.
 * @param {function} props.setDomain - The function to set the domain value.
 * @param {string} props.initialLabelText - The initial label text for the field.
 * @param {string} props.initialHelpText - The initial help text for the field.
 *
 */
export default function FormFieldSearchDomain(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const websites = useContext(WebsitesContext);
	const notification = useNotification();
	const redirect = useNavigate();
	const { domain, setDomain, initialLabelText, initialHelpText, websiteId } =
		props;
	// const stripePubKey = "pk_test_8whTdK2uGT2dwyYsDrHKndVf00Epcej71D"; // TEST KEY
	const stripePubKey = "pk_live_Y8Ul9BJkKl2Neruxn4Ph3Nct00X2RdcmCb"; // LIVE KEY
	const [stripePromise] = useState(() => loadStripe(stripePubKey));
	const [subscriptionData, setSubscriptionData] = useState(null);
	const [loading, setLoading] = useState(false); // Loading status, changes state of buttons etc when loading
	const [availability, setAvailability] = useState(null); // The user-queried domain availability
	const [altDomains, setAltDomains] = useState([]); // Alternate domain suggestions if user-queried domain is unavailable
	const [domainObject, setDomainObject] = useState({}); // Behind-the-scenes changes the string value of domain to an object {name, tld} which is what the rest of the logic in here uses. domain is just what the user views/types into the text box.
	const [clientSecret, setClientSecret] = useState(null);
	const [couponCode, setCouponCode] = useState({ code: "" });

	/* Dependencies
  -------------------------------------------------- */
	// Stripe Settings
	const appearance = {
		theme: "stripe",
		labels: "floating",
	};
	const options = {
		clientSecret,
		appearance,
	};

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (domainObject.full) {
			splitDomain(domainObject.full);
		}
		console.log(domainObject);
	}, [domainObject.full]); // Triggers the domainObject to be updated every time the user updates the domain

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Handles the verification of a coupon code by querying the Firebase database.
	 * @returns None
	 */
	async function handleCouponVerify() {
		firebase.db.get(`billing/coupon/${couponCode.code}`).then((res) => {
			// If no matching coupon code was returned from Stripe
			if (res.data.length < 1) {
				setCouponCode({
					...couponCode,
					valid: false, // Set validity to false, updates UI
				});
				return;
			} else {
				// If there are matching coupon codes
				setCouponCode({
					...couponCode,
					valid: true, // Set validity to true, updates UI
					data: { ...res.data }, // Save coupon data so it can be sent to update subscription
				});
			}
		});
	}

	/**
	 * Retrieves alternative available domains based on the current domain object.
	 * @returns None
	 */
	function getAltAvailableDomains() {
		// If unavailable, query the API for the availability of the following suggestions:
		const allowedDomains = [
			`${domainObject.name}.co.uk`,
			`${domainObject.name}.info`,
			`${domainObject.name}.actor`,
			`${domainObject.name}.net`,
			`${domainObject.name}.biz`,
		];
		setAvailability(false); // Set availability to false (Displays "unavailable" in the form)
		allowedDomains.forEach((allowedDomain) => {
			// For each of the above suggestions, query the API
			firebase.db
				.get(`domain/whois/?domain=${allowedDomain}`)
				.then((res) => {
					if (res.status === "available") {
						// If an alternate domain is available, add it to the altDomains state which gets mapped over in the component to display buttons
						setAltDomains((prevAltDomains) => [
							...prevAltDomains,
							allowedDomain,
						]);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		});
	}

	/**
	 * Retrieves the availability of a domain using the WHOIS lookup API.
	 * If the domain is unavailable, it retrieves alternative available domains.
	 * @returns None
	 */
	function getDomainAvailability() {
		const query = `${domainObject.name}${domainObject.tld}`; // Concatenate the domain name and tld for use in the API Query

		firebase.db
			.get(`domain/whois/?domain=${query}`, firebase?.accessToken) // Get the domain whois data from the API (expecting query="name.tld")
			.then((res) => {
				if (res.result === "error") {
					if (
						res.message === "The given TLD is not supported for WHOIS lookups"
					) {
						getAltAvailableDomains();
					} else {
						notification.add("warning", res.message);
					}
					return;
				}
				if (res.status === "unavailable") {
					getAltAvailableDomains();
				} else {
					setAvailability(true);
					setDomain(query);
					/**
					 * Handles the verification of a coupon code by querying the Firebase database.
					 * @returns None
					 */
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	/**
	 * Handles the form submission event.
	 * @param {Event} e - The form submission event.
	 * @returns None
	 */
	async function handleFormSubmit(e) {
		if (couponCode.code !== "") {
			await handleCouponVerify();
		}

		setLoading(true); // Set the loading state to true
		setAltDomains([]); // Reset alternate domain suggestions
		e.preventDefault(); // Prevent default browser actions

		if (!domainObject.tld || domainObject.tld === ".") {
			// If the domain is not valid, show valid options
			getAltAvailableDomains();
		} else {
			// If the domain is valid, check availability
			getDomainAvailability();
		}
	}

	/**
	 * Splits a domain into its constituent parts and sets the domain object.
	 * @param {string} domain - The domain to split.
	 * @returns None
	 */
	function splitDomain(domain) {
		// Splits the user-input domain into a separate domain object for use in functions immutably
		const parts = domain.split("."); // split at the first "."

		setDomainObject({
			// Set the domain object
			...domainObject,
			name: parts[0], // The part of the split before the first "."
			tld: "." + parts.slice(1).join("."), // The part of the split after the first ".". Split removes the . so add one
		});
	}

	/**
	 * Handles the click event for the order button.
	 * Sends a POST request to the Firebase database to create a new subscription for the user.
	 * If the invoice amount due is 0, redirects the user and reloads the page.
	 * Otherwise, sets the client secret, subscription data, and billing user in the state.
	 * @returns None
	 */
	function handleOrderClick() {
		setLoading(true);
		firebase.db
			.post(
				`billing/subscriptions?uid=${firebase.user.uid}&email=${firebase.user.email}&name=${firebase.user.displayName}&domain=${domain}&websiteId=${websiteId}`,
				{
					items: [
						{
							// price: "price_1McwEoBzLULujERxqNKLym78", // TEST KEY
							price: "price_1MslDIBzLULujERxDLadubhr", // LIVE KEY
						},
					],
					...(couponCode?.data &&
						couponCode?.valid &&
						couponCode?.data[0].id && {
							promotion_code: couponCode.data[0].id,
						}),
				},
				firebase?.accessToken
			)
			.then((res) => {
				if (res.invoice_status.amount_due === 0) {
					redirect(
						"?payment_intent=null&payment_intent_client_secret=null&redirect_status=completed%20and%20your%20free%20subscription%20is%20now%20active"
					); // A hacky way of showing the payment confirmation with text: "Your payment has completed and your free subscription is now active"
					window.location.reload(); // Refresh the page
				} else {
					setDomain(domainObject.full);
					setClientSecret(res.invoice_status.payment_intent.client_secret);
					setSubscriptionData(res);
					firebase.setBillingUser(res.customer);
					websites.setWebsitePayment(true);
				}
			})
			.catch((error) => {
				throw error;
			})
			.finally(() => {
				setDomain(domainObject.full);
				const newUrl = new URL(window.location.href); // Remove the new param from the url, incase this is the first time user loaded the page after they created the website
				newUrl.searchParams.delete("new");
				window.history.replaceState({}, "", newUrl.toString());
			});
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (altDomains.length === 0) {
		return (
			<>
				<form
					onSubmit={handleFormSubmit}
					style={{
						width: "75%",
						margin: "auto",
						alignItems: "center",
						textAlignLast: "center",
					}}
				>
					<Form.Field.Label>
						<Form.Label
							renderAs={Heading}
							subtitle
							size={5}
							mb={5}
						>
							{initialLabelText
								? initialLabelText
								: "Find a new name for your website"}
						</Form.Label>
						<Form.Help
							renderAs={Heading}
							size={6}
							mb={5}
							textWeight="light"
						>
							{initialHelpText
								? initialHelpText
								: "Lets start by checking it's available"}
						</Form.Help>
					</Form.Field.Label>
					<Form.Field>
						<Form.Field.Body>
							<Form.Field
								mb={3}
								kind="addons"
								flexWrap="wrap"
							>
								<Form.Control
									style={{ width: "65px" }}
									touch={{ display: "hidden" }}
								>
									<Form.Input
										type="text"
										placeholder="www."
										disabled
									/>
								</Form.Control>
								<Form.Control fullwidth>
									<Form.Input
										type="text"
										name="search_domainname"
										autoCorrect="off"
										spellCheck="false"
										placeholder="mywebsite.co.uk"
										style={{ textTransform: "lowercase" }}
										color={
											availability === null
												? null
												: availability === true
												? "success"
												: "danger"
										}
										value={domainObject.full}
										onChange={(e) => {
											setDomainObject({
												...domainObject,
												full: e.currentTarget.value,
											});
											setAvailability(null);
										}}
									/>{" "}
									{!loading && availability !== null ? (
										<Icon
											align="right"
											style={{ marginRight: "30px", opacity: "0.4" }}
											color={availability ? "success" : "danger"}
										>
											{availability ? "Available" : "Unavailable"}
										</Icon>
									) : null}
								</Form.Control>
								{(!availability || couponCode.code) && (
									<Form.Control>
										<Form.Input
											color={
												!couponCode ||
												couponCode.code === "" ||
												couponCode.valid === undefined
													? null
													: couponCode.valid === true
													? "success"
													: "warning"
											}
											disabled={couponCode.valid === true}
											placeholder="Discount Code"
											value={couponCode.code}
											name="search_coupon"
											onChange={(e) =>
												setCouponCode({
													...couponCode,
													code: e.currentTarget.value,
													valid: undefined,
												})
											}
										/>
										{!couponCode ||
										couponCode.code === "" ||
										couponCode.valid === undefined ? null : couponCode.valid ===
										  true ? (
											<Icon
												align="right"
												color="success"
											>
												<FontAwesomeIcon icon={faCheck} />
											</Icon>
										) : (
											<Icon
												align="right"
												color="warning"
											>
												<FontAwesomeIcon icon={faTriangleExclamation} />
											</Icon>
										)}
									</Form.Control>
								)}
								<Form.Control>
									{!availability || (couponCode.code && !couponCode.valid) ? (
										<Button
											submit
											loading={loading}
											disabled={loading || !domainObject.full}
										>
											Check Availability
										</Button>
									) : (
										<Button
											type="button"
											color="primary"
											onClick={() => handleOrderClick()}
											loading={loading}
											disabled={couponCode.valid === false || loading}
										>
											Subscribe Now
										</Button>
									)}
								</Form.Control>
							</Form.Field>
						</Form.Field.Body>
					</Form.Field>
				</form>

				<PopupModal
					title="Complete payment"
					body={
						clientSecret && (
							<Elements
								options={options}
								stripe={stripePromise}
								key={clientSecret}
							>
								<StripeForm
									clientSecret={clientSecret}
									domain={domainObject.full}
									subscriptionData={subscriptionData}
									setDomainSearchLoading={setLoading}
								/>
							</Elements>
						)
					}
					state={websites.websitePayment}
					stateSetter={websites.setWebsitePayment}
					handleOnClose={() =>
						notification.add(
							"warning",
							"The payment window was closed. Please go to the Settings page if you wish to complete payment"
						)
					}
				/>
			</>
		);
	} else {
		return (
			<>
				<form>
					<Heading
						subtitle
						size={6}
						mr={2}
						alignContent="center"
						justifyContent="center"
						textAlign="center"
					>
						{domainObject.name?.toLowerCase()}
						{domainObject.tld?.toLowerCase()} is not{" "}
						{domainObject.tld === "." ? "a valid domain name" : "available"},
						how about:
						<br />
					</Heading>
					{altDomains.length !== 0 && (
						<Tag.Group
							alignItems="baseline"
							justifyContent="center"
							style={{ marginTop: "0px" }}
						>
							{altDomains.map((altDomain) => (
								<Tag
									renderAs={Button}
									size="medium"
									color="primary"
									key={altDomain}
									onClick={(e) => {
										e.preventDefault(); // Prevent default browser actions
										setAltDomains([]); // Reset alternate domain suggestions
										setAvailability(true); // Reset Availability - Form State Updates based on this
										setDomainObject({
											full: altDomain,
										});
										// setLoading(false);
									}}
								>
									{altDomain}
								</Tag>
							))}
							<Tag.Group
								hasAddons
								size="medium"
							>
								<Tag size="medium">Reset</Tag>
								<Tag
									remove
									size="medium"
									onClick={(e) => {
										e.preventDefault(); // Prevent default browser actions
										setAltDomains([]); // Reset alternate domain suggestions
										setAvailability(null); // Reset Availability - Form State Updates based on this
										setDomainObject({}); // Reset the Domain
									}}
								/>
							</Tag.Group>
						</Tag.Group>
					)}
				</form>
			</>
		);
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
