/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useEffect, useState, useRef, useContext } from "react";
import { useOutletContext } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../../hooks/useNotification";
import arrayMutators from "final-form-arrays";

/* Context Imports
-------------------------------------------------- */
import { WebsitesContext } from "../../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import {
	Table,
	Section,
	Button,
	Heading,
	Block,
	Notification,
} from "react-bulma-components";
import PopupModal from "../PopupModal";
import GenericConfirmActionPopup from "../PopupModals/GenericConfirmAction_Popup";
import { Form } from "react-final-form";
import { DynamicFormFields } from "../FormComponents/DynamicFormFields";
import { editorSavePrompt } from "../FormComponents/EditorSavePrompt";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * Renders a boxed list component with form fields, group filters, and form data.
 * @param {Object} formFields - The form fields to display in the boxed list.
 * @param {Object} groupFilters - The group filters to apply to the boxed list.
 * @param {Object} form - The form data to populate the boxed list.
 * @returns The rendered boxed list component.
 */
export default function BoxedList({ groupFilters, form }) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const {
		//Context from EditWebsite.js (the parent of this component)
		firebase,
		websiteData,
	} = useOutletContext();
	const notification = useNotification();
	const websites = useContext(WebsitesContext);
	const [messages, setMessages] = useState(null);
	const [popupModal, setPopupModal] = useState(false);
	const [openMessage, setOpenMessage] = useState({});
	const [confirmPopup, setConfirmPopup] = useState(false);
	const isFirstRender = useRef(true); // Use to only allow the useEffect to run once
	const formFields = [
		{
			helpText:
				"You will be notified about website enquiries to this email address.",
			validators: {
				required: "true",
				type: "email",
			},
			label: "Your Email Address",
			id: "emailAddress",
			fields: [
				{
					component: "input",
					attributes: {
						icon: "fa-envelopes-bulk",
					},
				},
			],
			group: "user",
		},
	];

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */
	useEffect(() => {
		try {
			if (!firebase.authLoading && websiteData && isFirstRender.current) {
				const promise = firebase.db
					.get(`messages/${websiteData.data.domain}`, firebase?.accessToken)
					.then((data) => {
						setMessages(data);
						if (!data || data.length < 1) {
							return "No new messages.";
						}
						return `Retrieved ${data.length ?? 0} messages.`;
					})
					.catch((e) => {
						throw e;
					});

				notification.withPromise(promise, { pending: "Loading messages." });
				isFirstRender.current = false;
			}
		} catch (error) {
			notification.add("warning", error.message);
		}
	}, [
		websiteData,
		firebase.authLoading,
		notification,
		firebase.accessToken,
		firebase.db,
	]);

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Handles the click event for deleting a message.
	 * @param {string} id - The ID of the message to delete.
	 * @returns None
	 */
	function handleDeleteClick(id) {
		const promise = firebase.db
			.remove(
				`messages/${websiteData.data.domain}?id=${id}`,
				firebase?.accessToken
			)
			.then((res) => {
				if (res === "success") {
					const messageIndex = messages.findIndex(
						(message) => message.id === id
					);
					if (messageIndex === -1) {
						throw new Error("Message with ID not found:", id);
					}
					const updatedMessages = [
						...messages.slice(0, messageIndex),
						...messages.slice(messageIndex + 1),
					];
					setMessages(updatedMessages);
				}
			});

		notification.withPromise(promise, {
			pending: "Deleting message",
			success: "Message Deleted.",
		});
	}

	/**
	 * Handles the form submission by updating the website data in the Firebase database
	 * and displaying a notification with the result.
	 * @param {Object} values - The form values to update the website data with.
	 * @returns None
	 */
	async function handleFormSubmit(values) {
		const promise = firebase.db
			.patch(
				`websites/${websiteData.id}`,
				{
					...values,
				},
				firebase.user?.accessToken ?? null
			)
			.then(() => websites.updateWebsiteData(websiteData.id, values));

		notification.withPromise(promise, {
			pending: "Updating website",
			success: "Website Updated",
		});
		await promise;
	}
	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Section>
			<Section>
				<Form
					initialValues={{
						user: {
							emailAddress: [
								{
									value: websiteData?.data?.user?.emailAddress[0].value
										? websiteData.data.user.emailAddress[0].value
										: "",
								},
							],
						},
					}}
					mutators={{
						...arrayMutators,
					}}
					subscription={{
						submitting: true,
						pristine: true,
						errors: true,
						validating: true,
					}}
					onSubmit={(values) => handleFormSubmit(values)}
				>
					{(props) => {
						return (
							<form
								onSubmit={props.handleSubmit}
								noValidate={true} // Disable default browser validation #WeDoItBetter
							>
								<DynamicFormFields
									formFields={formFields}
									{...props.form.mutators}
									firebase={firebase}
									notification={notification}
									containerSettings={{
										displayLabel: true,
										displayHelp: true,
										isHorizontal: true,
									}}
								/>
								{!props.pristine && editorSavePrompt(props)}
							</form>
						);
					}}
				</Form>
				<PopupModal
					state={popupModal}
					stateSetter={setPopupModal}
					title={"Read an Email"}
					body={
						<>
							<Block
								className="accordian is-table"
								display="block"
							>
								<Heading size={5}>{openMessage.name}</Heading>
								<Heading
									subtitle
									size={6}
								>
									{openMessage.email} on {openMessage.date}
								</Heading>

								<p>{openMessage.body}</p>
							</Block>
							<Block>
								<Notification color="warning">
									Email Addresses are not verified as they are entered via the
									contact form on your website. Keep your eye out for scams and
									fake emails.
								</Notification>
							</Block>
						</>
					}
					footer={
						<Button.Group>
							<Button
								color="dark"
								onClick={() => {
									handleDeleteClick(openMessage.id);
									setPopupModal(false);
									setOpenMessage({});
								}}
							>
								Permanently Delete
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setPopupModal(false); // Close Modal
									setOpenMessage({}); // Reset Message Array
								}}
							>
								Close
							</Button>
						</Button.Group>
					}
				/>
			</Section>
			<Section>
				<Table>
					<thead>
						<tr>
							<th>Contact Name</th>
							<th>Sender</th>
							<th>Email</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{messages &&
							messages.map((message) => {
								return (
									<tr key={message.id ?? ""}>
										<GenericConfirmActionPopup
											key={"ConfirmPopup"}
											state={confirmPopup}
											stateSetter={setConfirmPopup}
											action={() => {
												handleDeleteClick(message.id);
											}}
										/>
										<td>{message.name ?? ""}</td>
										<td>{message.email ?? ""}</td>
										<td>{message.body ?? ""}</td>
										<td>
											<Button.Group>
												<Button
													color="info"
													onClick={() => {
														setOpenMessage({
															id: message.id,
															name: message.name,
															email: message.email,
															body: message.body,
															date: new Date(
																message.time._seconds * 1000
															).toUTCString(),
														});

														setPopupModal(true);
													}}
												>
													<FontAwesomeIcon icon={faEye} />
												</Button>
												<Button
													color="danger"
													onClick={() => setConfirmPopup(true)}
												>
													<FontAwesomeIcon icon={faTrash} />
												</Button>
											</Button.Group>
										</td>
									</tr>
								);
							})}

						{!messages ||
							(messages.length < 1 && (
								<tr>
									<td
										colSpan={4}
										align="center"
									>
										You have no new messages. Messages that appear here are sent
										via your website's contact form.
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			</Section>
		</Section>
	);
}
/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
