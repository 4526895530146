/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { ToastContainer } from "react-toastify";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import ServiceStatus from "../pages/ServiceStatus";
import EditWebsite from "../pages/EditWebsite";
import AuthenticationRequired from "./AuthenticationRequired";
import EditorForm from "./EditWebsitePages/EditorForm";
import Settings from "../pages/Settings";
/**
 * Renders the page heading component with the given title, subtitle, loading state, and buttons.
 * @param {string} title - The main title of the page heading.
 * @param {string} subtitle - The subtitle of the page heading.
 * @param {boolean} loading - Indicates whether the page is currently loading.
 * @param {ReactNode} buttons - The buttons to display in the page heading.
 * @returns The rendered page heading component.
 */
import Emails from "./EditWebsitePages/Emails";
import ManageUsers from "../pages/ManageUsers";
import E404 from "../pages/404";

/* Templates */
import SidebarContentWrapper from "../template-parts/SidebarContentWrapper";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function App() {
  /* State, Context and Hooks
  -------------------------------------------------- */
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState(null); // Holds the page title of the current page
  const { dbError, setDbError } = firebase.db;

  /* Dependencies
  -------------------------------------------------- */

  /* useEffects
  -------------------------------------------------- */
  // Trigger state change when firebase user changes
  useEffect(() => {
    if (firebase.loading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    return function cleanup() {
      return null;
    };
  }, [firebase.loading]);

  // Manage Page Title State
  useEffect(() => {
    document.title = pageTitle
      ? `${pageTitle} - Web Design for Actors`
      : "Web Design for Actors";
  }, [pageTitle]);

  /*=============================================
  =                   Functions                   =
  =============================================*/

  /*============  End of Functions  =============*/

  /*=============================================
=                   Components                   =
=============================================*/

  /*============  End of Components  =============*/

  /*=============================================
  =                   Main Return                   =
  =============================================*/

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/****  PUBLICLY ACCESSIBLE ROUTES ****/}
          {/* Login Page */}
          <Route
            path=""
            element={
              <Login
                firebase={firebase}
                setPageTitle={setPageTitle}
                pageTitle={pageTitle}
              />
            }
          />

          {/**** ROUTES WITH AUTHENTICATION REQUIRED ****/}
          <Route
            path="/dashboard/"
            element={
              <AuthenticationRequired
                accessLevel={"user"}
                setPageTitle={setPageTitle}
                pageTitle={pageTitle}
              />
            }
          >
            {/* /dashboard/ */}
            <Route
              path=""
              element={
                <SidebarContentWrapper
                  loading={loading}
                  dbError={dbError}
                  setDbError={setDbError}
                >
                  <Dashboard
                    loading={loading}
                    setLoading={setLoading}
                    setPageTitle={setPageTitle}
                    pageTitle={pageTitle}
                  />
                </SidebarContentWrapper>
              }
            />
            {/* /dashboard/edit/<websitedomain> */}
            <Route
              path="edit/:websiteId/"
              element={
                <SidebarContentWrapper
                  loading={loading}
                  dbError={dbError}
                  setDbError={setDbError}
                >
                  <EditWebsite
                    loading={loading}
                    setPageTitle={setPageTitle}
                    pageTitle={pageTitle}
                  />
                </SidebarContentWrapper>
              }
            >
              <Route
                path="emails"
                element={<Emails />}
              />
              {/* Catch All */}
              <Route
                path="" // Default values on /id/
                element={<EditorForm />}
              />
              <Route
                path=":g" // Catches /id/group,list params
                element={<EditorForm />}
              />
              {/* End Catch All */}
            </Route>
            <Route
              path="settings"
              element={
                <SidebarContentWrapper
                  loading={loading}
                  dbError={dbError}
                  setDbError={setDbError}
                >
                  <Settings
                    loading={loading}
                    setPageTitle={setPageTitle}
                    pageTitle={pageTitle}
                  />
                </SidebarContentWrapper>
              }
            ></Route>
            <Route
              path="status"
              element={
                <SidebarContentWrapper
                  loading={loading}
                  dbError={dbError}
                  setDbError={setDbError}
                >
                  <ServiceStatus
                    setPageTitle={setPageTitle}
                    pageTitle={pageTitle}
                  />
                </SidebarContentWrapper>
              }
            ></Route>

            <Route
              path="manageusers"
              element={
                <SidebarContentWrapper
                  loading={loading}
                  dbError={dbError}
                  setDbError={setDbError}
                >
                  <ManageUsers
                    setPageTitle={setPageTitle}
                    pageTitle={pageTitle}
                  />
                </SidebarContentWrapper>
              }
            ></Route>
          </Route>
          <Route
            path="*"
            element={<E404 />}
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
