/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useRef, useState, useContext } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../../hooks/useNotification";
import { Cropper } from "react-advanced-cropper";
import { uploadFile } from "../../helpers/handleFileUpload";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import PopupModal from "../PopupModal";
import { Button, Section, Heading } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/corners.css";

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function CropImagePopup(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const notification = useNotification();
	const firebase = useContext(FirebaseContext);
	const { state, stateSetter, imageSrc, setImageSrc } = props;
	const cropperRef = useRef(null);
	const [image, setImage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Renders an image cropper component that allows the user to crop a photo to fit properly.
	 * @returns The JSX element representing the image cropper component.
	 */
	function ImageCropper() {
		return (
			<Section pl={1}>
				<Heading subtitle>Crop your photo so that it fits properly.</Heading>
				<Cropper
					src={imageSrc.downloadURL}
					ref={cropperRef}
					className={"cropper"}
					stencilProps={{
						aspectRatio: 41 / 32,
						grid: true,
					}}
				/>
			</Section>
		);
	}

	/**
	 * Handles the cropping of an image using the cropperRef.
	 * @returns {Promise<Blob | null>} - A promise that resolves to the cropped image blob, or null if no image was cropped.
	 */
	async function handleCrop() {
		let croppedImage = null;

		if (cropperRef.current) {
			const blob = await new Promise((resolve) => {
				cropperRef.current.getCanvas()?.toBlob((blob) => {
					resolve(blob);
				});
			});

			if (blob) {
				croppedImage = blob;
				setImage(croppedImage);
			}
		}

		return croppedImage;
	}

	/**
	 * Handles the click event when the submit button is clicked.
	 * Sets the isLoading state to true.
	 * Calls the handleCrop function to get the cropped image.
	 * If a cropped image is obtained, it uploads the image using the uploadFile function.
	 * Once the image is uploaded, it sets the image source state to the uploaded cropped image.
	 * Finally, it sets the isLoading state to false and calls the stateSetter function with false as the argument.
	 * @returns None
	 */
	async function handleSubmitClick() {
		setIsLoading(true);
		const croppedImage = await handleCrop();

		if (croppedImage) {
			await uploadFile(
				croppedImage,
				"blob",
				false,
				image,
				firebase,
				notification,
				imageSrc.name
			)
				.then((uploadedCroppedImage) => {
					setImageSrc(uploadedCroppedImage);
				})
				.finally(() => {
					setIsLoading(false);
					stateSetter(false);
				});
		}
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<PopupModal
			state={state}
			stateSetter={stateSetter}
			title={`Crop a photo`}
			body={ImageCropper()}
			footer={
				<Button.Group>
					<Button
						color="primary"
						submit
						onClick={handleSubmitClick}
						loading={isLoading}
						disabled={isLoading}
					>
						Save Changes
					</Button>
				</Button.Group>
			}
		/>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
