/*=============================================
=                   IMPORTS                   =
=============================================*/
import handleUserUpdate from "./handleUserUpdate";

/*============  End of IMPORTS  =============*/

/*=============================================
=                   Functions                   =
=============================================*/

/* deleteFile()
-------------------------------------------------- */
/**
 * Deletes a file from Firebase storage.
 * @param {object} existingFile - The existing file object to delete.
 * @param {object} firebase - The Firebase instance.
 * @returns {Promise<void>} - A promise that resolves when the file is successfully deleted.
 * @throws {Error} - If there is an error deleting the file.
 */
export async function deleteFile(existingFile, firebase) {
	firebase.getDocRef(existingFile.fullPath).then((ref) => {
		// Get a reference object for the file based on the filepath
		firebase
			.deleteDoc(ref) // Delete the document
			.catch((error) => {
				throw error;
			});
	});
}

/* uploadFile()
-------------------------------------------------- */
export async function uploadFile(
	file,
	type,
	updateUser,
	stateSetter,
	firebase,
	notification,
	fileName
) {
	if (type === "image" && !file.type.startsWith("image/")) {
		throw new Error(`Invalid Filetype ${file.type}. Must be ${type}`);
	} else if (type === "pdf" && file.type !== "application/pdf") {
		throw new Error(`Invalid Filetype ${file.type}. Must be ${type}`);
	}

	const metadata = {
		contentType: file.type,
		size: file.size,
		name: file.name ?? fileName ?? "undefined name",
	};

	// DRY concept - fires only when called
	return firebase
		.getDocRef(firebase.user.uid)
		.then((docRef) => {
			return firebase.uploadWebsiteFile(docRef, file, metadata, fileName); // Send file to upload in the user's storage folder
		})

		.then((uploadedFile) => {
			if (updateUser) {
				handleUserUpdate(
					"photoURL",
					uploadedFile.downloadURL,
					stateSetter,
					null,
					firebase
				); // Set Image URL to User Profile
			}
			return uploadedFile;
		})

		.catch((error) => {
			throw error;
		});
}

/* useFileUpload()
-------------------------------------------------- */
/**
 * Handles file upload functionality.
 * @param {Event} e - The event object triggered by the file upload.
 * @param {object} state - The current state object.
 * @param {string} type - The type of file being uploaded.
 * @param {function} stateSetter - The function to update the state.
 * @param {function} updateUser - The function to update the user data.
 * @param {object} firebase - The Firebase object.
 * @param {object} notification - The notification object.
 * @returns None
 * @throws {Error} If an error occurs during the file upload process.
 */
export default async function useFileUpload(
	e,
	state,
	type,
	stateSetter,
	updateUser,
	firebase,
	notification
) {
	try {
		const selectedFile = e.target.files.item(0); // Convert the item to upload from a File object to a usual object

		if (e.target.files.length === 0) {
			throw new Error(
				"Error uploading a replacement file: No file was selected for upload, please try again."
			);
		} else if (state?.fullPath) {
			// If a file already exists, delete it and upload the new one
			// Delete existing file
			console.log("Deleting the just uploaded file");

			await deleteFile(state);

			// Upload the new File
			return await uploadFile(
				selectedFile,
				type,
				updateUser,
				stateSetter,
				firebase,
				notification
			);
		} else {
			// If new file is ready to upload and a file does not already exist
			return await uploadFile(
				selectedFile,
				type,
				updateUser,
				stateSetter,
				firebase,
				notification
			);
		}
	} catch (error) {
		notification.add("danger", error.message);
		throw error;
	}
}

/*============  End of Functions  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
