/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import ContentLoader from "react-content-loader";
import { Level } from "react-bulma-components"; /* Icons Imports */

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * Renders a skeleton loading animation for a page heading.
 * @returns The JSX element representing the page heading skeleton.
 */
export default function PageHeadingSkeleton() {
	/* State, Context and Hooks
  -------------------------------------------------- */

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/
	return (
		<Level>
			<Level.Side align="left">
				<ContentLoader
					speed={2}
					width="100%"
					height="100px"
					// viewBox="0 0 100% 100%"
					backgroundColor="#f3f3f3"
					foregroundColor="#fff"
				>
					<rect
						width="100%"
						height="3rem"
					/>
					<rect
						y="4rem"
						width="50%"
						height="1.25rem"
					/>
				</ContentLoader>
			</Level.Side>
			<Level.Side align="right">
				<ContentLoader
					speed={2}
					width="100%"
					height="80px"
					// viewBox="0 0 100% 100%"
					backgroundColor="#f3f3f3"
					foregroundColor="#fff"
				>
					<rect
						width="50%"
						height="40px"
					/>
					<rect
						width="50%"
						height="40px"
						x="56%"
					/>
				</ContentLoader>
			</Level.Side>
		</Level>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
