/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useEffect, useContext, useRef } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useNotification from "../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Block, Table, Button } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faXmark,
	faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * Renders a subscription table component.
 * @param {Object} props - The props passed to the component.
 * @param {function} props.setCancelSubscriptionPopup - A function to set the cancel subscription popup state.
 * @param {function} props.setSubscription - A function to set the subscription state.
 * @param {Array} props.subscriptions - An array of subscription objects.
 * @param {function} props.setSubscriptions - A function to set the subscriptions state.
 * @returns The rendered subscription table component.
 */
export default function SubscriptionTable(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const {
		setCancelSubscriptionPopup,
		setSubscription,
		subscriptions,
		setSubscriptions,
	} = props;
	const firebase = useContext(FirebaseContext);
	const notification = useNotification();
	let firstRender = useRef(true); // Prevent infinite loop inside useEffect

	/* Dependencies
  -------------------------------------------------- */

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (
			!firebase.authLoading &&
			firebase.billingUser &&
			subscriptions.length === 0 &&
			firstRender.current // Only run the get code if firstRender is true
		) {
			firebase.db
				.get(
					`billing/subscriptions/?id=${firebase.billingUser.id}`,
					firebase?.accessToken
				)
				.then((subs) => {
					if (firstRender) {
						const newSubs = subs.data.map((sub) => ({ ...sub }));
						setSubscriptions(newSubs);
					}
				})
				.finally(() => {
					firstRender.current = false; // Always set firstRender to false after running the get code
				});
		}
	}, [
		firebase.authLoading,
		firebase.billingUser,
		firebase.db,
		subscriptions,
		setSubscriptions,
		firebase.accessToken,
	]);

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Handles the download of an invoice by retrieving the invoice URL from the Firebase database.
	 * @param {string} invoiceId - The ID of the invoice to download.
	 * @returns {Promise<string>} - A promise that resolves to the URL of the invoice.
	 * @throws {Error} - If there is an error retrieving the invoice URL or if the URL is not found.
	 */
	async function handleInvoiceDownload(invoiceId) {
		const invoiceUrl = await firebase.db
			.get(
				`billing/invoice/${invoiceId}/?customerId=${firebase.billingUser.id}`,
				firebase.accessToken
			)
			.then((res) => {
				if (!res.hosted_invoice_url) {
					// Display error and return if response did not contain an invoice link
					throw new Error("Unable to get your invoice link");
				}

				return res.hosted_invoice_url;
			})
			.catch((error) => {
				throw error;
			});

		return invoiceUrl;
	}
	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Block className="accordian is-table">
			<Table
				striped
				hoverable
			>
				<thead>
					<tr>
						<th>Status</th>
						<th>Domain</th>
						<th>Plan</th>
						<th>Renewal</th>
						<th>Price</th>
						<th align="right">Actions</th>
					</tr>
				</thead>
				<tbody>
					{!firebase.billingUser && (
						<tr>
							<td colSpan={6}>You have no subscriptions yet.</td>
						</tr>
					)}
					{subscriptions.length > 0 &&
						subscriptions.map((subscription) => {
							if (subscription.status === "incomplete_expired") {
								return null;
							}
							const incomplete = subscription.status === "incomplete";
							const cancelled =
								subscription.cancel_at_period_end ||
								subscription.cancel_at !== null; // returns true if the subscription is cancelled
							return (
								<tr key={subscription.id}>
									<td style={{ textTransform: "capitalize" }}>
										{subscription.status}
									</td>
									<td>{subscription.metadata?.domain}</td>
									<td>{subscription.plan.nickname}</td>
									<td>
										{/*Show Cancelled if the Subscription is Cancelled*/}
										{(!cancelled &&
											new Date(
												subscription.current_period_end * 1000
											).toLocaleDateString()) ||
											(cancelled && "Cancelled")}{" "}
									</td>
									<td>
										{subscription.plan.amount / 100}
										{subscription.plan.currency}
									</td>
									<td>
										<Button.Group align="right">
											{/* Cancel Subscription before Next Renewal */}
											<Button
												color="warning"
												disabled={cancelled || incomplete} // Disable button if subscription is already cancelled
												onClick={() => {
													setCancelSubscriptionPopup(true);
													setSubscription(subscription);
												}}
											>
												<FontAwesomeIcon icon={faXmark} />
											</Button>

											{/* Open Latest Invoice */}
											<Button
												color="info"
												disabled={!subscription.latest_invoice} // Disable if no invoice exists
												onClick={async () => {
													// Prevent popup blocking
													// Do this here to try and prevent popup blockers as it is a direct result of clicking on this button
													const windowReference = window.open();
													handleInvoiceDownload(subscription.latest_invoice)
														.then((url) => (windowReference.location = url))
														.catch((error) => {
															notification.add(
																"danger",
																"Unable to open invoice page"
															);
															throw error;
														});
												}}
											>
												<FontAwesomeIcon icon={faFileInvoiceDollar} />
											</Button>
										</Button.Group>
									</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</Block>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
