/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useState, useEffect, useRef } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useManageCards from "../../hooks/useManageCards";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Block, Table, Icon, Button } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
	faCcAmex,
	faCcApplePay,
	faCcDinersClub,
	faCcDiscover,
	faCcJcb,
	faCcMastercard,
	faCcVisa,
} from "@fortawesome/free-brands-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * A component that displays a table of cards.
 * @param {Object} props - The component props.
 * @param {function} props.setAddCardPopup - A function to set the state of the add card popup.
 * @param {function} props.setClientSecret - A function to set the client secret.
 * @returns The rendered CardsTable component.
 */
export default function CardsTable(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	/* Context and Hook Values */
	const { setAddCardPopup, setClientSecret } = props;
	const firebase = useContext(FirebaseContext);
	const manageCards = useManageCards(firebase);
	const [cards, setCards] = useState([]);
	const isFirstRender = useRef(true);
	/* Dependencies
  -------------------------------------------------- */
	// Handle updates when the specified dependencies change
	const iconMap = {
		visa: faCcVisa,
		mastercard: faCcMastercard,
		jcb: faCcJcb,
		discover: faCcDiscover,
		applePay: faCcApplePay,
		amex: faCcAmex,
		dinersClub: faCcDinersClub,
	};

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (
			!isFirstRender.current &&
			!firebase.authLoading &&
			firebase.billingUser
		) {
			isFirstRender.current = false;
			firebase.db
				.get(
					`billing/cards/?id=${firebase.billingUser.id}`,
					firebase?.accessToken
				)
				.then((cards) => {
					if (isFirstRender.current) {
						// Check isFirstRender.current
						const newCards = cards.data?.map((card) => ({ ...card }));
						setCards(newCards);
					}
				})
				.catch((error) => {
					throw error;
				});
		}
	}, [
		firebase.billingUser,
		firebase.authLoading,
		firebase.accessToken,
		firebase.db,
	]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<>
			<Block className="accordian is-table">
				<Table striped>
					<thead>
						<tr>
							<th>Card Number</th>
							<th>Exipry</th>
							<th align="right">Actions</th>
						</tr>
					</thead>
					<tbody>
						{cards.length > 0 &&
							cards.map((card) => {
								return (
									<tr key={card.id}>
										<td>
											***{card.card.last4}
											<Icon
												size="auto"
												pull="right"
											>
												<FontAwesomeIcon
													icon={iconMap[card.card.brand.toLowerCase()]}
												/>
											</Icon>
										</td>
										<td>
											{card.card.exp_month}/{card.card.exp_year}
										</td>
										<td align="right">
											<Button
												color="warning"
												disabled={cards.length <= 1}
												onClick={(e) => {
													manageCards.remove(
														e,
														firebase?.billingUser?.id,
														card.id,
														setCards
													);
												}}
											>
												<FontAwesomeIcon icon={faTrash} />
											</Button>
										</td>
									</tr>
								);
							})}
						{!firebase.billingUser && (
							<tr>
								<td colSpan={3}>You have no cards to manage yet.</td>
							</tr>
						)}
					</tbody>
				</Table>
			</Block>
			<Block>
				{firebase.billingUser && (
					<Button.Group align="right">
						<Button
							color="success"
							disabled={!firebase.billingUser}
							onClick={async (e) => {
								const _client_secret = await manageCards.getSecret(
									e,
									firebase?.billingUser.id
								);
								setClientSecret(await _client_secret);
								setAddCardPopup(true);
							}}
						>
							<FontAwesomeIcon icon={faPlus} />
							&nbsp; New Card
						</Button>
					</Button.Group>
				)}
			</Block>
		</>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
