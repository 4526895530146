/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Button, Section, Heading, Level } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export function editorSavePrompt(formProps) {
	/* State, Context and Hooks
  -------------------------------------------------- */

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Section
			className="editorBtnsOver selectable"
			backgroundColor="light"
			id="formButtons"
		>
			<Level>
				<Level.Side align="left">
					<Level.Item>
						<Heading
							subtitle
							size={5}
						>
							Don't forget to save your changes.
						</Heading>
					</Level.Item>
				</Level.Side>
				<Level.Side align="right">
					<Level.Item>
						<Button.Group>
							<Button
								type="submit"
								color="primary"
								disabled={
									formProps.pristine ||
									formProps.invalid ||
									formProps.validating
								}
								loading={formProps.submitting}
							>
								Save Changes
							</Button>
							<Button
								type="reset"
								color="light"
								inverted
								onClick={formProps.form.reset}
								disabled={formProps.submitting || formProps.pristine}
							>
								Cancel
							</Button>
						</Button.Group>
					</Level.Item>
				</Level.Side>
			</Level>
		</Section>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
