/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Card, Heading } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckRampBox } from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

/**
 * Renders a Call to Action (CTA) component.
 * @returns The rendered CTA component.
 */
export default function CTA() {
	/* State, Context and Hooks
  -------------------------------------------------- */

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Card.Content>
			<FontAwesomeIcon
				icon={faTruckRampBox}
				size="6x"
			/>
			<Heading>Build your new website in minutes</Heading>
			<Heading
				subtitle
				p={4}
			>
				Tell us some information about you and your skills, upload your CV and
				Headshot, <br />
				and we'll handle the rest. Update it here, at any time. It's as simple
				as that.
			</Heading>
		</Card.Content>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
