/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import { default as handleFileUpload } from "../../helpers/handleFileUpload";
import clsx from "clsx";

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Form as BulmaForm, Button, Icon } from "react-bulma-components";
import { ColourScheme } from "../FormComponents/ColourScheme";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import FormFieldContainer from "../FormComponents/FormFieldContainer";
import CropImagePopup from "../PopupModals/CropImage_Popup";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

/*============  End of IMPORTS  =============*/

/*=============================================
=                   Outer Functions                   =
=============================================*/

/**
 * A React component that renders a spacer element.
 * @param {number} fieldKeyIndex - The index of the field key.
 * @returns The rendered spacer element.
 */
function Spacer({ fieldKeyIndex }) {
	return (
		<BulmaForm.Field
			multiline={true}
			kind="group"
			style={{
				overflow: "hidden",
				flexBasis: "100%",
			}}
			key={`spacer_${fieldKeyIndex}`}
			order={fieldKeyIndex}
		>
			<BulmaForm.Control></BulmaForm.Control>
		</BulmaForm.Field>
	);
}

/**
 * A custom rating field component that displays a set of buttons for rating.
 * @param {Object} props - The props object containing meta and input properties.
 * @param {Object} props.meta - The meta object containing information about the field's state.
 * @param {Object} props.input - The input object containing information about the field's value and onChange function.
 * @returns The JSX code for rendering the rating field component.
 */
function RatingField({ meta, ...input }) {
	const buttonCount = 5;

	library.add(fas); // This adds all solid icons to the library
	const buttons = [];

	for (let count = 1; count <= buttonCount; count++) {
		const percentageValue = count * (100 / buttonCount); // Sets the value to a percentage, dependant on amount of buttons (buttonCount)

		buttons.push(
			// Add each button to an array of buttons.
			<Button
				key={count}
				type="button"
				onClick={() => {
					input.onChange(percentageValue);
				}}
				color={
					(meta.error !== "") === "danger"
						? "danger"
						: input.value >= percentageValue
						? "primary"
						: ""
				}
			>
				<FontAwesomeIcon icon="star" />
			</Button>
		);
	}

	return (
		<Button.Group
			hasAddons
			justifyContent="center"
		>
			{buttons}
		</Button.Group>
	);
}

/**
 * Renders a select field component with the provided options and name.
 * @param {Object} options - An array of options for the select field.
 * @param {string} name - The name of the select field.
 * @param {Object} input - Additional input props for the select field.
 * @returns The rendered select field component.
 */
function SelectField({ options, name, ...input }) {
	const defaultOptionName = `option_${name}_default`;
	function getFields() {
		if (options?.length > 0) {
			return options.map((item) => {
				return (
					<option
						value={item.value ?? undefined}
						key={`option_${name}_${item.value ?? undefined}`}
					>
						{item.label ?? undefined}
					</option>
				);
			});
		} else {
			return null;
		}
	}

	const isEmptyField =
		!input.value || input.value === " " || input.value.length <= 0;

	return (
		<BulmaForm.Select
			key={name}
			onChange={(e) => {
				const value = e.target.value;
				input.origOnChange(value);
			}}
			value={isEmptyField ? defaultOptionName : input.value}
		>
			<option
				key={defaultOptionName}
				value={defaultOptionName}
				disabled
			>
				Select an option
			</option>
			{getFields()}
		</BulmaForm.Select>
	);
}

/**
 * A component for uploading files.
 * @param {Object} props - The component props.
 * @param {Object} props.meta - The meta object.
 * @param {Object} props.firebase - The firebase object.
 * @param {Object} props.notification - The notification object.
 * @param {Object} props.input - The input object.
 * @returns The FileUpload component.
 */
function FileUpload({ meta, firebase, notification, ...input }) {
	const [isLoading, setIsLoading] = useState(false);
	const [cropPopup, setCropPopup] = useState(false);
	/**
	 * Sets the file preview based on the uploaded file and its type.
	 * @param {object} uploaded - The uploaded file object.
	 * @param {string} type - The type of the uploaded file.
	 * @returns {string | JSX.Element | undefined} - The file preview.
	 */
	function setFilePreview(uploaded, type) {
		if (!uploaded || uploaded.length < 1) {
			// If there is no file to preview
			return; // Nothing to do here
		}

		if (uploaded?.downloadURL && (type === "image" || type === "media")) {
			return (
				<img
					src={uploaded.downloadURL}
					alt={`Your ${uploaded.name ?? "Uploaded File"}${
						uploaded.name ? ": " + uploaded.name : ""
					}`} // Your Headshot, Your Profile Photo, Your CV, Your Uploaded File
				/>
			);
		} else if (uploaded.downloadURL) {
			return uploaded.name ?? `Your ${uploaded.name ?? "file"}`;
		}
	}

	async function handleUpload(e) {
		setIsLoading(true);

		await handleFileUpload(
			e,
			input.value,
			input.type,
			input.onChange,
			input.updateUser,
			firebase,
			notification
		)
			.then((res) => {
				input.onChange(res);
			})
			.finally(() => setIsLoading(false));
	}
	return (
		<>
			<BulmaForm.InputFile
				className={
					input.type === "image" || input.type === "media"
						? "display-image"
						: null
				} // set class "display-image" if file type is "image"
				boxed={true}
				icon={
					// Show spinner icon when loading
					isLoading && (
						<FontAwesomeIcon
							icon="spinner"
							spin
						/>
					)
				}
				filename={setFilePreview(input.value, input.type)}
				inputProps={{
					disabled: isLoading,
					accept:
						input.type === "media"
							? "audio/*, video/*, image/*"
							: input.type === "image"
							? "image/*"
							: "application/pdf",
				}} // Disable selecting when Loading
				onChange={(e) => {
					handleUpload(e).then(() => {
						if (input.forceCrop) {
							setCropPopup(true);
						}
					});
				}}
				label={`Upload a ${input.name ?? "file"}`}
			/>

			<CropImagePopup
				state={cropPopup}
				stateSetter={setCropPopup}
				imageSrc={input.value}
				setImageSrc={input.onChange}
			/>
		</>
	);
}

/*============  End of Outer Functions  =============*/

export function DynamicFormFields({
	formFields,
	groupFilters,
	fieldFilters,
	firebase,
	notification,
	containerSettings,
	onlyShowIndex,
}) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	let relevantFields = {};
	const hasFieldFilters =
		fieldFilters && Object.keys(fieldFilters).length ? true : false;
	const hasGroupFilters =
		groupFilters && Object.keys(groupFilters).length ? true : false;

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Filters an array of form fields based on the specified groups.
	 * @param {Array} filters - An array of group names to filter by.
	 * @returns {Array} - An array of form fields that belong to the specified groups.
	 */
	function getGroups(filters) {
		return formFields.filter((fields) => filters.includes(fields.group));
	}

	/**
	 * Retrieves the filtered fields based on the provided field filters.
	 * @param {Object} fieldFilters - The field filters object containing groups and field IDs.
	 * @returns {Array} An array of filtered fields that match the provided field filters.
	 */
	function getFilteredFields(fieldFilters) {
		const filteredFields = [];

		for (const group in fieldFilters) {
			const fieldIds = fieldFilters[group];

			fieldIds.forEach((fieldId) => {
				const matchingField = formFields.find(
					(field) => field.group === group && field.id === fieldId
				);
				if (matchingField) {
					filteredFields.push(matchingField);
				}
			});
		}
		return filteredFields;
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (!hasFieldFilters && hasGroupFilters) {
		relevantFields = getGroups(groupFilters); // Just return all the containers that groupFields matches

		// IF WE NEED TO FILTER FIELDS FROM THE GROUP
	} else if (hasFieldFilters) {
		relevantFields = getFilteredFields(fieldFilters); // Return filtered fields based on the provided object of filters
	} else {
		relevantFields = formFields;
	}

	return relevantFields.map((container, index) => {
		const uniqueContainerId = `${container.group}.${container.id}`; // returns e.g. intro.stageName
		const componentMap = {
			field: BulmaForm.Input,
			textarea: BulmaForm.Textarea,
			file: FileUpload,
			select: SelectField,
			colorScheme: ColourScheme,
			rating: RatingField,
			spacer: Spacer,
		};

		return (
			<FieldArray
				name={uniqueContainerId}
				key={`array_${uniqueContainerId}_${index}`}
				subscription={{
					error: true,
					value: true,
					touched: true,
				}}
			>
				{({ fields: arrayField, meta }) => (
					// available from props: arrayField, meta
					<FormFieldContainer
						labelText={container.label}
						helpText={container.helpText}
						{...containerSettings}
						key={`container_${uniqueContainerId}_${index}`}
						color={meta.touched && meta.error ? "danger" : ""}
					>
						{arrayField.map((name, rowIndex) => {
							if (onlyShowIndex >= 0 && onlyShowIndex !== rowIndex) {
								return null;
							}

							const keysArray = Reflect.ownKeys(arrayField.value[rowIndex]);
							const keysArrayLength = keysArray.length;

							return (
								<div
									key={`fieldsContainer_${name}`}
									className="field is-grouped is-grouped-multiline"
									style={{
										display: "flex",
										placeContent: "flex-end",
										overflow: "hidden",
										alignItems: "baseline",
										boxShadow:
											keysArrayLength > 1
												? "rgb(247 247 247) 2px 3px 4px 0px"
												: "unset",
										padding: keysArrayLength > 1 ? "1em 10px" : "unset",
										margin: keysArrayLength > 1 ? "1em 10px" : "unset",
										border:
											keysArrayLength > 1
												? "3px dotted rgb(227 226 226)"
												: "unset",
									}}
								>
									{keysArray.map((fieldKey, fieldKeyIndex) => {
										const fieldConfig = container.fields.find((fieldConfig) => {
											if (!fieldConfig.id && fieldKey === "value") {
												return fieldConfig;
											} else if (fieldConfig.id === fieldKey) {
												return fieldConfig;
											} else {
												return null;
											}
										});

										if (!fieldConfig) {
											notification.add(
												"warning",
												`Unable to generate a field. Could not find keys for ${fieldKey} in ${name}. Please contact support`
											);
											return null;
										}

										const fieldFlexBasis = () => {
											if (fieldConfig.attributes?.width) {
												// If a width is defined in the field config, use that
												return `calc(${fieldConfig.attributes?.width} - 18px)`;
											} else if (keysArrayLength === 1) {
												// If there is only 1 key in the keys array
												return "100%";
											} else {
												// Default to 45% (2x Columns Per Row)
												return "calc(50% - 18px)";
											}
										};

										library.add(fas, fab);
										const iconPrefix =
											fieldConfig.attributes?.icon &&
											fieldConfig.attributes?.icon.startsWith("fa-")
												? "fas"
												: "fab";

										const Component =
											componentMap[fieldConfig.component] || BulmaForm.Input; // Uses the componentMap to change the Component respectively, defaults to Form.field

										function validate(value, allValues, validators) {
											const isEmptyField =
												!value || value === " " || value.length <= 0;

											if (validators.required && isEmptyField) {
												return "Please fill out this field.";
											}

											if (!isEmptyField) {
												if (validators.type === "number" && isNaN(value)) {
													return "Must be a number";
												}

												if (
													validators.maxChars !== undefined &&
													value.length > validators.maxChars
												) {
													return `There are too many characters in this field. ${
														value.length
													} of ${validators.maxChars ?? 0}.`;
												}

												if (
													validators.minChars !== undefined &&
													value.length < validators.minChars
												) {
													return `Minimum of ${
														validators.minChars ?? 0
													} characters required in this field.`;
												}

												if (
													validators.min !== undefined &&
													Number(value) < validators.min
												) {
													return `Must be a number over ${validators.min}`;
												}

												if (
													validators.max !== undefined &&
													Number(value) > validators.max
												) {
													return `Must be a number under ${validators.max}`;
												}

												if (validators.type !== undefined) {
													// Regex Library for Future Joe: https://uibakery.io/regex-library/
													/* eslint-disable no-useless-escape*/

													const urlRegex =
														/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

													const emailRegex =
														/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

													const telRegex =
														/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

													const nameRegex =
														/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
													/* esline-enable */

													if (
														validators.type === "url" &&
														!value.match(urlRegex)
													) {
														return "Please enter a valid URL. It must start with https://";
													}

													if (
														validators.type === "email" &&
														!value.match(emailRegex)
													) {
														return "Please enter a valid email address.";
													}

													if (
														validators.type === "tel" &&
														!value.match(telRegex)
													) {
														return "Please enter a valid phone number.";
													}
													if (
														validators.type === "name" &&
														!value.match(nameRegex)
													) {
														return "We do not currently accept this format of name.";
													}
												}
											}

											// Add more if conditions for other validators as needed
											return;
										}

										const validationFunction = (value, allValues) =>
											validate(value, allValues, fieldConfig.validators ?? {});

										return (
											<BulmaForm.Field
												kind={fieldConfig.append ? "addons" : "group"}
												multiline={true}
												style={{
													overflow: "hidden",
													flexBasis: fieldFlexBasis(),
													margin: "5px",
													order: fieldKeyIndex,
													placeItems: "flex-end",
													color: "unset",
													display:
														fieldConfig.attributes?.hidden === true
															? "none"
															: "inherit",
												}}
												key={`outerfield_${uniqueContainerId}_${fieldKey}`}
											>
												<Field
													name={`${name}.${fieldKey}`}
													placeholder={fieldConfig.attributes?.placeholder}
													subscription={{
														error: true,
														value: true,
														touched: true,
													}}
													defaultValue={
														fieldKey === "date"
															? new Date().toISOString()
															: null
													}
													key={
														arrayField?.value?.[fieldKeyIndex]?.[fieldKey]
															? 1
															: 0
													}
													validate={(value, allValues) =>
														validationFunction(value, allValues)
													}
												>
													{({ input, meta, placeholder }) => {
														const color =
															meta.error && meta.touched ? "danger" : "";

														function handleChange(e) {
															if (!e.currentTarget) {
																return input.onChange(e);
															}
															const newValue = e.currentTarget.value;
															const trimmedValue = newValue.startsWith(" ")
																? newValue.trimStart()
																: newValue;
															input.onChange(trimmedValue || " ");
														}
														return (
															<BulmaForm.Control
																fullwidth={true}
																key={`innerField_${fieldConfig.attributes?.label}`}
															>
																{fieldConfig.attributes?.label && (
																	<div>
																		<BulmaForm.Label>
																			{fieldConfig.attributes.label}
																		</BulmaForm.Label>
																	</div>
																)}
																{fieldConfig.attributes?.icon &&
																	fieldConfig.component !== "rating" && (
																		<Icon
																			align="left"
																			size="small"
																			style={{
																				top: fieldConfig.attributes?.label
																					? "24px"
																					: "0px",
																			}}
																		>
																			<FontAwesomeIcon
																				icon={[
																					iconPrefix,
																					fieldConfig.attributes?.icon,
																				]}
																			/>
																		</Icon>
																	)}
																<Component
																	placeholder={placeholder}
																	{...input}
																	onChange={(e) => handleChange(e)}
																	origOnChange={input.onChange}
																	{...fieldConfig.attributes}
																	{...fieldConfig.validators}
																	meta={meta}
																	color={color}
																	firebase={firebase}
																	notification={notification}
																/>
																{meta.touched && meta.error && (
																	<BulmaForm.Help color={color}>
																		{meta.error ?? "Unknown Error"}
																	</BulmaForm.Help>
																)}
															</BulmaForm.Control>
														);
													}}
												</Field>
												{container.dynamic &&
													onlyShowIndex === undefined &&
													fieldKeyIndex === keysArrayLength - 1 && (
														<BulmaForm.Control
															style={{ alignSelf: "flex-end" }}
														>
															<Button.Group pull="right">
																<Button
																	type="button"
																	color="danger"
																	onClick={() => arrayField.remove(rowIndex)}
																	disabled={rowIndex === 0}
																	style={{
																		visibility: clsx({
																			hidden: rowIndex === 0,
																		}),
																	}}
																>
																	<FontAwesomeIcon icon="trash" />
																</Button>
															</Button.Group>
														</BulmaForm.Control>
													)}
												{fieldConfig.append && (
													<BulmaForm.Control style={{ width: "40%" }}>
														<BulmaForm.Input
															placeholder={fieldConfig.append}
															disabled
														/>
													</BulmaForm.Control>
												)}
											</BulmaForm.Field>
										);
									})}
									{container.dynamic &&
										onlyShowIndex === undefined &&
										rowIndex === arrayField.value.length - 1 && (
											<Button.Group
												pull="right"
												size="small"
												justifyContent="right"
												style={{ order: "99", flexBasis: "100%" }}
											>
												<Button
													type="button"
													pull="right"
													color="primary"
													onClick={() => {
														const newField = {};
														keysArray.forEach((key) => {
															newField[key] = "";
														});
														arrayField.push(newField);
													}} // Using push as there is some issue with insert
													disabled={
														arrayField.value.length >= container.dynamic ||
														rowIndex !== arrayField.value.length - 1
													}
												>
													{arrayField.value.length >= container.dynamic
														? `Reached Max. ${container.dynamic ?? "Length"}`
														: "Add Another"}
													&nbsp;
													{arrayField.value.length < container.dynamic && (
														<FontAwesomeIcon icon="plus" />
													)}
												</Button>
											</Button.Group>
										)}
								</div>
							);
						})}
					</FormFieldContainer>
				)}
			</FieldArray>
		);
	});
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
