/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useEffect } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import clsx from "clsx";
import useNotification from "../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { WebsitesContext } from "../context/websites_contextProvider";

/* Component Imports
-------------------------------------------------- */
import { Columns, Card } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function SelectImages(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const { firebase, colorScheme, template } = props;
	const websites = useContext(WebsitesContext);
	const notification = useNotification();

	/* Dependencies
  -------------------------------------------------- */

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		// Set template based on props.template
		try {
			if (!firebase.authLoading && !websites.templateVariations) {
				// Request template variations if they don't already exist
				websites.getTemplateVariations(firebase, props.template);
			}
		} catch (error) {
			//Catch unhandled errors
			notification.add("danger", error.message);
			throw error;
		}

		return function cleanup() {
			return null;
		};
	}, [
		firebase.authLoading,
		websites.getTemplateVariations,
		notification,
		firebase,
		props.template,
		websites,
	]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (websites?.templateVariations?.colors?.length !== 0) {
		return websites.templateVariations?.colors?.map((color) => {
			// Set classes to selectable and selected if props.colorScheme is the same as the current color
			const classes = clsx({
				selectable: true,
				selected: colorScheme.toLowerCase() === color.id.toLowerCase(),
			});
			return (
				<Columns.Column
					size={3}
					touch={{ size: 2 }}
					key={color.id.toLowerCase()}
					className="padding-required"
				>
					<Card className={classes}>
						<Card.Header style={{ minHeight: "80px" }}>
							<Card.Header.Title>{color.name}</Card.Header.Title>
						</Card.Header>
						<Card.Content>
							<img
								src={
									process.env.PUBLIC_URL +
									`/${template}/demo_images/${color.id}.png`
								}
								alt={`${color.id} color scheme`}
							/>
						</Card.Content>
						<Card.Footer>
							<Card.Footer.Item
								renderAs="button"
								type="button"
								onClick={(e) => {
									props.setColorScheme(color.id);
								}}
								disabled={color.id.toLowerCase() === colorScheme.toLowerCase()}
							>
								{/* Set the Selected/Choose buttons based on state/current color id */}
								{color.id.toLowerCase() === colorScheme.toLowerCase()
									? "Selected"
									: "Choose"}
							</Card.Footer.Item>
						</Card.Footer>
					</Card>
				</Columns.Column>
			);
		});
	}
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
