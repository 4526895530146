/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { Link, useSearchParams } from "react-router-dom";

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Columns, Card, Heading, Tag, Form } from "react-bulma-components";
import BuilderSteps from "../components/FormComponents/CreateWebsite/BuilderSteps";
import WebsiteCardsSkeleton from "./Skeletons/WebsiteCards_Skeleton";
import WebsiteActions from "./WebsiteActions";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function WebsiteCards(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const { websites } = props;
	/* States */
	const [searchParams] = useSearchParams();

	/* Functions */

	/* Dependencies
  -------------------------------------------------- */
	const newWebsite = searchParams.get("create");

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	function A(props) {
		return <a {...props}>{props.children}</a>;
	}

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	if (!websites) {
		return <WebsiteCardsSkeleton />;
	}

	if (websites && (websites.length < 1 || newWebsite === "true")) {
		/* Return */
		return <BuilderSteps />;
	}
	return (
		<>
			<Columns
				p={0}
				multiline="true"
			>
				{websites &&
					websites.map((website) => {
						const colorScheme = () => {
							// Return a generic colour scheme value if one is not available

							const value = website.data.template?.colorScheme[0]?.value;

							if (!value || value === "") {
								return "blue";
							}

							return value;
						};

						const websiteViews = () => {
							const value = website.data.websiteViews;

							if (!value || value === "") {
								return "0";
							}
							return value;
						};

						return (
							<Columns.Column
								touch={{ size: "full" }}
								desktop={{ size: "half" }}
								tablet={{ size: "full" }}
								key={website.id}
							>
								<Card className="selectable">
									<Card.Content>
										<Columns
											vCentered
											centered
										>
											<Columns.Column
												size="full"
												textAlign={"center"}
											>
												<img
													src={
														process.env.PUBLIC_URL +
														`/default/demo_images/${colorScheme()}.png`
													}
													alt={`Example of your ${colorScheme} Colour Scheme Website`}
													width="30%"
													align="center"
												/>
											</Columns.Column>
											<Columns.Column textAlign={"center"}>
												<Heading size={4}>
													{website.data.domain}
													<WebsiteActions website={website} />
												</Heading>
												<Form.Field
													kind="group"
													align="center"
													multiline
												>
													<Tag.Group
														hasAddons
														mr={2}
													>
														<Tag color="primary">Status</Tag>
														<Tag textTransform="capitalized">
															{website.data.status}
														</Tag>
													</Tag.Group>
													<Tag.Group
														hasAddons
														mr={2}
													>
														<Tag color="primary">Package</Tag>
														<Tag textTransform="capitalized">
															{website.data.package}
														</Tag>
													</Tag.Group>
													{website.data.package.toLowerCase() === "demo" && (
														<Tag.Group
															hasAddons
															mr={2}
														>
															<Tag color="primary">Free Previews Remaining</Tag>
															<Tag>{50 - websiteViews()}</Tag>
														</Tag.Group>
													)}

													{website.data.package.toLowerCase() !== "demo" && (
														<Tag.Group
															hasAddons
															mr={2}
														>
															<Tag color="primary">Website Views</Tag>
															<Tag>{website.data.websiteViews ?? 0}</Tag>
														</Tag.Group>
													)}

													<Tag.Group
														hasAddons
														mr={2}
													>
														<Tag color="primary">CV Views</Tag>
														<Tag>{website.data.cvViews ?? 0}</Tag>
													</Tag.Group>
												</Form.Field>
											</Columns.Column>
										</Columns>
										{/* <Columns
                      pb={5}
                      pl={3}
                      pr={3}
                    >
                      <Container
                        display="flex"
                        style={{ justifyContent: "center" }}
                      >
                        <Columns.Column
                          size="two-fifths"
                          className="stat-overlay"
                        >
                          <Heading
                            size={7}
                            subtitle
                            textColor="white"
                          >
                            Page Views
                          </Heading>
                          <Level style={{ marginTop: "-20px" }}>
                            <Level.Side align="left">
                              <Heading
                                textColor="white"
                                size={3}
                              >
                                1,234
                              </Heading>
                            </Level.Side>
                            <Level.Side align="right">
                              <Tag.Group hasAddons>
                                <Tag
                                  rounded
                                  color="warning"
                                >
                                  <Icon color="dark">
                                    <FontAwesomeIcon icon={faArrowTrendDown} />
                                  </Icon>
                                </Tag>
                                <Tag rounded>30</Tag>
                              </Tag.Group>
                            </Level.Side>
                          </Level>
                        </Columns.Column>
                        <Columns.Column
                          size="two-fifths"
                          className="stat-overlay"
                        >
                          <Heading
                            size={7}
                            subtitle
                            textColor="white"
                          >
                            Resume Views
                          </Heading>
                          <Level style={{ marginTop: "-20px" }}>
                            <Level.Side align="left">
                              <Heading
                                textColor="white"
                                size={3}
                              >
                                123
                              </Heading>
                            </Level.Side>
                            <Level.Side align="right">
                              <Tag.Group hasAddons>
                                <Tag
                                  rounded
                                  color="success"
                                >
                                  <Icon color="dark">
                                    <FontAwesomeIcon icon={faArrowTrendUp} />
                                  </Icon>
                                </Tag>
                                <Tag rounded>10</Tag>
                              </Tag.Group>
                            </Level.Side>
                          </Level>
                        </Columns.Column>
                      </Container>
                    </Columns> */}
									</Card.Content>
									<Card.Footer>
										{/* <Card.Footer.Item>Upgrade</Card.Footer.Item> */}
										<Card.Footer.Item
											renderAs={A}
											href={`https://${website.data.domain}/`}
											target="_blank"
										>
											View
										</Card.Footer.Item>
										<Card.Footer.Item
											renderAs={Link}
											to={`edit/${website.id}`}
										>
											Edit
										</Card.Footer.Item>
									</Card.Footer>
								</Card>
							</Columns.Column>
						);
					})}
			</Columns>
		</>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
