/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState, useContext } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import Sidebar from "../components/Sidebar";
import { Button, Columns, Notification, Section } from "react-bulma-components";
import ErrorPage from "../pages/ErrorPage";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

export default function SidebarContentWrapper(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);

	/* Dependencies
  -------------------------------------------------- */
	const [displayAdmin, setDisplayAdmin] = useState(() =>
		firebase?.user?.claims?.admin ?? false ? true : false
	);

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/
	const { Desktop, Touch } = Sidebar(
		firebase.authLoading,
		firebase?.user?.claims?.admin ?? false
	);

	return (
		<Columns
			backgroundColor="light"
			className="app-container"
			style={{ marginTop: "unset" }}
		>
			<Desktop />
			<Columns.Column
				className="outer-content-container"
				style={{ maxHeight: "100vh", overflow: "scroll" }}
				touch={{ size: "full", display: "inline" }}
			>
				{displayAdmin && (
					<Section>
						<Notification color="primary">
							<b>
								You are signed in as an administrative user,{" "}
								{firebase.user.displayName} ({firebase.user.email}).
							</b>
							<Button
								remove
								onClick={() => setDisplayAdmin(false)}
							/>
						</Notification>
					</Section>
				)}
				{!props.dbError && props.children}
				{props.dbError && <ErrorPage />}
			</Columns.Column>
			<Touch />
		</Columns>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
