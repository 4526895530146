/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useEffect, useContext } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import generateInitialValues from "../../helpers/generateFormValues";
import arrayMutators from "final-form-arrays";
import useNotification from "../../hooks/useNotification";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import {
  Form as BulmaForm,
  Block,
  Notification,
  Button,
} from "react-bulma-components";
import { Form } from "react-final-form";
import { DynamicFormFields } from "../FormComponents/DynamicFormFields";
import AuthMethodTable from "../UserProfile/AuthMethodTable";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/**
 * Renders the basic profile component.
 * @param {Object} authMethods - The authentication methods object.
 * @param {Function} setAuthMethods - The function to set the authentication methods.
 * @returns The rendered basic profile component.
 */
export default function BasicProfile({ authMethods, setAuthMethods }) {
  /* State, Context and Hooks
  -------------------------------------------------- */
  const firebase = useContext(FirebaseContext);
  const notification = useNotification();

  /* Dependencies
  -------------------------------------------------- */
  const basicProfileConfig = [
    {
      group: "basic",
      id: "name",
      label: "Display Name",
      helpText:
        "Update your profile display name. This does not update your websites.",
      fields: [
        {
          component: "text",
          attributes: {
            placeholder: "My Name",
            updateUser: true,
          },
          validators: {
            required: true,
            type: "name",
          },
        },
      ],
    },
    {
      group: "basic",
      id: "pass",
      label: "Password",
      helpText: "Change your password",
      fields: [
        {
          id: "original",
          component: "text",
          attributes: {
            type: "password",
          },
          validators: {
            required: true,
            minChars: 8,
            maxChars: 50,
          },
        },
      ],
    },
    {
      group: "basic",
      id: "email",
      label: "Email Address",
      helpText:
        "Update your email address. This does not update any contact email addresses for your website if you have previously overridden them.",
      fields: [
        {
          component: "text",
          attributes: {
            placeholder: "my@email.com",
            updateUser: true,
          },
          validators: {
            required: true,
            type: "email",
          },
        },
      ],
    },
    {
      group: "basic",
      id: "file",
      label: "File Upload",
      helpText: "Click the Upload button to upload a file.",
      dynamic: false,
      append: false,
      fields: [
        {
          id: "headshot",
          component: "file",
          attributes: {
            name: "Profile Photo",
            updateUser: true,
            type: "image",
          },
          validators: {
            required: true,
            type: "image",
          },
        },
      ],
    },
  ];

  const initialProfileValues = {
    basic: {
      name: [
        {
          value: firebase.user?.displayName ?? "",
        },
      ],
      email: [{ value: firebase.user?.email ?? "" }],
      file: [
        {
          headshot: {
            downloadURL: firebase.user?.photoURL ?? "",
          },
        },
      ],
    },
  };

  const hasPasswordAuth = authMethods.find(
    (method) => method.providerId === "password"
  );

  /* useEffects
  -------------------------------------------------- */
  useEffect(() => {
    if (!firebase.authLoading) {
      setAuthMethods(firebase.user.providerData);
    }
  }, [setAuthMethods, firebase.authLoading, firebase.user.providerData]);

  /*=============================================
  =                   Functions                   =
  =============================================*/

  function ThisForm({ filters, onSubmit }) {
    return (
      <Form
        initialValues={generateInitialValues({
          containerConfigs: basicProfileConfig,
          websiteData: initialProfileValues,
        })}
        mutators={{
          ...arrayMutators,
        }}
        subscription={{
          submitting: true,
          valid: true,
          pristine: true,
          modifiedSinceLastSubmit: true,
        }}
        onSubmit={async (values) => onSubmit(values)}
      >
        {(props) => {
          return (
            <form
              onSubmit={props.handleSubmit}
              noValidate={true} // Disable default browser validation #WeDoItBetter
              style={{ marginBottom: "40px" }}
            >
              <DynamicFormFields
                formFields={basicProfileConfig}
                {...props.form.mutators}
                firebase={firebase}
                notification={notification}
                containerSettings={{
                  displayLabel: true,
                  displayHelp: true,
                  isHorizontal: true,
                }}
                fieldFilters={filters}
              />
              {!props.pristine && (
                <Button
                  pull="right"
                  color="primary"
                  disabled={!props.valid || props.submitting}
                  loading={props.submitting}
                >
                  Submit
                </Button>
              )}
            </form>
          );
        }}
      </Form>
    );
  }

  /*============  End of Functions  =============*/

  /*=============================================
=                   Components                   =
=============================================*/

  /*============  End of Components  =============*/

  /*=============================================
  =                   Main Return                   =
  =============================================*/

  return (
    <Block>
      {!hasPasswordAuth && (
        <Notification color="info">
          Your account is managed by a 3rd party, such as Google or Apple
          iCloud.
        </Notification>
      )}

      <ThisForm
        filters={{ basic: ["name"] }}
        onSubmit={async (values) => {
          const value = values.basic.name[0].value;

          if (!value) {
            throw new Error("No name set");
          }

          await firebase
            .updateProfile(firebase.auth.currentUser, {
              displayName: value,
            })
            .catch((error) => {
              throw new Error(error.message);
            });
        }}
      />
      <ThisForm
        filters={{ basic: ["file"] }}
        onSubmit={async (values) => {
          const value = values.basic.file[0].headshot.downloadURL;

          if (!value) {
            throw new Error("No file set");
          }

          await firebase
            .updateProfile(firebase.auth.currentUser, {
              photoURL: value,
            })
            .catch((error) => {
              throw new Error(error.message);
            });
        }}
      />

      {authMethods.map((provider) => {
        return (
          <div key={provider.providerId}>
            {provider.providerId === "password" && (
              <>
                <ThisForm
                  filters={{ basic: ["pass"] }}
                  onSubmit={async (values) => {
                    const value = values.basic.pass[0].verify;

                    if (!value) {
                      throw new Error("No password set");
                    }

                    await firebase
                      .updatePassword(firebase.auth.currentUser, value)
                      .catch((error) => {
                        throw new Error(error.message);
                      });
                  }}
                />
                <ThisForm
                  filters={{ basic: ["email"] }}
                  onSubmit={async (values) => {
                    const value = values.basic.email[0].value;

                    if (!value) {
                      throw new Error("No name set");
                    }

                    await firebase
                      .updateEmail(firebase.auth.currentUser, value)
                      .catch((error) => {
                        throw new Error(error.message);
                      });
                  }}
                />
              </>
            )}
            <Block key={`method_${provider.providerId}`}>
              <BulmaForm.Field.Label>
                <BulmaForm.Label>Authentication Methods</BulmaForm.Label>
              </BulmaForm.Field.Label>
              <BulmaForm.Help color="info">
                You have linked your Web Design for Actors account up with the
                following login providers. You may login to this account using
                any of the folling methods.
              </BulmaForm.Help>
              <AuthMethodTable
                authMethods={authMethods}
                setAuthMethods={setAuthMethods}
                firebase={firebase}
              />
            </Block>
          </div>
        );
      })}
    </Block>
  );
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
