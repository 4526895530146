/* React Imports */
import React from "react";
import ReactDOM from "react-dom/client";

/* Components Import */
import App from "./components/App";

/* Logging */
import LogRocket from "logrocket";

/* Context */
import { FirebaseProvider } from "./context/firebase_contextProvider";
import { WebsitesProvider } from "./context/websites_contextProvider";
/* Components Styling */
import "./css/wdfa.css"; // Theme Customisations
import "./css/bulma-overrides.scss"; // Component Styles - Brand Overrides
import "./css/fonts.css"; // Branding Fonts
import "react-toastify/dist/ReactToastify.css"; // Notification Styles

LogRocket.init("turuof/web-design-for-actors");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<FirebaseProvider>
		{/* Provides context for Error Handling */}
		{/* Provides Context for Firebase Authentication, Storage & DB to encapsulated components*/}
		<WebsitesProvider>
			{/* Provides Context for Notifications to encapsulated components*/}
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</WebsitesProvider>
	</FirebaseProvider>
);
