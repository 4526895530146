/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useContext, useEffect, useRef, useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */
import useCrisp from "../hooks/useCrisp";

/* Context Imports
-------------------------------------------------- */
import { FirebaseContext } from "../context/firebase_contextProvider";

/* Component Imports
-------------------------------------------------- */
import {
	Columns,
	Section,
	Heading,
	Container,
	Box,
	Notification,
	Tag,
	Block,
	Icon,
	Button,
} from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faDatabase,
	faHardDrive,
	faPassport,
	faCreditCard,
	faHeadset,
	faCheckCircle,
	// eslint-disable-next-line
	faExclamationTriangle,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";

/*============  End of IMPORTS  =============*/

export default function ServiceStatus({ pageTitle, setPageTitle }) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const firebase = useContext(FirebaseContext);
	const [servicesStatus, setServicesStatus] = useState({ loading: true });
	const [errorStatus, setErrorStatus] = useState(false);
	const [loading, setLoading] = useState(true);
	const supportChat = useCrisp();
	const isFirstRender = useRef(true);
	/* Dependencies
  -------------------------------------------------- */

	/* useEffects
  -------------------------------------------------- */
	useEffect(() => {
		if (!servicesStatus.loading && servicesStatus.firebase?.status !== "up") {
			if (servicesStatus.firebase?.authentication) {
				setErrorStatus({
					...servicesStatus.firebase?.authentication,
					service: "Authentication",
				});
			} else if (
				!servicesStatus.loading &&
				servicesStatus.firebase?.firestore
			) {
				setErrorStatus({
					...servicesStatus.firebase?.firestore,
					service: "Database",
				});
			} else if (!servicesStatus.loading && servicesStatus.firebase?.storage) {
				setErrorStatus({
					...servicesStatus.firebase?.storage,
					service: "Storage",
				});
			}
		}

		if (!servicesStatus.loading && servicesStatus.stripe?.status !== "up") {
			setErrorStatus({ ...servicesStatus.stripe, service: "Billing" });
		}

		if (!servicesStatus.loading && servicesStatus.crisp?.status !== "up") {
			setErrorStatus({ ...servicesStatus.crisp, service: "Support" });
		}
	}, [
		loading,
		servicesStatus.api,
		servicesStatus.crisp,
		servicesStatus.firebase,
		servicesStatus.stripe,
		servicesStatus.loading,
	]);

	useEffect(() => {
		if (!isFirstRender.current) {
			return;
		}
		isFirstRender.current = false;

		firebase.db
			.get(`status`, firebase.user.accessToken)
			.then((res) => {
				setServicesStatus({ ...res, api: { status: "up" } });
				if (res.firebase.status !== "up") {
					res.firebase.forEach((incident) => {
						if (
							incident.service.toLowerCase() === "authentication" ||
							incident.service.toLowerCase() === "firestore" ||
							incident.service.toLowerCase() === "storage"
						) {
							setServicesStatus((prevStatus) => {
								return { ...prevStatus, incident };
							});
						} else {
							return;
						}
					});
				}
			})
			.catch((error) => {
				setServicesStatus((prevStatus) => prevStatus, {
					id: Math.random(5),
					severity: "high",
					desc: error.message,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [firebase.db, firebase.user.accessToken, isFirstRender]);

	useEffect(() => {
		setPageTitle("Service Status");
	}, [setPageTitle]);

	/*=============================================
  =                   Functions                   =
  =============================================*/

	/**
	 * Sets the notification component based on the error status.
	 * If there is no error, it returns a notification with an "info" color,
	 * indicating that everything is okay. It also provides a button to contact support.
	 * If there is an error, it returns a notification with a "warning" color,
	 * displaying the error status and a message. It also mentions that the issue is already
	 * known and there is no need to report it. It may also include additional information
	 * about the error if available.
	 * @returns {React.Component} - The notification component.
	 */
	function setNotification() {
		if (!errorStatus) {
			return (
				<Notification color="info">
					<Icon mr={2}>
						<FontAwesomeIcon icon={faCheckCircle} />
					</Icon>
					Everything seems to be okay. Something not quite right?{" "}
					<Button
						className="buttonAsLink"
						text
						onClick={() => supportChat.then((crisp) => crisp.chat.open())}
					>
						Contact support
					</Button>
					.
				</Notification>
			);
		} else {
			return (
				<Notification color="warning">
					<Icon
						mr={2}
						pull="left"
					>
						<FontAwesomeIcon icon={faExclamationTriangle} />
					</Icon>
					{new Date().toLocaleDateString()}: We are currently aware of an
					ongoing issue with <b>{errorStatus.service}</b>.
					<br />
					<br />
					{errorStatus.desc === "sick" // Hide the default problem response from crisp "sick"
						? null
						: errorStatus.desc
						? errorStatus.desc + "."
						: null}{" "}
					We're already aware of the issue so no need to let us know. Your
					website and your experience on our website may not be affected by this
					issue.
				</Notification>
			);
		}
	}

	/**
	 * Sets the status tag for a given service.
	 * @param {object} service - The service object containing status information.
	 * @returns The JSX element representing the status tag.
	 */
	function setStatusTag(service) {
		let _serviceStatus =
			service?.status?.toLowerCase() ?? "Unable to Verify Availability";

		if (service?.severity) {
			_serviceStatus = `${service.severity} Severity Issues`;
		}

		if (servicesStatus.loading) {
			return (
				<Tag.Group mt={2}>
					<Tag color="info">
						<FontAwesomeIcon
							icon={faSpinner}
							spin
						/>
					</Tag>
				</Tag.Group>
			);
		} else {
			return (
				<Tag.Group mt={2}>
					<Tag
						color={
							_serviceStatus === "Unable to Verify Availability"
								? "warning"
								: service?.severity
								? "danger"
								: "success"
						}
						textTransform="capitalized"
					>
						{!loading && _serviceStatus?.toLowerCase() === "up"
							? "Available"
							: _serviceStatus}
					</Tag>
				</Tag.Group>
			);
		}
	}

	/**
	 * Populates a status card with the given title, description, icon, and status.
	 * @param {string} title - The title of the status card.
	 * @param {string} desc - The description of the status card.
	 * @param {FontAwesomeIcon} icon - The icon to display on the status card.
	 * @param {string} status - The status of the card.
	 * @returns The JSX element representing the populated status card.
	 */
	function populateStatusCard(title, desc, icon, status) {
		return (
			<Columns.Column size="half">
				<Box
					className="selectable"
					alignItems="center"
					style={{ textAlignLast: "unset" }}
				>
					<FontAwesomeIcon
						icon={icon}
						size="3x"
					/>
					<Block
						p={3}
						ml={4}
						style={{ width: "60%", textAlign: "left" }}
					>
						<Heading size={5}>{title}</Heading>
						<Heading
							subtitle
							size={6}
							style={{ fontWeight: "lighter" }}
						>
							{desc}
							{setStatusTag(status)}
						</Heading>
					</Block>
				</Box>
			</Columns.Column>
		);
	}

	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Columns.Column>
			<Columns className="content-parent-container">
				<Columns.Column>
					<Section>
						<Heading
							size={1}
							style={{
								fontWeight: "500",
								textTransform: "capitalize",
							}}
						>
							{pageTitle}
						</Heading>
						<Heading
							subtitle
							style={{
								fontWeight: "600",
							}}
						>
							Find out if your website services are all working.
						</Heading>
					</Section>
					<Container>
						<Section className="">
							{!servicesStatus.loading && setNotification()}
							{/*  */}
							<Columns>
								{populateStatusCard(
									"Database",
									"Stores your website information",
									faDatabase,
									servicesStatus.firebase?.status // Check for Firebase Status
										? servicesStatus.firebase // Return Firebase Status if exists
										: servicesStatus.firebase?.firestore // Check for firestore status
										? servicesStatus.firebase.firestore // Returns firestore status if exists
										: { status: "up" } // Returns "Available", problem is likely with another firebase service
								)}
								{populateStatusCard(
									"Storage",
									"For storing your file uploads",
									faHardDrive,
									servicesStatus.firebase?.status // Check for Firebase Status
										? servicesStatus.firebase // Return Firebase Status if exists
										: servicesStatus.firebase?.storage // Check for firestore status
										? servicesStatus.firebase.storage // Returns firestore status if exists
										: { status: "up" } // Returns "Available", problem is likely with another firebase service
								)}
								{populateStatusCard(
									"Authentication",
									"For checking your login credentials",
									faPassport,
									servicesStatus.firebase?.status // Check for Firebase Status
										? servicesStatus.firebase // Return Firebase Status if exists
										: servicesStatus.firebase?.authentication // Check for firestore status
										? servicesStatus.firebase.authentication // Returns firestore status if exists
										: { status: "up" } // Returns "Available", problem is likely with another firebase service
								)}
								{populateStatusCard(
									"Billing",
									"For your payments and subscriptions",
									faCreditCard,
									servicesStatus.stripe
								)}
								{populateStatusCard(
									"Support",
									"For your to be able to speak to our team",
									faHeadset,
									servicesStatus.crisp
								)}
							</Columns>
						</Section>
					</Container>
				</Columns.Column>
			</Columns>
		</Columns.Column>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
