/*=============================================
=                   IMPORTS                   =
=============================================*/

/* React Imports
-------------------------------------------------- */
import { useState } from "react";

/* Hooks & Helpers Imports
-------------------------------------------------- */

/* Context Imports
-------------------------------------------------- */

/* Component Imports
-------------------------------------------------- */
import { Form } from "react-bulma-components";

/* Styling Imports
-------------------------------------------------- */

/* Icon Imports
-------------------------------------------------- */

/*============  End of IMPORTS  =============*/

/*=============================================
=                   Outer Functions                   =
=============================================*/

export function useValidation() {
	const [validationMessage, setValidationMessage] = useState("");

	let color = "";

	if (validationMessage !== "") {
		color = "danger";
	} else {
		color = "";
	}

	function returnMessage() {
		if (!!validationMessage) {
			return (
				<Form.Help
					color={color}
					textWeight="bold"
				>
					{validationMessage}
				</Form.Help>
			);
		}
	}

	return { color, validationMessage, setValidationMessage, returnMessage };
}

/*============  End of Outer Functions  =============*/
/**
 * A container component for form fields.
 * @param {object} props - The props passed to the component.
 * @param {string} props.labelText - The text to display as the label for the form field.
 * @param {boolean} props.displayLabel - Determines whether to display the label or not.
 * @param {string} props.helpText - The text to display as the help text for the form field.
 * @param {boolean} props.displayHelp - Determines whether to display the help text or not.
 * @param {any} props.currentValue - The current value of the form field.
 * @param {boolean} props.isHorizontal - Determines whether to display the form field horizontally or not.
 * @returns
 */
export default function FormFieldContainer(props) {
	/* State, Context and Hooks
  -------------------------------------------------- */
	const {
		labelText, // <string> - The text to display for the label
		displayLabel, // BOOL (true/false) - Whether the label is displayed or not
		helpText, // <string> - The text to display for the help text
		displayHelp, // BOOL (true/false) - Whether the help text is displayed or not
		currentValue, // The current value of the field
		isHorizontal, // BOOL (true/false) - Changes the display settings of the form
		maxChars, // NUMBER - Set the maximum length that a field can be
		centre, // BOOL (true/false) - Whether the labels should be centred
		color, // <string> - Color Override
	} = props;

	/* Dependencies
  -------------------------------------------------- */

	/* End of useEffects
  -------------------------------------------------- */

	/*=============================================
  =                   Functions                   =
  =============================================*/
	/**
	 * Determines the color to be used based on the length of the current value compared to the maximum allowed characters.
	 * @returns {string} - The color to be used. Can be "success" if the length is within the limit, "danger" if the length exceeds the limit, or the default color if none of the conditions are met.
	 */
	function chooseMaxCharsColor() {
		if ((currentValue?.length ?? 0) <= maxChars) {
			return "success";
		} else if ((currentValue?.length ?? 0) >= maxChars) {
			return "danger";
		} else {
			return color;
		}
	}

	/**
	 * Returns a label component with the specified label text.
	 * @param {string} [labelText="Form Field"] - The text to display in the label.
	 * @returns {JSX.Element | undefined} - The label component.
	 */
	function returnLabel(labelText = "Form Field") {
		if (displayLabel) {
			return <Form.Label color={color && color}>{labelText}</Form.Label>;
		}
	}

	/**
	 * Returns a help component for a form field.
	 * @param {string} [helpText="This is a form field."] - The text to display as help for the form field.
	 * @returns {JSX.Element | undefined} - The help component to be rendered, or undefined if displayHelp is false.
	 */
	function returnHelp(helpText = "This is a form field.") {
		if (displayHelp) {
			return (
				<>
					<Form.Help color={color ? color : "info"}>{helpText}</Form.Help>

					{maxChars && (
						<Form.Help color={chooseMaxCharsColor()}>
							A maximum of {maxChars} characters is allowed here. You have used{" "}
							<b>
								{currentValue.length ?? "?"} of {maxChars}
							</b>{" "}
							characters.
						</Form.Help>
					)}
				</>
			);
		}
	}
	/*============  End of Functions  =============*/

	/*=============================================
=                   Components                   =
=============================================*/

	/*============  End of Components  =============*/

	/*=============================================
  =                   Main Return                   =
  =============================================*/

	return (
		<Form.Field
			horizontal={isHorizontal}
			className={centre ? "horizontally-center-one" : null}
		>
			{isHorizontal && (
				<Form.Field.Label>
					<div>
						{displayLabel && returnLabel(labelText)}
						{displayHelp && returnHelp(helpText)}
					</div>
				</Form.Field.Label>
			)}
			{!isHorizontal && (
				<Form.Field.Label className={centre ? "horizontally-center-one" : null}>
					{displayLabel && returnLabel(labelText)}
					{displayHelp && returnHelp(helpText)}
				</Form.Field.Label>
			)}
			<Form.Field.Body style={{ minWidth: "50%" }}>
				{props.children}
			</Form.Field.Body>
		</Form.Field>
	);
}

/*============  End of Main Return  =============*/

/*=============================================
=                   Exports                   =
=============================================*/

/*============  End of Exports  =============*/
